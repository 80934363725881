import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSpecificationsSubSections,
  specificationSubSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import NavBar from "components/UI/_TODO/WorkOrMaterialsModals/components/NavBar/NavBar";

import ProductTable from "../ProductTable/ProductTable";
import SectionChild from "../SectionChild/SectionChild";
import ButtonBase from "shared/ui/controls/ButtonBase";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";

import useDebounce from "utils/hooks/useDebouncedValue";

import styles from "./../../Materials.module.scss";

interface IProps {
  handleAddProducts: any;
  productsCount: number;
  onGoBack: () => void;
  type: string;
  objectId: string;
  activeSectionId: number;
  products: any[];
  setProducts: any;
}

const SubSectionsLevel: React.FC<IProps> = ({
  handleAddProducts,
  productsCount,
  onGoBack,
  objectId,
  activeSectionId,
  type,
  products,
  setProducts,
}) => {
  const dispatch = useDispatch();
  const [searchBarValue, setSearchBarValue] = useState<string>();

  const handleSearchBarChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBarValue(event.target.value);
  }, []);

  const specificationSubSections = useSelector(specificationSubSectionsSelector);
  useEffect(() => {
    dispatch(getSpecificationsSubSections(objectId, type, activeSectionId, ""));
  }, [activeSectionId, type, objectId]);

  return (
    <div className={styles.container}>
      <div className={styles.searchBlock}>
        <InputSearchRound
          onChange={handleSearchBarChange}
          className={styles.inputSearchClassName}
          value={searchBarValue}
        />
        <ButtonBase onClick={handleAddProducts} disabled={!productsCount} primary>
          Добавить
        </ButtonBase>
      </div>

      <div>
        <div className={styles.titleTable}>
          <span className={styles.button}>
            <NavBar onClick={onGoBack} isExist text={specificationSubSections?.name} />
          </span>
          {/* <span className={styles.countSection}>всего записей: {specificationSubSections?.subsections_count}</span> */}
        </div>
        {specificationSubSections?.subsections?.map((item: any) => (
          //b-1256
          /* item.products_count !== 0 && */ <SectionChild
            key={item.id}
            data={item}
            objectId={objectId}
            type={type}
            products={products}
            setProducts={setProducts}
            search={searchBarValue?.trim() ?? ""}
            isDefaultOpen={specificationSubSections?.subsections?.length === 1}
          />
        ))}
        {specificationSubSections?.subsections?.length === 0 && specificationSubSections.products?.length > 0 && (
          <ProductTable section={specificationSubSections} type={type} products={products} setProducts={setProducts} />
        )}
      </div>
    </div>
  );
};

export default SubSectionsLevel;
