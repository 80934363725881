import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getSegments } from "./model/thunks";
import AttributeCreateModal from "./ui/AttributeCreateModal/AttributeCreateModal";
import AttributeDetailModal from "./ui/AttributeDetailModal/AttributeDetailModal";
import { useTypedSelector } from "app/store/typedUseSelector";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { IAttribute } from "./model/types";

import estimateIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./SettingsObjectAttributes.module.scss";

interface IProps {
  objectId: string;
}

const SettingsObjectAttributes = ({ objectId }: IProps) => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);

  const isLoading = useTypedSelector((state) => state.settingsReducer.attributesReducer.loadings)[objectId];
  const segments = useTypedSelector((state) => state.settingsReducer.attributesReducer.segments)[objectId];
  const invalidationKey = useTypedSelector((state) => state.settingsReducer.attributesReducer.invalidationKey);

  useEffect(() => {
    dispatch(getSegments(objectId));
  }, [objectId, invalidationKey]);

  const isEmpty = !segments?.length && !isLoading;

  const [selectedAttribute, setSelectedAttribute] = useState<IAttribute | null>(null);

  return (
    <>
      <div className={styles.header}>
        <h1>Атрибуты</h1>
        <AddButton text="Добавить" onClick={() => setIsAdding(true)} />
      </div>
      {!isEmpty && (
        <div className={styles.table}>
          {segments?.map((el) => (
            <TableReusableRow key={el.id} onClick={() => setSelectedAttribute(el)}>
              <TableReusableCell>{el.name}</TableReusableCell>
            </TableReusableRow>
          ))}
        </div>
      )}
      {isLoading && <Spinner />}
      {isEmpty && (
        <EmptyPlaceholder img={estimateIcon}>
          <ButtonBase primary onClick={() => setIsAdding(true)}>
            Добавить
          </ButtonBase>
        </EmptyPlaceholder>
      )}

      <AttributeCreateModal
        invalidationKey={invalidationKey}
        isOpen={isAdding}
        onClose={() => setIsAdding(false)}
        objectId={objectId}
      />

      {!!selectedAttribute && (
        <AttributeDetailModal
          key={invalidationKey}
          attr={selectedAttribute}
          onClose={() => setSelectedAttribute(null)}
          objectId={objectId}
        />
      )}
    </>
  );
};

export default SettingsObjectAttributes;
