import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chartApi } from "redux/modules/common/chart/api";
import { chartStatusesSelector } from "redux/modules/common/chart/selectors";
import { getChartStatuses } from "redux/modules/common/chart/thunks";

import { IOption } from "components/UI/atoms/Select";

//TODO вынести это из графика,
//тк логически это уже не просто настройка цветов графика а сквозная для всей системы сущность
export const useGetFactStatuses = (skip?: boolean) => {
  const [factStatuses, setFactStatuses] = useState<IOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (skip) return;
    setIsLoading(true);
    chartApi
      .getStatuses()
      .then(({ data }) => {
        const res = [
          { name: data.accepted, id: "accepted" },
          { name: data.completed, id: "completed" },
          { name: data.todo, id: "todo" },
          { name: data.to_pay, id: "to_pay" },
        ];
        setFactStatuses(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [skip]);

  return {
    factStatuses,
    isFactStatusesLoading: isLoading,
  };
};
