import moment from "moment";

import { useUIIndicator } from "hooks/uiIndicators/useUIIndicator";

interface IProps {
  sectionId: any;
  sectionParentId: any;
  expenditureOrGroup: any;
  dateStart: any;
  dateEnd: any;
}

export const useBuilidngExpIndicators = ({
  sectionId,
  sectionParentId,
  expenditureOrGroup,
  dateEnd,
  dateStart,
}: IProps) => {
  const { isIndicatorDisplayed } = useUIIndicator({
    /* @ts-ignore */
    type: "confirm_expenditure_plan",
    data: { child_section_id: sectionId, parent_section_id: sectionParentId, exp_id: expenditureOrGroup?.id },
    customFilter: (indicator) =>
      !!indicator?.data?.year &&
      !!indicator?.data?.month &&
      moment()
        .year(indicator.data.year)
        .month(indicator.data.month - 1)
        .date(1)
        .isBetween(moment(dateStart), moment(dateEnd)),
  });

  return { isIndicatorDisplayed };
};
