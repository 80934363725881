import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { getPrices } from "../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";

import { PRICING_MODES_OPTIONS } from "../utils/constants";

export const useGetMultiplePrices = (objectId: string) => {
  const dispatch = useDispatch();
  const prices = useTypedSelector((state) => state.multiplePricesReducer.prices)[objectId];
  const multiplePricesLoadings = useTypedSelector((state) => state.multiplePricesReducer.loadings);
  const cacheKey = useTypedSelector((state) => state.multiplePricesReducer.invalidationKey);

  useEffect(() => {
    dispatch(getPrices(objectId));
  }, [objectId, cacheKey]);

  const multiplePricesOptions = useMemo(() => {
    return [...PRICING_MODES_OPTIONS, ...(prices ?? [])];
  }, [prices]);

  return {
    multiplePricesOptions,
    multiplePricesLoadings,
  };
};
