import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  ADD_SELECTED_PRODUCTS,
  ADD_SELECTED_SUBSECTIONS,
  DELETE_SELECTED_PRODUCTS,
  DELETE_SELECTED_SUBSECTIONS,
  loadIntervals,
  productsOverviewSelector,
  selectedProductsSelector,
  selectedSubsectionsSelector,
} from "redux/modules/common/building/process";
import { projectInvalidateKeySelector } from "redux/modules/common/building/project/selectors";
import { getExpendituresBySegment, getGroupsBySegment } from "redux/modules/common/building/project/thunks";
import {
  expendituresSubMaterialsSelector,
  getExpendituresBySection,
  resetExpendituresSubMaterialsAction,
} from "redux/modules/common/building/sections/sections";
import { getExpendituresGroups } from "redux/modules/common/building/shedules";

import { TabsEnum } from "components/pages/Building/enums";
import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import SubSection, { IExternalOffsetsSubsection } from "./SubSection";
import { IPlanSection, useSectionPlan } from "./useSectionPlan";
import { deletePlanInvalidateKeySelector } from "widgets/DeletePlan/model/selectors";

import { IExpenditureTypesFiltersInBuilding, IbuidlingSubSegment } from "../../types";
import ISection from "types/interfaces/Section";

import { _TEMP_useBuildingDetailDataById } from "hooks/useBuildingDetailDataById";

export interface ISubSectionContainerProps {
  objectId: number;
  section: ISection | IbuidlingSubSegment;
  editable: boolean;
  activeTab: TabsEnum;
  date_start: string;
  date_end: string;
  isShared: boolean;
  isIndicatorsOpen: boolean;
  canSharing: boolean;
  permissions: {
    canAddFiles: boolean;
    canDeleteFiles: boolean;
    canAddPlan: boolean;
    canAddProgress: boolean;
  };
  filters: IExpenditureTypesFiltersInBuilding;
  canAddPlan?: boolean;
  dataTestId?: string | number;
  lsrPlanData?: IPlanSection;
  isDefaultOpened?: boolean;
  externalTopOffsets?: IExternalOffsetsSubsection;
}

const SubSectionContainer = ({
  objectId,
  section,
  date_start,
  date_end,
  activeTab,
  editable,
  filters,
  canAddPlan,
  dataTestId,
  lsrPlanData,
  isDefaultOpened = false,
  externalTopOffsets,
  ...props
}: ISubSectionContainerProps) => {
  const dispatch = useDispatch();
  const { measure } = useFinanceMeasure();
  const selectedProducts = useSelector(selectedProductsSelector);
  const intervals = useSelector((state: any) => state.process.intervals);
  const productsOverview = useSelector(productsOverviewSelector);
  const selectedSubsections = useSelector(selectedSubsectionsSelector);
  const projectInvalidateKey = useSelector(projectInvalidateKeySelector);
  const expendituresSubMaterials = useSelector(expendituresSubMaterialsSelector);
  const deletePlanInvalidateKey = useSelector(deletePlanInvalidateKeySelector);
  const projectStructure = filters.attribute ? "segments" : "sections";

  const onCheck = useCallback(
    (isChecked: boolean, itemId: number, expenditure: any | undefined) => {
      if (itemId !== section.id) {
        const payload = {
          id: itemId,
          sectionId: section.id,
        };

        if (expenditure !== undefined) {
          /* @ts-ignore */
          payload.expenditure = expenditure;
        }

        dispatch({
          type: isChecked ? ADD_SELECTED_PRODUCTS : DELETE_SELECTED_PRODUCTS,
          payload,
        });
      } else {
        dispatch({
          type: isChecked ? ADD_SELECTED_SUBSECTIONS : DELETE_SELECTED_SUBSECTIONS,
          payload: itemId,
        });
      }
    },
    [section.id]
  );

  const loadSectionExpenditures = useCallback(() => {
    if (!section) return;
    if (filters.attribute) {
      dispatch(
        getExpendituresBySegment({
          building: objectId,
          segment: section.id,
        })
      );
    } else {
      dispatch(
        getExpendituresBySection({
          building: objectId,
          section: section.id,
          estimateState: ESTIMATE_STATES_IDS.PRODUCTION,
        })
      );
    }
  }, [objectId, section?.id, filters.attribute]);

  const loadSectionGroups = useCallback(() => {
    if (!section) return;
    if (filters.attribute) {
      dispatch(
        getGroupsBySegment({
          buildingId: String(objectId),
          segmentId: section.id,
          dateEnd: moment(date_end).format("YYYY-MM-DD"),
          dateStart: moment(date_start).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        getExpendituresGroups({
          buildingId: String(objectId),
          sectionId: section.id,
          dateEnd: moment(date_end).format("YYYY-MM-DD"),
          dateStart: moment(date_start).format("YYYY-MM-DD"),
        })
      );
    }
  }, [objectId, section?.id, date_start, date_end, filters.attribute]);

  useEffect(() => {
    return () => {
      compose(dispatch, resetExpendituresSubMaterialsAction)();
    };
  }, []);

  const { buildingData } = _TEMP_useBuildingDetailDataById(objectId.toString());

  const loadSubSectionIntervals = useCallback(() => {
    if (!buildingData?.construction_date_start || !buildingData?.construction_date_end) return;
    dispatch(
      loadIntervals({
        buildingId: objectId,
        subSectionId: section.id,
        type: activeTab,
        startAt: buildingData.construction_date_start,
        endAt: buildingData.construction_date_end,
        structure: projectStructure,
        all: undefined,
        needRefresh: undefined,
      })
    );
  }, [
    dispatch,
    objectId,
    section.id,
    activeTab,
    buildingData?.construction_date_start,
    buildingData?.construction_date_end,
    projectStructure,
  ]);

  useEffect(() => {
    loadSectionGroups();
    loadSectionExpenditures();
    loadSubSectionIntervals();
  }, [projectInvalidateKey, deletePlanInvalidateKey, loadSubSectionIntervals]);

  useEffect(() => {
    if (!buildingData?.construction_date_start || !buildingData?.construction_date_end) return;
    dispatch(
      loadIntervals({
        buildingId: objectId,
        subSectionId: section.id,
        type: activeTab,
        startAt: buildingData?.construction_date_start,
        endAt: buildingData?.construction_date_end,
        structure: projectStructure,
        all: undefined,
        needRefresh: true,
      })
    );
  }, [deletePlanInvalidateKey]);

  const { planData, refetchSectionPlan } = useSectionPlan(section.id, activeTab === TabsEnum.plan);

  if (!section) return null;

  return (
    <SubSection
      filters={filters}
      objectId={objectId}
      // @ts-ignore
      section={section}
      measure={measure}
      activeTab={activeTab}
      loadProduct={loadSubSectionIntervals}
      onCheck={onCheck}
      checkedExpenditures={selectedProducts}
      checkedSections={selectedSubsections}
      intervals={intervals}
      expendituresOverview={productsOverview} /* @ts-ignore */
      sectionPlanData={planData}
      isShownPlanData={activeTab === TabsEnum.plan}
      onReloadSubSections={refetchSectionPlan}
      canAddPlan={canAddPlan}
      loadSectionExpenditures={loadSectionExpenditures}
      loadSectionGroups={loadSectionGroups}
      // @ts-ignore
      parentId={section.parent_id}
      lsrPlanData={lsrPlanData}
      dateStart={date_start}
      dateEnd={date_end}
      isDefaultOpened={isDefaultOpened}
      externalTopOffsets={externalTopOffsets}
      constructionDateStart={buildingData?.construction_date_start || ""}
      constructionDateEnd={buildingData?.construction_date_end || ""}
      {...props}
    />
  );
};

export default React.memo(SubSectionContainer);
