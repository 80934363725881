import cn from "classnames";
import _ from "lodash";
import React, { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";

import Icon from "../../../../_LEGACY/UI/_LEGACY_Icon/Icon";

import clearIcon from "./clear";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { IExpenditureFue } from "types/interfaces/Expenditure";

import useEscapeHandler from "utils/hooks/useEscapeHandler";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import arrowDownSelect from "images/icons/arrowDownSelect.svg";

import styles from "./index.module.scss";

const INITIAL_OPTIONS: IOption[] = [];

export enum COLORS {
  BLUE = "blue",
}

export enum SIZES {
  SMALL = "small",
}

const getOptionById = (options: IOption[], id?: IOption["id"]) => options.find((option) => option.id === id);

export interface IOption {
  id: number | string;
  name: string | number;
  description?: string;
  isHidden?: boolean;
}

export interface ISelectProps<O extends IOption = IOption> {
  dataName?: string;
  options: O[];
  value?: O["id"];
  color?: COLORS;
  size?: SIZES;
  labelColor?: string;
  placeholder?: React.ReactNode;
  onChange?: (optionId: O["id"], optionName: O["name"]) => void;
  disabled?: boolean;
  containerClassName?: string;
  className?: string;
  selectClassName?: string;
  label?: string;
  meta?: FieldMetaState<IOption["id"]>;
  onOpen?: () => void;
  onClose?: () => void;
  icon?: string;
  iconClassName?: string;
  input?: FieldInputProps<IOption["id"]>;
  testId?: string;
  hideMoreThanOptions?: number;
  isLoading?: boolean;
  isScrolledToDefaultOption?: boolean;
  isSearchBar?: boolean;
  searchBarClassName?: string;
  onSearchBarChange?: (value?: string | number) => void;
  onSearchBarSelectedItemChange?: (value: IExpenditureFue[]) => void;
  searchBarValue?: string;
  isSearchBarLoading?: boolean;
  isDefaultSearchBarOpen?: boolean;
  classNameOptions?: string;
  customDropdown?: React.ReactNode;
  displayIngValue?: ReactNode;
  placement?: "top" | "bottom";
  classNameOption?: string;
  renderOption?: (option: O, onClick?: (optionId: O["id"], optionName: O["name"]) => void) => React.ReactNode;
  externalIsOpen?: boolean;
  onClear?: () => void;
  classNameLabel?: string;
  endDecorator?: ReactNode;
}

const Select = <O extends IOption = IOption>({
  dataName,
  options = INITIAL_OPTIONS as O[],
  value,
  color,
  size,
  labelColor,
  placeholder = "Выберите",
  onChange,
  disabled,
  containerClassName,
  className,
  selectClassName,
  label,
  meta,
  onOpen,
  onClose,
  icon = arrowDownSelect,
  iconClassName,
  input, // for react-final-form Field
  testId,
  hideMoreThanOptions,
  isLoading,
  isScrolledToDefaultOption,
  isSearchBar,
  searchBarClassName,
  classNameOptions,
  onSearchBarChange,
  onSearchBarSelectedItemChange,
  searchBarValue,
  isSearchBarLoading,
  isDefaultSearchBarOpen = true,
  customDropdown,
  displayIngValue,
  placement,
  classNameOption,
  renderOption,
  externalIsOpen,
  onClear,
  classNameLabel,
  endDecorator,
}: ISelectProps<O>) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const optionsBlockRef = useRef<HTMLDivElement>(null);

  const isDefaultOpened = useRef<boolean>(isDefaultSearchBarOpen);

  const [isOpen, setIsOpen] = useState(false);
  const [localValueId, setLocalValueId] = useState<IOption["id"] | undefined>((input && input.value) ?? value);
  const [isOptionsHidden, setIsOptionsHidden] = useState<boolean>(!!hideMoreThanOptions);

  React.useEffect(() => {
    if (!localValueId && input?.value) {
      setLocalValueId(input?.value);
    }
  }, [input?.value, localValueId]);

  const selectOption = useCallback(
    (optionId: IOption["id"], optionName: IOption["name"]) => {
      setLocalValueId(optionId);

      if (isSearchBar) {
        const selectedOptionItem = getOptionById(options, optionId);

        onSearchBarSelectedItemChange?.(selectedOptionItem as any);
      }

      if (input && input.onChange) {
        input.onChange(optionId);
        return;
      }
      if (onChange) onChange(optionId, optionName);
    },
    [onChange, input?.onChange, options]
  );

  const someOptionsHaveDescription = useMemo(
    () => options.some((option) => option.description !== undefined),
    [options]
  );

  const selectedOption = useMemo(() => {
    return getOptionById(options, localValueId);
  }, [options, localValueId, input?.value]);

  const displayingOptions = useMemo(() => {
    if (!options) return [];
    if (hideMoreThanOptions && isOptionsHidden) return options.slice(0, hideMoreThanOptions);
    return options.filter((option) => !option.isHidden);
  }, [hideMoreThanOptions, isOptionsHidden, options]);

  const showAllOptions: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    setIsOptionsHidden(false);
  }, []);

  useEscapeHandler(() => {
    onClose && onClose();
    setIsOpen(false);
  });

  const handleOpening: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();

      if (disabled || (isSearchBar && displayingOptions?.length <= 0)) return;
      input?.onFocus?.();
      input?.onBlur?.();
      setIsOpen((prevState) => !prevState);
    },
    [disabled, displayingOptions]
  );

  useEffect(() => {
    if (!isSearchBar || (isSearchBarLoading && searchBarValue)) return;

    if (searchBarValue) {
      isDefaultOpened.current && externalIsOpen !== false && setIsOpen(true);
      //isDefaultOpened.current = true;
    } else {
      if (!externalIsOpen) {
        setIsOpen(false);
      }
    }
  }, [searchBarValue, isSearchBarLoading, isSearchBar]);

  useEffect(() => {
    setLocalValueId(input?.value ?? value);
  }, [value, input?.value]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) setIsOpen(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    setLocalValueId(value);
  }, [value]);

  useEffect(() => {
    if (isOpen) {
      if (onOpen) onOpen();
    } else {
      setIsOptionsHidden(!!hideMoreThanOptions);
      if (onClose) onClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !isScrolledToDefaultOption || !selectedOption || !optionsBlockRef.current) return;
    optionsBlockRef.current.scrollTop = (
      optionsBlockRef.current.querySelector(`div[data-optionid='${selectedOption.id}']`) as HTMLElement
    )?.offsetTop;
  }, [isOpen, isScrolledToDefaultOption, selectedOption, optionsBlockRef.current]);

  return (
    <div className={cn(styles.container, containerClassName, styles[placement!])}>
      {label && <label className={cn(styles.label, classNameLabel)}>{label}</label>}
      <div
        className={cn(styles.selectContainer, className)}
        onClick={handleOpening}
        ref={selectRef}
        data-name={dataName}
      >
        {!isSearchBar ? (
          <div
            className={cn(
              styles.input,
              selectClassName,
              color && styles[color],
              size && styles[size],
              labelColor && styles[labelColor],
              {
                [styles.isOpen]: isOpen && !disabled,
                [styles.disabled]: disabled,
              }
            )}
          >
            <span className={cn(styles.title)} data-testid={testId}>
              {displayIngValue ? displayIngValue : selectedOption ? selectedOption.name : placeholder}
            </span>
            {!disabled && (
              <Icon
                icon={icon}
                className={cn(styles.arrow, iconClassName, { [styles.arrowReverse]: isOpen && !disabled })}
              />
            )}
          </div>
        ) : (
          <>
            <input
              type="text"
              className={cn(
                styles.input,
                styles.searchBar,
                searchBarClassName,
                color && styles[color],
                size && styles[size],
                labelColor && styles[labelColor],
                {
                  [styles.isOpen]: isOpen && !disabled,
                  [styles.disabled]: disabled,
                  [styles.withClear]: onClear,
                }
              )}
              onClick={(event) => isOpen && stopEventPropagation(event)}
              onChange={(event) => {
                input?.onChange(event.target.value);
                onSearchBarChange?.(event.target.value);
              }}
              value={searchBarValue}
              placeholder={placeholder as string}
              disabled={disabled}
            />
            {!!onClear && (
              <div
                className={styles.clear}
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
              >
                {clearIcon}
              </div>
            )}
          </>
        )}
        {isOpen && (
          <div
            ref={optionsBlockRef}
            className={cn(
              styles.optionsBlock,
              {
                [styles.withDescription]: someOptionsHaveDescription,
                [styles.hiddenOptions]: hideMoreThanOptions,
              },
              classNameOptions
            )}
          >
            {customDropdown}
            {!isLoading &&
              displayingOptions.map((option) => {
                if (renderOption) {
                  return renderOption(option, onChange);
                }
                if (!renderOption) {
                  return (
                    <div
                      className={cn(styles.option, size && styles[size], classNameOption, {
                        [styles.selected]: option.id === localValueId,
                      })}
                      onClick={() => selectOption(option.id, option.name)}
                      key={option.id}
                      data-optionid={option.id}
                      title={String(option.name)}
                    >
                      <span className={styles.name}>{option.name}</span>
                      {option.description && <span>{option.description}</span>}
                    </div>
                  );
                }
              })}
            {!isLoading && (!options || options.length === 0) && (
              <div className={cn(styles.option, classNameOption)}>
                {isSearchBar ? "По вашему запросу ничего не найдено" : "Нет данных"}
              </div>
            )}
            {!isLoading && hideMoreThanOptions && isOptionsHidden && (
              <ButtonBase className={styles.moreButton} onClick={showAllOptions} small primary>
                Еще
              </ButtonBase>
            )}
            {isLoading && (
              <div className={styles.spinner}>
                <Spinner isSmall />
              </div>
            )}
            {endDecorator}
          </div>
        )}
      </div>
      {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
    </div>
  );
};

export default React.memo(Select);
