import React from "react";
import { useSelector } from "react-redux";

import { chartActionsSelector, chartTreeFiltersSelector } from "redux/modules/common/chart/selectors";
import { ChartModalType, IChartTree } from "redux/modules/common/chart/types";

import ChartInterval from "../ChartInterval/ChartInterval";
import withPlan from "../ChartInterval/_plan/withPlan";
import withResource from "../ChartInterval/_resource/withResource";
import withWork from "../ChartInterval/_work/withWork";
import { withEditing } from "../ChartInterval/withEditing";
import { withLinking } from "../ChartInterval/withLinking";

import { CHART_TREE_LVL } from "../../constants";

import styles from "../ChartTree/ChartTree.module.scss";

const Work = withWork(ChartInterval);
// @ts-ignore
const Plan = withPlan(withLinking(withEditing(ChartInterval)));
const Resource = withResource(ChartInterval);

export interface IChartIntervalsRowProps {
  tree: IChartTree;
  isOpen: boolean;
}

const ChartIntervalsRow: React.FC<IChartIntervalsRowProps> = ({ tree, isOpen }) => {
  const chartActions = useSelector(chartActionsSelector);
  const filters = useSelector(chartTreeFiltersSelector);
  const isPlansInteractionMode = chartActions?.linking_editing_enabled || chartActions?.plans_editing_enabled;
  const hideWorks = isPlansInteractionMode;
  const modalType: ChartModalType = (() => {
    if (!tree.isSection) return "expenditure";
    if (tree.lvl === CHART_TREE_LVL.PROJECT) return "building";
    if (tree.lvl === CHART_TREE_LVL.LSR) return "lsr";
    if (tree.lvl === CHART_TREE_LVL.SECTION) return "section";
    return "expenditure";
  })();
  /** Принудительно скрываем связи для схлопнутых строк и строк с отфильтрованными дочерними */
  const hideLinks = Boolean(
    tree.children?.length && (!isOpen || (tree.lvl > 1 && filters?.skipBranchLvl?.[tree.lvl + 1]))
  );

  return (
    <>
      <div className={styles.intervalsBranch}>
        {tree.processedIntervals?.plan_sections.map((planSection) => (
          <Plan
            plan={planSection}
            key={`${tree._id}_${planSection.id}`}
            isSectionPlan
            modalType={modalType}
            projectId={tree.projectId}
            hideLinks={hideLinks}
            treeUID={ tree._id}
          />
        ))}
        {tree.processedIntervals?.plan_works.map((plan) => (
          <Plan
            plan={plan}
            key={`${tree._id}_${plan[plan.type]?.id}`}
            modalType={modalType}
            projectId={tree.projectId}
            hideLinks={hideLinks}
            treeUID={ tree._id}
          />
        ))}
        {!hideWorks &&
          tree.processedIntervals?.fact_works.map((work) => (
            <Work
              work={work}
              key={`${tree._id}_${work[work.type]?.id}`}
              modalType={modalType}
              projectId={tree.projectId}
              treeUID={ tree._id}
            />
          ))}
        {tree.processedIntervals?.plan_resources.map((planResource) => (
          <Resource resource={planResource} key={`${tree._id}_${planResource.plan?.id}`} projectId={tree.projectId} />
        ))}
        {tree.processedIntervals?.fact_resources.map((resource) => (
          <Resource
            resource={resource}
            key={`${tree._id}_${resource.exp_id}_${resource.date}`}
            projectId={tree.projectId}
          />
        ))}
        {!isOpen || filters?.skipBranchLvl?.[tree.lvl + 1] ? (
          <>
            {tree.bubbledIntervals?.plan_sections.map((planSection) => (
              <Plan
                plan={planSection}
                key={`${tree._id}_${planSection.id}`}
                isSectionPlan
                modalType={modalType}
                projectId={tree.projectId}
                hideLinks={hideLinks}
                treeUID={ tree._id}
              />
            ))}
            {tree.bubbledIntervals?.plan_works.map((plan) => (
              <Plan
                plan={plan}
                key={`${tree._id}_${plan[plan.type]?.id}`}
                modalType={modalType}
                projectId={tree.projectId}
                hideLinks={hideLinks}
                treeUID={ tree._id}
              />
            ))}
            {!hideWorks &&
              tree.bubbledIntervals?.fact_works.map((work) => (
                <Work
                  work={work}
                  key={`${tree._id}_${work[work.type]?.id}`}
                  modalType={modalType}
                  projectId={tree.projectId}
                  treeUID={ tree._id}
                />
              ))}
            {tree.bubbledIntervals?.plan_resources.map((planResource) => (
              <Resource
                resource={planResource}
                key={`${tree._id}_${planResource.plan?.id}`}
                projectId={tree.projectId}
              />
            ))}
            {tree.bubbledIntervals?.fact_resources.map((resource) => (
              <Resource
                resource={resource}
                key={`${tree._id}_${resource.exp_id}_${resource.date}`}
                projectId={tree.projectId}
              />
            ))}
          </>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(ChartIntervalsRow);
