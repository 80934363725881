import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//TODO_V2_FINANCE
import { useSummaryScroll } from "pages/Finance/_TODO/useSummaryScroll";
//TODO_V2_FINANCE
import SummaryHeadline from "pages/Finance/common/ui/SummaryOrForecastHeadline/SummaryOrForecastHeadline";

import { useFinanceFiltersContext } from "../common/contexts/FinanceFiltersContext/FinanceFiltersContext";
import FinanceTableHead from "../common/ui/FinanceTableHead/FinanceTableHead";
import { getSummaryListFull, getSummaryObject, getSummaryProject } from "./model/thunks";
import ListSide from "./ui/ListSide/ListSide";
import TableSide from "./ui/TableSide/TableSide";
import SummarySettingsModal from "./widgets/SummarySettingsModal/SummarySettingsModal";

import styles from "./Summary.module.scss";

const Summary: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const { objectId, projectId } = useParams<Record<"objectId" | "projectId", string>>();
  const { tableRef, year } = useSummaryScroll();
  const { filter, setFilter } = useFinanceFiltersContext();

  const oldObjectId = useRef(objectId);
  const oldProjectId = useRef(projectId);
  useEffect(() => {
    if (oldObjectId.current !== objectId || oldProjectId.current !== projectId) {
      setFilter((prev) => ({ ...prev, markup_id: undefined }));
    }
    oldObjectId.current = objectId;
    oldProjectId.current = projectId;
  }, [objectId, projectId]);

  useEffect(() => {
    if (objectId === "0" && projectId === "0") {
      dispatch(getSummaryListFull(year));
    } else if (objectId === "0") {
      dispatch(getSummaryProject(projectId, year));
    } else {
      dispatch(getSummaryObject(projectId, objectId, year, filter));
    }
  }, [objectId, projectId, year, filter]);

  const [isSettingsModal, setIsSettingsModal] = useState(false);

  return (
    <div className={styles.summary}>
      <div className={styles.summary__listSide}>
        <ListSide>{children}</ListSide>
      </div>
      <div className={styles.summary__tableSide}>
        <div className={styles.sticky} ref={tableRef}>
          <SummaryHeadline location="summary" onSettingsClick={() => setIsSettingsModal(true)} />
          <FinanceTableHead />
        </div>
        <TableSide />
      </div>
      <SummarySettingsModal isOpen={isSettingsModal} onClose={() => setIsSettingsModal(false)} />
    </div>
  );
};

export default Summary;
