import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import { postSegment } from "../../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import Label from "shared/ui/atoms/Label/Label";
import Actions from "shared/ui/controls/Actions/Actions";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";

import styles from "./AttributeCreateModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  objectId: string;
  invalidationKey: number;
}

const AttributeCreateModal = ({ isOpen, onClose, objectId, invalidationKey }: IProps) => {
  const dispatch = useDispatch();
  const [attr1, setAttr1] = useState("");
  const [attrs2, setAttrs2] = useState<{ val: string; isSaved?: boolean; localId: number }[]>([]);

  const onSubmit = () => {
    dispatch(postSegment(objectId, attr1, attrs2));
  };

  const closeHandler = () => {
    onClose();
    setAttr1("");
    setAttrs2([]);
  };

  useEffect(() => {
    closeHandler();
  }, [invalidationKey]);

  const isPending = useTypedSelector((state) => state.settingsReducer.attributesReducer.loadings)["create"];

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={closeHandler}
      name="Создание атрибута"
      customHeadline={
        <>
          <InputBase
            label="Атрибут 1"
            placeholder="Введите название атрибута"
            value={attr1}
            onChange={(e) => setAttr1(e.target.value)}
          />
        </>
      }
    >
      <div className={styles.attr2}>
        <Label>Атрибут 2</Label>
        <AddButton onClick={() => setAttrs2((p) => [...p, { val: "", localId: Date.now() }])} />
      </div>
      <div className={styles.list}>
        {attrs2.map((el, i) => (
          <div key={el.localId} className={styles.row}>
            <InputBase
              value={attrs2[i]?.val}
              onChange={(e) =>
                setAttrs2((p) =>
                  p.map((attr) => {
                    if (attr.localId === el.localId) {
                      return { ...attr, val: e.target.value };
                    } else {
                      return attr;
                    }
                  })
                )
              }
            />
            <Actions
              canRemove
              key={el.localId}
              onRemoveDirectly={() => setAttrs2((p) => p.filter((attr) => attr.localId !== el.localId))}
            />
          </div>
        ))}
      </div>
      <BottomControls isExists isDoubleBtns>
        <ButtonBase secondary onClick={closeHandler}>
          Отменить
        </ButtonBase>
        <ButtonBase primary isLoading={isPending} onClick={onSubmit}>
          Сохранить
        </ButtonBase>
      </BottomControls>
    </ModalContainer>
  );
};

export default AttributeCreateModal;
