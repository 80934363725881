import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IOption } from "components/UI/atoms/Select";

import { handlerCoefficientsInvalidateKeySelector, handlerCoefficientsLoadingSelector } from "../../model/selectors";
import { createHandlerCoefficient, createOperationCoefficient, deleteHandlerCoefficient } from "../../model/thunks";
import HandlerCoefficientsForm from "../HandlerCoefficientsForm/HandlerCoefficientsForm";
import HandlerCoefficientsOperation from "../HandlerCoefficientsOperation/HandlerCoefficientsOperation";
import HandlerCoefficientsRow from "./HandlerCoefficientsRow";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import { ICreateCoefficient, ICreateOperation, IHandlerCoefficient } from "../../model/types";
import { IIdAndName } from "types/idAndName";

import { preProcessCoefficientCreation } from "../../utils/preProcessCoefficientCreation";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./HandlerCoefficientsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  section: IIdAndName;
  coefficients: IHandlerCoefficient[] | null;
  isLoading: boolean;
  defaultAmount: string;
  objectId: string;
}

export type CoefficientInList = IOption &
  Pick<IHandlerCoefficient, "value" | "including_items_costs"> & {
    originalName: string;
    originalId: number;
  };

const HandlerCoefficientsModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  section,
  coefficients,
  isLoading,
  defaultAmount,
  objectId,
}) => {
  const dispatch = useDispatch();
  const invalidateKey = useSelector(handlerCoefficientsInvalidateKeySelector);
  const key = generateStorageKey({ type: "pending", buildingId: objectId });
  const isPending = useSelector(handlerCoefficientsLoadingSelector)[key];
  const isEmpty = !isLoading && !coefficients?.length;
  
  const [isAdding, setIsAdding] = useState(false);
  const [isOperating, setIsOperating] = useState(false);

  const createHandler = (vals: ICreateCoefficient) => {
    const validatedVals = preProcessCoefficientCreation(vals, { sectionId: section.id });
    dispatch(createHandlerCoefficient(objectId, validatedVals as ICreateCoefficient));
  };

  const operationHandler = (vals: ICreateOperation) => {
    dispatch(createOperationCoefficient(objectId, vals));
  };

  const onRemove = (indexId: number) => {
    dispatch(deleteHandlerCoefficient(objectId, indexId));
  };

  useEffect(() => {
    setIsAdding(false);
    setIsOperating(false);
  }, [invalidateKey]);

  const coefficientsForSteps: CoefficientInList[] = useMemo(
    () =>
      coefficients?.map((cf) => ({
        id: `coefficient_${cf.id}`,
        name: `Коэффициент ${cf.number}`,
        value: cf.value,
        originalName: cf.name,
        originalId: cf.id,
        including_items_costs: cf.including_items_costs,
      })) || [],
    [coefficients]
  );

  const coefficientsById = useMemo(
    () =>
      coefficients?.reduce((acc, v) => {
        acc[v.id] = v;
        return acc;
      }, {} as Record<number, IHandlerCoefficient>) || {},
    [coefficients]
  );

  return (
    <Modal
      title="Коэффициенты"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      closeOnClickOutside={false}
    >
      {!isEmpty && (
        <div className={styles.totalsRow}>
          <div className={styles.subtitle}>ЛСР: {section?.name}</div>
          <div className={styles.total}>
            <div>Исходная стоимость:</div>
            <span>{transformDigitToFinancial(defaultAmount, { withCurrencySign: true, withFloat: true })}</span>
          </div>
        </div>
      )}
      {!isEmpty && (
        <div className={cn(styles.tableHeader, styles.cols)}>
          <div>№</div>
          <div>Название и/или обоснование коэффициента</div>
          <div className={styles.indexHeader}>Коэффициент</div>
          <div className={styles.centered}>Применен к</div>
          <div className={styles.centered}>Уточнение</div>
          <div>Применил</div>
          <div className={styles.cost}>Стоимость с учётом коэффициента</div>
        </div>
      )}
      <div className={styles.table}>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner isStatic />
          </div>
        )}
        {coefficients?.map((el) => (
          <HandlerCoefficientsRow
            values={el}
            key={el.id}
            onRemove={onRemove}
            isPending={isPending}
            coefficientsForSteps={coefficientsForSteps}
            coefficientsById={coefficientsById}
            objectId={objectId}
          />
        ))}
        {isEmpty && <div className={styles.empty}>К ЛСР нет примененных коэффициентов</div>}
      </div>
      {isAdding && (
        <HandlerCoefficientsForm
          onSubmit={createHandler}
          onCancel={() => setIsAdding(false)}
          isPending={isPending}
          coefficientsForSteps={coefficientsForSteps}
        />
      )}
      {isOperating && (
        <HandlerCoefficientsOperation
          onSubmit={operationHandler}
          onCancel={() => setIsOperating(false)}
          isPending={isPending}
          coefficientsForSteps={coefficientsForSteps}
        />
      )}
      <div className={styles.add}>
        {!isAdding && !isOperating && (
          <>
            <AddButton isWide text="Добавить коэффициент" textPosition="left" onClick={() => setIsAdding(true)} />
            {coefficients && coefficients.length > 1 && (
              <ButtonBase secondary onClick={() => setIsOperating(true)}>
                Выполнить операцию
              </ButtonBase>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default HandlerCoefficientsModal;
