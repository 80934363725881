import cn from "classnames";
import React, { useMemo } from "react";

import Select from "components/UI/atoms/Select";

import { FinanceViews, useFinanceFiltersContext } from "../../contexts/FinanceFiltersContext/FinanceFiltersContext";
import { useGetFactStatuses } from "./useGetFactStatuses";
import { useGetMultiplePrices } from "features/MultiplePrices";
import { Switch } from "shared/ui/inputs/Switch/Switch";

import styles from "./FinancePricesSettings.module.scss";

interface IProps {
  objectId: string;
  placement?: "summary" | "forecast";
}

const FinancePricesSettings = ({ objectId, placement = "summary" }: IProps) => {
  const { multiplePricesLoadings, multiplePricesOptions } = useGetMultiplePrices(objectId);

  const isSummary = placement === "summary";

  const { factStatuses, isFactStatusesLoading } = useGetFactStatuses(!isSummary);

  const { filter, setFilter, financeView, setFinanceView } = useFinanceFiltersContext();

  const planValue = useMemo(() => {
    if (filter.budget) {
      return "budget";
    }
    if (!filter.markup_id) {
      return "view";
    }
    return filter.markup_id;
  }, [filter]);

  const onChangePlanFilter = (key: any) => {
    if (key === "view") {
      setFilter((prev) => ({ ...prev, budget: undefined, markup_id: undefined }));
      return;
    }
    if (key === "budget") {
      setFilter((prev) => ({ ...prev, budget: true, markup_id: undefined }));
      return;
    }
    setFilter((prev) => ({ ...prev, budget: undefined, markup_id: key }));
  };

  const factValue = useMemo(() => {
    if (filter.accepted) return "accepted";
    if (filter.completed) return "completed";
    if (filter.to_pay) return "to_pay";
    if (filter.todo) return "todo";
  }, [filter]);

  const onChangeFactFilter = (v: any) => {
    setFilter((prev) => ({
      ...prev,
      accepted: undefined,
      completed: undefined,
      to_pay: undefined,
      todo: undefined,
      [v]: true,
    }));
  };

  return (
    <div className={cn(styles.grid, !isSummary && styles.oneRow)}>
      <div className={styles.pair}>
        <div className={styles.label}>План</div>
        <Select
          options={multiplePricesOptions}
          isLoading={multiplePricesLoadings[objectId]}
          value={planValue}
          onChange={onChangePlanFilter}
        />
      </div>
      {isSummary && (
        <div className={styles.pair}>
          <div className={styles.label}>Факт</div>
          <Select
            options={factStatuses}
            isLoading={isFactStatusesLoading}
            value={factValue}
            onChange={onChangeFactFilter}
          />
        </div>
      )}
      <div className={styles.pair}>
        <div className={styles.label}>Итого</div>
        <Switch
          label="Рубли"
          labelRight="Проценты %"
          checked={financeView !== FinanceViews.currency}
          onChange={({ target }) => {
            setFinanceView(target.checked ? FinanceViews.percents : FinanceViews.currency);
          }}
        />
      </div>
    </div>
  );
};

export default FinancePricesSettings;
