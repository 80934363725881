import { combineReducers } from "redux";

import { settingsObjectReducer } from "./Object/model/slice";
import { attributesReducer } from "./Object/tabs/SettingsObjectAttributes/model/slice";
import { settingsProjectReducer } from "./Project/model/slice";

export const settingsReducer = combineReducers({
  settingsProjectReducer,
  settingsObjectReducer,
  attributesReducer,
});
