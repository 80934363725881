import { IProjectActions } from "./actions";

import { IProjectState } from "./types";

export const createProjectInitialState = (): IProjectState => ({
  invalidateKey: 0,
  filters: {
    material: false,
    machine: false,
    transport: false,
    equipment: false,
    attribute: false,
  },
});

const initialState = createProjectInitialState();

const projectReducer = (state = initialState, action: IProjectActions): IProjectState => {
  const { type, payload } = action;
  switch (type) {
    case "project/INVALIDATE_KEY":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    case "project/SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filter]: payload.value,
        },
      };
    default:
      return state;
  }
};

export default projectReducer;
