import { Dispatch, compose } from "redux";

import {
  addExpendituresBySectionAction,
  setSectionsAction,
  startLoadingSection,
  stopLoadingSection,
} from "../sections/sections";
import {
  IGetExpendituresGroupsProps,
  addExpendituresGroupsBySectionAction,
  resetExpendituresIntervalsGroupsBySection,
} from "../shedules";
import { projectApi } from "./api";

import { applyRelatedResources } from "../sections/utils/applyRelatedResources";
import { queryParamsFormatter } from "utils/formatters/queryParamsFormatter";
import { errorCatcher } from "utils/helpers/errorCatcher";

export const getExpendituresBySegment =
  (ids: { segment: number | string; building: number | string }, filters = {}) =>
  (dispatch: Dispatch) => {
    const segmentIdFilter = { segment: ids.segment.toString() };
    const formattedfilters = queryParamsFormatter(filters, segmentIdFilter);
    return projectApi.getExpenditures(ids.building, formattedfilters).then((expenditures) => {
      applyRelatedResources(expenditures);
      return compose(
        dispatch,
        addExpendituresBySectionAction
      )({
        expenditures,
        sectionId: ids.segment,
      });
    });
  };

export const getGroupsBySegment =
  ({
    buildingId,
    segmentId,
    year,
    month,
    dateEnd,
    dateStart,
  }: Omit<IGetExpendituresGroupsProps, "sectionId"> & { segmentId: number }) =>
  (dispatch: Dispatch) => {
    const params: Record<string, string> = {
      segment: segmentId?.toString() || "",
    };
    if (dateEnd || dateStart) {
      params.planfact_start = dateStart || "";
      params.planfact_end = dateEnd || "";
    } else {
      params.planfact_year = year?.toString() || "";
      params.planfact_month = month?.toString() || "";
    }

    dispatch(resetExpendituresIntervalsGroupsBySection(segmentId));
    return projectApi.getGroups(buildingId, params).then((groups) => {
      dispatch(addExpendituresGroupsBySectionAction(groups.results, segmentId));
    });
  };

export const loadSegments =
  ({ buildingId, progressOrPlan }: { buildingId: number | string; progressOrPlan: "progress" | "plan" }) =>
  async (dispatch: Dispatch) => {
    compose(dispatch, startLoadingSection)(+buildingId);
    let response;
    if (progressOrPlan === "plan") {
      response = await projectApi
        .getSegmentsPlan(buildingId)
        .then((responseData) => compose(dispatch, setSectionsAction)(responseData))
        .catch(errorCatcher);
    } else {
      response = await projectApi
        .getSegmentsProgress(buildingId)
        .then((responseData) => compose(dispatch, setSectionsAction)(responseData?.results))
        .catch(errorCatcher);
    }
    compose(dispatch, stopLoadingSection)(+buildingId);
    return response;
  };
