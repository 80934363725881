import cn from "classnames";
import React, { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { objectDetailSelector } from "redux/modules/common/building/object/nowObject";
import { ISectionPlan } from "redux/modules/common/building/processTypes";

import Amounts, { AmountsType } from "./components/Amounts/Amounts";
import EstimateStateMark from "./components/EstimateStateMark/EstimateStateMark";
import PlanSectionMark from "./components/PlanSectionMark/PlanSectionMark";
import PricingModeBudgetIndicators from "./components/PricingModeBudgetIndicators/PricingModeBudgetIndicators";
import PricingModeEstimatedPriceIndicators from "./components/PricingModeEstimatedPriceIndicators/PricingModeEstimatedPriceIndicators";
import VisibilityMark from "./components/VisibilityMark/VisibilityMark";
import Checkbox from "_LEGACY/UI/__TODO/Checkbox/Checkbox";
import ConfirmSelect from "_LEGACY/UI/__trash/ConfirmSelect/ConfirmSelect";
import { TabsEnum } from "components/pages/Building/enums";

import Amount from "../../../../shared/ui/dataDisplay/Amount/Amount";
import PopoverOverlay from "../PopoverOverlay/PopoverOverlay";
import { usePriceSelectContext } from "features/MultiplePrices/contexts/PriceSelectContext";
import Actions, { IActionsProps } from "shared/ui/controls/Actions/Actions";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import InputBase from "shared/ui/inputs/InputBase";

import { PricingModes } from "features/MultiplePrices/utils/constants";
import ISection from "types/interfaces/Section";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import ArrowFill from "images/icons/ArrowFill";
import CheckDouble from "images/icons/CheckDouble";

import styles from "./EstimateSection.module.scss";

export interface IActions extends IActionsProps {
  onSaveEditName: (name: string) => void;
  canConfirm: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  disabled: boolean;
}

export enum ESTIMATE_SECTION_VARIANTS {
  primary = "primary",
  secondary = "secondary",
}

export interface IEstimateSectionProps {
  isChecked?: boolean;
  onCheck: (checked: boolean) => void;
  canCheck?: boolean;
  section: ISection;
  actions: IActions;
  amounts: AmountsType;
  isDisplayAmounts?: boolean;
  isDisplayEstimateStateMark?: boolean;
  variant?: ESTIMATE_SECTION_VARIANTS;
  isDisplayAddButton?: boolean;
  isShownPlanData?: boolean;
  onAddButtonClick?: () => void;
  sectionPlanData?: ISectionPlan | null;
  isCheckboxPlaceholder?: boolean;
  onRefetchSectionPlan?: (parentId: number) => void;
  dataTestId?: string | number;
  isNeedOpenSectionPlan?: boolean;
  activeTab?: keyof typeof TabsEnum;
  activePricingMode?: PricingModes;
  changeDiscountCost?: (sectionId: number, cost: string) => void;
  changeCost?: (sectionId: number, cost: string) => void;
  isWithIndicator?: boolean;
  isWithIndexes?: boolean;
  isProduction?: boolean;
  visibilityChecked: (id: number) => boolean;
  handleVisibilityCheck: (item: any) => void;
}

const EstimateSection: FC<IEstimateSectionProps> = ({
  activeTab,
  isChecked,
  onCheck,
  canCheck,
  section,
  actions,
  amounts,
  isDisplayAmounts,
  isDisplayEstimateStateMark,
  variant = ESTIMATE_SECTION_VARIANTS.primary,
  isDisplayAddButton,
  onAddButtonClick,
  sectionPlanData,
  isShownPlanData,
  isCheckboxPlaceholder,
  onRefetchSectionPlan,
  dataTestId,
  isNeedOpenSectionPlan,
  activePricingMode = PricingModes.view,
  changeDiscountCost,
  changeCost,
  isWithIndicator,
  isWithIndexes,
  isProduction = false,
  visibilityChecked,
  handleVisibilityCheck,
}) => {
  const building = useSelector(objectDetailSelector);
  const isBuildingShared = building && building.is_shared;

  const [isEdit, setIsEdit] = useState(false);
  const [changedName, setChangedName] = useState(section.name);

  const showEdit = useCallback(() => setIsEdit(true), []);

  const isShowCheckbox = useMemo(() => !activeTab || activeTab === TabsEnum.progress, [activeTab]);

  const onChangeNameInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setChangedName(e.target.value),
    []
  );

  const onSubmitEditSectionName = useCallback(() => {
    setIsEdit(false);
    if (changedName === section.name) return;
    actions.onSaveEditName(changedName);
  }, [actions, changedName, section.name]);

  const onKeyDownNameInput: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key !== "Enter") return;
      onSubmitEditSectionName();
    },
    [onSubmitEditSectionName]
  );

  const handleOnCheck = useCallback(() => onCheck(!isChecked), [onCheck, isChecked]);

  const handleChangeDiscountCost = useCallback(
    (cost: string) => {
      changeDiscountCost?.(section.id, cost);
    },
    [changeDiscountCost, section.id]
  );

  const handleChangeCost = useCallback(
    (cost: string) => {
      changeCost?.(section.id, cost);
    },
    [changeCost, section.id]
  );

  const isShowActions = actions && !isBuildingShared;
  const isArrowVisible = !activePricingMode;

  const providers = useMemo(() => section?.providers ?? [], [section]);
  const isAllProvidersAccepted = useMemo(() => {
    return !!providers.length && providers.every((p) => p.accepted);
  }, [providers]);

  const isHandler = window.location.pathname.includes("handler");

  const { isSomeEstimatedPrice } = usePriceSelectContext();

  return (
    <div
      className={cn(styles.sectionRow, styles[variant], {
        [styles.withIndicator]: isWithIndicator,
        [styles.withArrowPadding]: isArrowVisible,
      })}
      data-testid={dataTestId}
    >
      <div className={styles.titleContainer}>
        {!isHandler && !canCheck && !!section.providers?.length && (
          <PopoverOverlay
            openType="hover"
            placement="top"
            popoverBorderColor="primary"
            content={
              <div className={styles.providersList}>
                {providers.map((p) => (
                  <div className={styles.providerItem} key={p?.entity_id}>
                    <span className={styles.status}>{p?.accepted ? "Передано" : "Ожидает подтверждения"}</span>
                    <span className={styles.providerName}>{p?.entity_name}</span>
                  </div>
                ))}
              </div>
            }
          >
            <CheckDouble className={styles.checkDouble} variant={isAllProvidersAccepted ? "primary" : "default"} />
          </PopoverOverlay>
        )}
        {isProduction && (
          <div onClick={stopEventPropagation} className={styles.checkboxContainer}>
            <Checkbox
              checked={visibilityChecked(section.id)}
              onChange={(e) => {
                e.stopPropagation();
                handleVisibilityCheck(section);
              }}
              checkboxClassName={styles.checkbox}
            />
          </div>
        )}
        {canCheck && !isCheckboxPlaceholder && (
          <div className={styles.checkboxContainer} onClick={stopEventPropagation}>
            {isShowCheckbox && (
              <Checkbox checked={!!isChecked} onChange={handleOnCheck} checkboxClassName={styles.checkbox} />
            )}
          </div>
        )}
        {canCheck && isCheckboxPlaceholder && (
          <div className={cn(styles.checkboxContainer, styles.checkboxPlaceholder)}>
            {isShowCheckbox && (
              <Checkbox checked={!!isChecked} onChange={handleOnCheck} checkboxClassName={styles.checkbox} />
            )}
          </div>
        )}
        {isEdit ? (
          <div className={styles.inputContainer} onClick={stopEventPropagation}>
            <InputBase
              classNameInput={styles.input}
              value={changedName}
              onChange={onChangeNameInput}
              onKeyDown={onKeyDownNameInput}
              placeholder="Введите наименование раздела"
              input={undefined}
              className={undefined}
              label={undefined}
              onBlur={undefined}
            />
          </div>
        ) : (
          <span className={styles.title} title={changedName}>
            {changedName}
          </span>
        )}
      </div>
      <div className={styles.leftSide} onClick={stopEventPropagation}>
        {sectionPlanData && isShownPlanData && (
          <PlanSectionMark
            {...sectionPlanData}
            onRefetchSectionPlan={onRefetchSectionPlan}
            isNeedToOpen={isNeedOpenSectionPlan}
            className={styles.sectionPlanButton}
          />
        )}
        {!sectionPlanData && isDisplayAddButton && (
          <AddButton className={styles.addBtn} onClick={onAddButtonClick} text="" />
        )}
        {isShowActions && (
          <div
            className={cn(styles.actions, {
              [styles.actionsMargin]: activePricingMode !== PricingModes.view || !isDisplayEstimateStateMark,
            })}
            onClick={stopEventPropagation}
          >
            <Actions
              className={styles.actionBlock}
              canSave={isEdit}
              onSave={onSubmitEditSectionName}
              canEdit={!isEdit && actions.canEdit}
              onEdit={showEdit}
              canRemove={actions.canRemove}
              onRemove={actions.onRemove}
            />
            {actions.canConfirm && (
              <ConfirmSelect
                className={styles.confirmSelect}
                onConfirm={actions.onConfirm}
                onCancel={actions.onCancel}
                isLoading={actions.isLoading}
                disabled={actions.disabled}
              />
            )}
          </div>
        )}

        {activePricingMode === PricingModes.view && (
          <>
            {isDisplayEstimateStateMark && section.confirmed_state && (
              <EstimateStateMark state={section.confirmed_state} />
            )}
            {isProduction && (
              <VisibilityMark
                variant={
                  section.hidden_status === 0
                    ? "allAvailable"
                    : section.hidden_status === 1
                    ? "allHidden"
                    : section.hidden_status === 2
                    ? "partHidden"
                    : "allAvailable"
                }
              />
            )}
            {isDisplayAmounts && <Amounts amounts={amounts} section={section} isWithIndexes={isWithIndexes} />}
            {(isDisplayAmounts || isDisplayEstimateStateMark || actions) && <div className={styles.divider} />}
            <div className={styles.budgetCol}>
              <Amount
                className={styles.budgetTitle}
                title={"По смете"}
                value={amounts.all}
                measure={amounts.measure}
                isBold
                isLeft
              />
            </div>
          </>
        )}

        {activePricingMode === PricingModes.budget && (
          <PricingModeBudgetIndicators amounts={amounts} changeDiscountCost={handleChangeDiscountCost} />
        )}
        {isSomeEstimatedPrice && (
          <PricingModeEstimatedPriceIndicators
            amounts={amounts}
            changeCost={handleChangeCost}
            key={activePricingMode}
          />
        )}
        {isArrowVisible && <ArrowFill className={styles.arrowIcon} rotate="-90" />}
      </div>
    </div>
  );
};

export default React.memo(EstimateSection);
