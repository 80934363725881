import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";
import { getAosr } from "redux/modules/common/building/journal/aosr/thunks";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

export const useActingActs = (expenditure: IExpenditureInActing) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();
  const activeAosr = useMemo(() => {
    return expenditure.aosr; //?.find((el) => el.status === "close");
  }, [expenditure]);

  const activeAook = useMemo(() => {
    return expenditure.aook; //?.find((el) => el.status === "close");
  }, [expenditure]);

  const [isAosrOpen, setIsAosrOpen] = useState(false);
  const [isAookOpen, setIsAookOpen] = useState(false);

  useEffect(() => {
    if (!isAosrOpen) return;
    dispatch(getAosr(objectId, expenditure.id));
  }, [objectId, expenditure.id, isAosrOpen /* invalidateAosrKey */]);

  return {
    activeAosr,
    activeAook,
    isAosrOpen,
    isAookOpen,
    setIsAosrOpen,
    setIsAookOpen,
  };
};
