import React, { PropsWithChildren, createContext, useContext, useState } from "react";

const MaterialsInvalidationKeyContext = createContext({ invalidationKey: 0, updateInvalidationKey: () => {} });

//TODO временный костыль пока материалы не переедут со старого редакса
export const MaterialsInvalidationKeyContextProvider = ({ children }: PropsWithChildren) => {
  const [key, setKey] = useState(0);

  const updateInvalidationKey = () => {
    setKey((p) => p + 1);
  };

  return (
    <MaterialsInvalidationKeyContext.Provider value={{ invalidationKey: key, updateInvalidationKey }}>
      {children}
    </MaterialsInvalidationKeyContext.Provider>
  );
};

export const useMaterialsInvalidationKeyContext = () => useContext(MaterialsInvalidationKeyContext);
