import axios from "axios";

import { IAttribute } from "./types";
import { IListResponseData, ListResponse } from "types/ListResponse";

export const attributesApi = {
  getList: (objectId: string) => {
    return axios.get<IListResponseData<IAttribute>>(`/building/${objectId}/segments/`, { params: { limit: 500 } });
  },
  post: (objectId: string, data: any) => {
    return axios.post(`/building/${objectId}/segments/`, data);
  },
  bulkCreate: (objectId: string, data: any[]) => {
    return axios.post(`/building/${objectId}/segments/bulk-create/`, data);
  },
  patch: (objectId: string, id: number, data: any) => {
    return axios.patch(`/building/${objectId}/segments/${id}/`, data);
  },
  delete: (objectId: string, id: number) => {
    return axios.delete(`/building/${objectId}/segments/${id}/`);
  },
};
