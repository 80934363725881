import cn from "classnames";
import React, { ChangeEvent, useMemo, useRef } from "react";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import { ReactComponent as CheckBlackThin } from "images/icons/check-black-thin.svg";

import styles from "./Checkbox.module.scss";

export const enum LabelPlacement {
  LEFT = "left",
  RIGHT = "right",
}

interface IProps {
  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  indeterminate?: boolean;
  children?: React.ReactNode;
  className?: string;
  labelPlacement?: LabelPlacement;
  disabled?: boolean;
  title?: string;
}

const Checkbox: React.FC<IProps> = (props) => {
  const {
    onCheck,
    checked,
    children,
    className,
    title,
    labelPlacement = LabelPlacement.LEFT,
    disabled = false,
    indeterminate = false,
  } = props;
  const ref = useRef(null);

  const generatedId = useMemo(() => {
    return "checkbox" + Math.random();
  }, []);

  const handleOnCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onCheck(e);
    }
  };

  return (
    <label
      ref={ref}
      htmlFor={generatedId}
      className={cn(styles.container, className)}
      onClick={stopEventPropagation}
      title={title}
    >
      {labelPlacement === LabelPlacement.LEFT && children}
      <input
        id={generatedId}
        type="checkbox"
        onChange={handleOnCheck}
        className={styles.checkbox}
        checked={checked}
        disabled={disabled}
      />
      <label
        className={cn(styles.borders, "checkbox", { [styles.labelRight]: labelPlacement === LabelPlacement.RIGHT })}
        htmlFor={generatedId}
      >
        {checked && !indeterminate && <CheckBlackThin />}
        {indeterminate && <div className={styles.indeterminateThin} />}
      </label>
      {labelPlacement === LabelPlacement.RIGHT && children}
    </label>
  );
};

export default React.memo(Checkbox);
