import { Dispatch } from "redux";

import { IFinanceCommonFilter } from "pages/Finance/common/contexts/FinanceFiltersContext/FinanceFiltersContext";
import { generateFinanceKey } from "pages/Finance/common/model/commonFinanceUtils";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { summaryApi } from "./api";
import { financeSummaryActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IIdAndName } from "types/idAndName";

import { summaryUtils } from "./utils";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getSummaryListFull = (year: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = generateStorageKey({ type: "all", year });
  if (getState().financeReducer.summary.loadings[key] === undefined) {
    dispatch(financeSummaryActions.setLoading({ key, status: true }));
  }
  summaryApi
    .getListFull()
    .then(({ data }) => {
      dispatch(financeSummaryActions.setProjects({ data, year }));
    })
    .finally(() => {
      dispatch(financeSummaryActions.setLoading({ key, status: false }));
    });
};

export const getSummaryProject =
  (projectId: string, year: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ projectId, type: "project" });
    const wasntInCommonList = !getState().financeReducer.summary.dataTree[year]?.find((el) => el.id === +projectId);
    if (wasntInCommonList) {
      dispatch(financeSummaryActions.setLoading({ key, status: true }));
    }
    summaryApi
      .getListByProject(+projectId, year)
      .then(({ data }) => {
        dispatch(financeSummaryActions.setProject({ data, projectId: +projectId, year }));
        if (data[0]?.buildings?.length === 1) {
          const child = summaryUtils.findNode(getState().financeReducer.summary.dataTree[year] ?? [], {
            id: data[0]?.buildings[0].id,
            type: "object",
            key: generateFinanceKey(data[0]?.buildings[0].id, "object"),
          });
          if (child) {
            dispatch(toggleSummaryTree(child, year) as any);
          }
        }
      })
      .finally(() => {
        dispatch(financeSummaryActions.setLoading({ key, status: false }));
      });
  };

export const getSummaryObject =
  (projectId: string, objectId: string, year: number, filter?: IFinanceCommonFilter) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const actualKey = generateStorageKey({ year, ...filter });
    const key = generateStorageKey({ buildingId: objectId, type: "object" });
    const wasntInCommonList = !getState()
      .financeReducer.summary.dataTree[year]?.find((el) => el.id === +projectId)
      ?.children?.find((el) => el.id === +objectId)?.children?.length;
    if (wasntInCommonList) {
      dispatch(financeSummaryActions.setLoading({ key, status: true }));
    }
    summaryApi
      .getDetailedObject(+objectId, year, filter)
      .then(({ data }) => {
        dispatch(financeSummaryActions.setObject({ data, projectId: +projectId, objectId: +objectId, year }));
      })
      .finally(() => {
        dispatch(financeSummaryActions.setLoading({ key, status: false }));
      });
  };

export const toggleSummaryTree =
  (subTree: IFinanceTreeItem, year: number) => (dispatch: Dispatch, getState: () => RootState) => {
    if (subTree.isOpen) {
      dispatch(financeSummaryActions.closeItem({ year, item: subTree }));
    } else {
      dispatch(financeSummaryActions.openItem({ year, item: subTree }));
      if (subTree.type === "object" && subTree.shouldBeLoaded) {
        const tree = getState().financeReducer.summary.dataTree;
        const parent = tree[year]?.find((project) => project.children?.some((obj) => obj.id === subTree.id));
        dispatch(getSummaryObject(String(parent?.id), String(subTree.id), year) as any);
      }
    }
  };

export const downloadSummaryReport = (buildigId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const buildingName = getState().objects.objectsBreadcrumbs?.results?.find(
    (el: IIdAndName) => el.id == buildigId
  )?.name;
  const reportName = buildingName ? `Сводная по ${buildingName}.xlsx` : "Сводная.xlsx";
  summaryApi.downloadReport(buildigId, reportName);
};
