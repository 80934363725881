import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSpecificationsSections,
  specificationSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import Section from "../../../../../../Section";
import ButtonBase from "shared/ui/controls/ButtonBase";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";

import useDebounce from "utils/hooks/useDebouncedValue";

import styles from "./../../Materials.module.scss";

interface IProps {
  handleAddProducts: () => void;
  type: string;
  productsCount: number;
  handleSetSection: (data: unknown) => void;
  objectId: string;
}

const SectionLevel: React.FC<IProps> = ({ handleAddProducts, type, productsCount, handleSetSection, objectId }) => {
  const dispatch = useDispatch();
  const [searchBarValue, setSearchBarValue] = useState<string>();
  const debouncedSearchBarValue = useDebounce(searchBarValue, 500);
  const sections = useSelector(specificationSectionsSelector);

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBarValue(event.target.value);
  };

  useEffect(() => {
    if (!debouncedSearchBarValue && typeof searchBarValue !== "string") return;
    dispatch(getSpecificationsSections(objectId, type, searchBarValue));
  }, [debouncedSearchBarValue, objectId, type]);

  return (
    <div className={styles.container}>
      <div className={styles.searchBlock}>
        <InputSearchRound
          onChange={handleSearchBarChange}
          className={styles.inputSearchClassName}
          value={searchBarValue}
        />
        <ButtonBase onClick={handleAddProducts} disabled={!productsCount} primary>
          Добавить
        </ButtonBase>
      </div>
      {sections?.results?.map((item: any) => (
        //b-1256
        /* item.products_count !== 0 &&  */ <Section key={item.id} data={item} onClick={handleSetSection} type={type} />
      ))}
    </div>
  );
};

export default SectionLevel;
