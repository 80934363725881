import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IIdAndName } from "types/idAndName";

interface MultiplePricesState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  prices: Record<string, IIdAndName[]>;
}

export const initialState: MultiplePricesState = {
  invalidationKey: 0,
  loadings: {},
  prices: {},
};

const multiplePricesSlice = createSlice({
  name: "multiplePrices",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setPrices: (state, action: PayloadAction<{ data: IIdAndName[]; key: string }>) => {
      const { data, key } = action.payload;
      state.prices[key] = data;
    },
    addPriceOptimistic: (state, action: PayloadAction<{ option: IIdAndName; key: string }>) => {
      const { option, key } = action.payload;
      state.prices[key] = [...(state.prices[key] ?? []), option];
    },
    replacePrice: (state, action: PayloadAction<{ oldId: number; key: string; newId: number; newName?: string }>) => {
      const { oldId, key, newId, newName } = action.payload;
      state.prices[key] = (state.prices[key] ?? []).map((el: any) => {
        if (el.id === oldId) {
          return { ...el, id: newId, name: newName ?? el.name };
        }
        return el;
      });
    },
  },
});

export const multiplePricesActions = multiplePricesSlice.actions;

export const multiplePricesReducer = multiplePricesSlice.reducer;
