import { AxiosRequestConfig } from "axios";

const authTokenInterceptor = (request: AxiosRequestConfig<any>) => {
  if (!request.headers) {
    request.headers = {};
  }

  request.headers["Cache-Control"] = "no-cache";

  const storeToken = localStorage.getItem("authToken");
  if (storeToken) {
    request.headers.Authorization = `Token ${storeToken}`;
  }

  return request;
};

export default authTokenInterceptor;