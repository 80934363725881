import _ from "lodash";

import { IEstimateTarget } from "./AddRelationModalSections/AddRelationModalSections";

import { ExpenditureType } from "../../../../../../types/enums/ExpenditureTypeEnum";
import { ISectionTree } from "types/interfaces/Section";

export const filterSectionsBySearch = (sections: ISectionTree[], search: string) => {
  if (!sections) return [];
  const sectionsCopy = _.cloneDeep(sections);
  const filteredSections = sectionsCopy.filter((section) => {
    const filteredSubSections = [...section.subsections].filter((subSection) => {
      const filteredExpenditures = [...subSection.expenditures].filter((expenditure) => {
        return expenditure.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      if (!!filteredExpenditures.length) {
        subSection.expenditures = filteredExpenditures;
        return true;
      } else {
        return false;
      }
    });
    if (!!filteredSubSections.length) {
      section.subsections = filteredSubSections;
      return true;
    } else {
      return false;
    }
  });

  return filteredSections;
};

export const findEstimateTargets = (sections: ISectionTree[], expenditureIds: number[]): IEstimateTarget[] => {
  if (!sections || !expenditureIds.length) {
    return [];
  }

  const result: { sectionId: number; subsectionId: number; expenditureId: number }[] = [];

  expenditureIds.forEach((id) => {
    sections.forEach((section) => {
      section.subsections.forEach((subSection) => {
        const exp = subSection.expenditures.find((expenditure) => expenditure.id === id);
        if (!!exp) {
          result.push({ sectionId: section.id, subsectionId: subSection.id, expenditureId: id });
        }
      });
    });
  });

  //@ts-expect-error
  return result;
};

export const filterSectionsByExpenditureType = (sections: ISectionTree[], expenditureType: ExpenditureType) => {
  if (!sections) return [];
  const sectionsCopy = _.cloneDeep(sections);
  const filteredSections = sectionsCopy.filter((section) => {
    const filteredSubSections = [...section.subsections].filter((subSection) => {
      const filteredExpenditures = [...subSection.expenditures].filter((expenditure) => {
        return expenditure.expenditure_type === expenditureType;
      });
      if (!!filteredExpenditures.length) {
        subSection.expenditures = filteredExpenditures;
        return true;
      } else {
        return false;
      }
    });
    if (!!filteredSubSections.length) {
      section.subsections = filteredSubSections;
      return true;
    } else {
      return false;
    }
  });

  return filteredSections;
};
