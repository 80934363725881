import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateSpecificationExpenditure } from "redux/modules/common/building/materials/specification";

import { IMatchingInfo } from "./MatchingModal";

import { errorCatcher } from "utils/helpers/errorCatcher";

interface IuseMatchingModalExpenditure {
  rawInfo: IMatchingInfo;
  isActive: boolean;
  objectId: string;
}

export const useMatchingModalExpenditure = ({ rawInfo, isActive, objectId }: IuseMatchingModalExpenditure) => {
  // const [info, setInfo] = useState(rawInfo);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isActive) return;
    if (rawInfo.estimate_expenditure && !rawInfo?.estimate_expenditure.type) {
      setLoading(true);
      axios
        .get(`/building/${objectId}/materials_v2/expenditure/${rawInfo.estimate_expenditure.id}/`)
        .then(({ data }) => {
          dispatch(updateSpecificationExpenditure({...rawInfo, estimate_expenditure: Object.assign({}, rawInfo.estimate_expenditure || {}, data) }));
          // setInfo((prev) => ({
          //   ...prev,
          //   estimate_expenditure: Object.assign({}, prev.estimate_expenditure || {}, data),
          // }));
        })
        .catch(errorCatcher)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isActive, objectId, rawInfo?.estimate_expenditure]);

  return {
    info: rawInfo,
    isLoading,
  };
};
