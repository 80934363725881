import { message } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { IProduct } from "types/interfaces/Product";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { updateSpecificationExpenditure } from "redux/modules/common/building/materials/specification";

interface IProps {
  tab: string;
  selectedEstimateTarget: any;
  onClose: any;
  itemId: number;
  subSectionId: number;
  successCallback?: (data: IProduct) => void;
}

export const useSpecificationRelationsSubmit = ({
  tab,
  selectedEstimateTarget,
  onClose,
  itemId,
  subSectionId,
  successCallback,
}: IProps) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const [isPending, setIsPending] = useState(false);
  const [isSplitting, setIsSplitting] = useState(false);

  const handleSubmit = () => {
    if (tab === "rd") {
      setIsSplitting(true);
      onClose();
      return;
    }
    if (!selectedEstimateTarget) return;
    setIsPending(true);
    axios
      .patch(`/building/${objectId}/materials/specification/${itemId}/`, {
        estimate_expenditure_id: selectedEstimateTarget.expenditureId,
      })
      .then((response) => {
        setIsPending(false);
        message.success("Продукт сопоставлен");
        successCallback?.(response.data);
        dispatch(updateSpecificationExpenditure(response.data));
        // dispatch(getSpecificationsExpenditures(objectId, SPECIFICATION, subSectionId, false));
        onClose();
      })
      .catch(errorCatcher);
  };

  return { isPending, isSplitting, setIsSplitting, handleSubmit };
};
