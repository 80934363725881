import { message } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { expendituresBySectionsSelector } from "../../../../redux/modules/common/building/sections/sections";
import { getLiteEstimateSectionsTree } from "../../../../redux/modules/common/building/sections/sectionsApi";

import { IMatchingInfo } from "components/pages/Journal/components/JournalDelivery/components/JournalTickets/JournalTickets";
import { filterSectionsByExpenditureType } from "components/UI/_TODO/Expenditure/components/AddRelationsModal/utils";
import BottomControls from "../WorkOrMaterialsModals/components/BottomControls/BottomControls";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "../../../../shared/ui/controls/ButtonBase";
import { InputSearchRound } from "../../../../shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "../../../../shared/ui/modal/SliderModal/SliderModal";
import SectionsWithCheckedExpenditures, {
  IEstimateTarget
} from "../SectionsWithCheckedExpenditures/SectionsWithCheckedExpenditures";

import { ExpenditureType } from "types/enums/ExpenditureTypeEnum";
import { IProduct } from "types/interfaces/Product";
import { IExpenditureTree, ISectionTree, ISubsectionTree } from "types/interfaces/Section";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";
import { filterSectionsBySearch, findEstimateTarget } from "./utils";

import styles from "./index.module.scss";

export interface IEstimateTargetPath {
  section: ISectionTree | undefined;
  subsection: ISubsectionTree | undefined;
  expenditure: IExpenditureTree | undefined;
}

interface IProps {
  idAddRelation: IMatchingInfo | null;
  updateProducts: (data: IProduct) => void;
  handleClose: () => void;
  objectId: string;
  type?: string;
  onSubmit: (objectId: number | undefined, idAddRelation: IEstimateTargetPath) => (inner_id: number) => void;
  typeForFilter?: ExpenditureType;
  isExternalMultiple?: boolean;
  externalCheckedExpenditureIds?: Record<number, boolean>;
  externalOnSelect: (id: number) => void;
  canSubmitEmpty?: boolean;
}

const AddRelationToProduct: React.FC<IProps> = ({
  idAddRelation,
  updateProducts,
  handleClose,
  objectId,
  type,
  onSubmit,
  typeForFilter,
  isExternalMultiple,
  externalOnSelect,
  externalCheckedExpenditureIds,
  canSubmitEmpty,
}) => {
  const [sections, setSections] = useState<ISectionTree[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const expendituresBySections = useSelector(expendituresBySectionsSelector);

  const [selectedEstimateTarget, setSelectedEstimateTarget] = useState<IEstimateTarget | null>(null);

  const handleCloseModal = () => {
    handleClose();
  };

  const getEstimateTargetPath = useCallback(() => {
    const selectedSection = sections.find((section) => section.id === selectedEstimateTarget?.sectionId);
    const selectedSubsection = selectedSection?.subsections.find(
      (subsection) => subsection.id === selectedEstimateTarget?.subsectionId
    );
    const selectedExpenditure = selectedSubsection?.expenditures.find(
      (expenditure) => expenditure.id === selectedEstimateTarget?.expenditureId
    );

    return { section: selectedSection, subsection: selectedSubsection, expenditure: selectedExpenditure };
  }, [expendituresBySections, sections, selectedEstimateTarget]);

  const handleSubmit = useCallback(() => {
    if (canSubmitEmpty && onSubmit) {
      onSubmit(selectedEstimateTarget?.expenditureId, getEstimateTargetPath());
    }

    if (!selectedEstimateTarget) return;

    if (!onSubmit) {
      axios
        .patch(`/building/${objectId}/materials/${type}/${idAddRelation?.id}/`, {
          estimate_expenditure_id: selectedEstimateTarget.expenditureId,
        })
        .then((response) => {
          message.success("Продукт сопоставлен");
          if (updateProducts) updateProducts(response.data);
        })
        .catch(errorCatcher);
    } else {
      onSubmit(selectedEstimateTarget.expenditureId, getEstimateTargetPath());
    }

    handleClose();
  }, [
    getEstimateTargetPath,
    handleClose,
    idAddRelation?.id,
    objectId,
    onSubmit,
    selectedEstimateTarget,
    type,
    updateProducts,
  ]);

  const onSelectExpenditure = useCallback(
    (estimateTarget: IEstimateTarget, isSelect: boolean) => {
      if (isExternalMultiple) {
        externalOnSelect(estimateTarget.expenditureId!);
      }
      if (isSelect) setSelectedEstimateTarget(estimateTarget);
    },
    [isExternalMultiple, externalOnSelect]
  );

  const searchedSections = useMemo(() => filterSectionsBySearch(sections, search), [sections, search]);

  const onChangeSearchInput = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  const debouncedOnChangeSearchInput = useMemo(() => debounce(onChangeSearchInput, 300), [onChangeSearchInput]);

  useEffect(() => {
    if (!idAddRelation) return;
    getLiteEstimateSectionsTree(objectId).then((data) => {
      if (typeForFilter) {
        setSections(filterSectionsByExpenditureType(data.sections, typeForFilter));
      } else {
        setSections(data.sections);
      }

      const target = findEstimateTarget(data.sections, idAddRelation?.expenditure_id);
      setSelectedEstimateTarget(target);
      setIsLoading(false);
    });
  }, [objectId, idAddRelation?.id]);

  const isExternalsDisabled = useMemo(() => {
    if (!isExternalMultiple) return false;
    if (canSubmitEmpty) return false;
    return !Object.values(externalCheckedExpenditureIds!).filter((el) => !!el)?.length;
  }, [externalCheckedExpenditureIds, isExternalMultiple, canSubmitEmpty]);

  const submitDisabled = isExternalMultiple ? isExternalsDisabled : !selectedEstimateTarget;

  return (
    <SliderModal isOpen={!!idAddRelation?.id} closeHandler={handleCloseModal} className={styles.sliderClassName}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Связь со сметой</div>
          </div>
          <div className={styles.searchBlock}>
            <InputSearchRound onChange={debouncedOnChangeSearchInput} className={styles.inputClassName} />
            <ButtonBase className={styles.btn} primary onClick={handleSubmit} disabled={submitDisabled}>
              Связать
            </ButtonBase>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {!isLoading /* @ts-ignore */ ? (
              <SectionsWithCheckedExpenditures
                selectedEstimateTarget={selectedEstimateTarget}
                sections={searchedSections}
                opened={!!search.length}
                onCheckExpenditure={onSelectExpenditure}
                externalCheckedExpenditures={externalCheckedExpenditureIds}
              />
            ) : (
              <Spinner isStatic />
            )}
          </div>
        </div>
        <BottomControls isEmpty isExists />
      </div>
    </SliderModal>
  );
};

export default React.memo(AddRelationToProduct);
