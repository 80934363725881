import _, { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

import { IStructWithRecursiveChildren } from "./useSpecificationRelationsData";

export const useSpecificationsSearch = (actualData: IStructWithRecursiveChildren[] | undefined) => {
  const [search, setSearch] = useState("");

  const onChangeSearchInput = useCallback((e: any) => {
    setSearch(e.target.value);
  }, []);

  const debouncedOnChangeSearchInput = useMemo(() => debounce(onChangeSearchInput, 300), [onChangeSearchInput]);

  const searchedSections = useMemo(() => filterSectionsBySearch(actualData ?? [], search), [actualData, search]);

  return {
    debouncedOnChangeSearchInput,
    searchedSections,
    search,
  };
};

export const filterSectionsBySearch = (
  sections: IStructWithRecursiveChildren[],
  search: string = ""
): IStructWithRecursiveChildren[] => {
  if (!sections) return [];
  const sectionsCopy = _.cloneDeep(sections);
  const filteredSections = sectionsCopy.filter((section) => {
    const filteredSubSections = section.children?.filter((subSection) => {
      const filteredExpenditures = subSection.children?.filter((expenditure) => {
        return expenditure.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      if (!!filteredExpenditures?.length) {
        subSection.children = filteredExpenditures;
        return true;
      } else {
        return false;
      }
    });
    if (!!filteredSubSections?.length) {
      section.children = filteredSubSections;
      return true;
    } else {
      return false;
    }
  });

  return filteredSections;
};
