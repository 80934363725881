import cn from "classnames";
import React, { useRef, useState } from "react";
import { Field } from "react-final-form";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import { Switch } from "shared/ui/inputs/Switch/Switch";

import useOnClickOutside from "hooks/useOnClickOutside";

import { ReactComponent as SettingsIcon } from "shared/assets/icons/Union.svg";

import styles from "./EditObjectPopup.module.scss";

const EditObjectPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popover = useRef<HTMLDivElement>(null);
  const trigger = useRef<HTMLDivElement>(null);
  useOnClickOutside(
    popover,
    () => {
      setIsOpen(false);
    },
    { excludeRefs: [trigger] }
  );

  return (
    <PopoverOverlay
      popoverClassName={cn(!isOpen && styles.popover)}
      popoverBorderColor="primary"
      placement="bottom"
      openType="click"
      isOpen
      content={
        <div className={styles.content} ref={popover}>
          <b className={styles.title}>Настройки объекта</b>
          <div className={styles.form}>
            <Field
              name="allow_shift"
              render={({ input, meta }) => (
                <Switch
                  className={styles.switcher}
                  label="Анализ планов работ"
                  checked={input.value ?? false}
                  onChange={(e) => {
                    input.onChange(!input.value);
                  }}
                />
              )}
            />
            <Field
              name="new_close_dates"
              render={({ input, meta }) => (
                <Switch
                  className={styles.switcher}
                  label="Разнесение по фактам"
                  checked={input.value ?? false}
                  onChange={(e) => input.onChange(!input.value)}
                />
              )}
            />
            <Field
              name="is_simple_parser"
              render={({ input, meta }) => (
                <Switch
                  className={styles.switcher}
                  label={`Упрощенная смета`}
                  checked={input.value ?? false}
                  onChange={(e) => input.onChange(!input.value)}
                />
              )}
            />
          </div>
        </div>
      }
    >
      <div ref={trigger}>
        <SettingsIcon className={styles.settingsIcon} onClick={() => setIsOpen((p) => !p)} />
      </div>
    </PopoverOverlay>
  );
};

export default EditObjectPopup;
