import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import {
  expendituresFueDataSelector,
  expendituresFueIsLoadingSelector,
  expendituresSelector,
  getExpendituresFue,
  setExpendituresFueData,
  setIsExpendituresFueLoading,
} from "redux/modules/common/building/sections/sections";

import CheckboxFieldComponent from "../../../../_LEGACY/UI/CheckboxFieldComponent/CheckboxFieldComponent";

import Actions from "../../../../shared/ui/controls/Actions/Actions";
import ButtonBase from "../../../../shared/ui/controls/ButtonBase";
import InputBase, { VALUE_TYPES } from "../../../../shared/ui/inputs/InputBase";
import Select from "../../atoms/Select";
import MeasureSelect from "entities/MeasureSelect/MeasureSelect";

import { materialsType } from "../../../../constants/constant";
import { ExpenditureTypeEnum } from "types/enums/ExpenditureTypeEnum";

import useDebounce from "utils/hooks/useDebouncedValue";

import {
  composeFieldValidators,
  maxLength,
  mustBeEstimateNumber,
  mustBeNumber,
  required,
} from "../../../../utils/formHelpers/validations";

import styles from "./ExpenditureForm.module.scss";

const EXPENDITURE_TYPES = [
  { name: "Работа", id: "work" },
  { name: "Материал", id: "material" },
  { name: "Транспорт", id: "transport" },
  { name: "Оборудование", id: "equipment" },
];

const DEFAULT_REQUIRED_FIELDS = ["name", "measure", "count", "price", "justification", "expenditure_type"];

const getJustificationText = (justification) => {
  if (!justification) return "";
  if (!isNaN(justification[0])) return `ФЕР ${justification}`;
  return justification;
};

const ExpenditureForm = ({
  submit,
  isEstimate,
  isServices = false,
  initialValues,
  requiredFields = DEFAULT_REQUIRED_FIELDS,
  isWithNumberField,
  isInRow,
  onCancel,
  isDefault,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  const expendituresFueData = useSelector(expendituresFueDataSelector);
  const expendituresFueIsLoading = useSelector(expendituresFueIsLoadingSelector);
  const expenditures = useSelector(expendituresSelector);

  const [searchBarValue, setSearchBarValue] = useState(() => initialValues?.name || "");
  const [activeSelectedItem, setActiveSelectedItem] = useState();

  const debouncedSearchBarValue = useDebounce(searchBarValue, 500);

  const presetValues = useMemo(() => {
    return {
      ...initialValues,
      name: activeSelectedItem?.name || initialValues?.name,
      measure: activeSelectedItem?.measure_unit || initialValues?.measure,
      count: initialValues?.count,
      price: activeSelectedItem?.price || initialValues?.price,
      justification: getJustificationText(activeSelectedItem?.code) || initialValues?.justification,
      expenditure_type: activeSelectedItem ? EXPENDITURE_TYPES[0]?.id : "" || initialValues?.expenditure_type,
      related_work_id: initialValues?.related_work_id || initialValues?.related_work?.id,
    };
  }, [activeSelectedItem, initialValues]);

  const filteredExpendituresByType = useMemo(
    () =>
      expenditures?.results &&
      expenditures.results.filter(
        (item) => item?.id === initialValues?.related_work_id || item?.expenditure_type === "work"
      ),
    [expenditures]
  );

  useEffect(() => {
    if (expendituresFueIsLoading) return;

    dispatch(setIsExpendituresFueLoading(true));
  }, [expendituresFueIsLoading]);

  useEffect(() => {
    if (!debouncedSearchBarValue || expendituresFueData?.some((item) => item.name === searchBarValue)) {
      dispatch(setExpendituresFueData([]));

      return;
    }

    dispatch(getExpendituresFue(searchBarValue));
  }, [debouncedSearchBarValue]);

  const onSubmit = useCallback(
    ({ type, ...values }) => {
      submit(values.created_from_estimate ? { ...values } : { ...values, type });
      if (!formRef.current) return;
      formRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
      setSearchBarValue("");
    },
    [submit, formRef.current]
  );

  const handleSearchBarChange = useCallback((value) => {
    setSearchBarValue(value);
  }, []);

  const handleSelectedItemChange = useCallback((selectedItem) => {
    setActiveSelectedItem(selectedItem);
    setSearchBarValue(selectedItem?.name);
  }, []);

  return (
    <div className={cn(styles.container, isInRow && styles.containerInRow)}>
      <Form
        onSubmit={onSubmit}
        initialValues={presetValues}
        render={({ values, handleSubmit, form }) => {
          return (
            <form ref={formRef} onSubmit={handleSubmit} onReset={() => form.restart()}>
              <div className={styles.topSide}>
                <div className={styles.headerContent}>
                  <div className={styles.rowFirst}>
                    {isWithNumberField && (
                      <div className={styles.numberField}>
                        <Field
                          name="number"
                          label={"Номер *"}
                          component={InputBase}
                          validate={composeFieldValidators(required(), mustBeEstimateNumber(), maxLength(155))}
                          disabled={!isDefault}
                        />
                      </div>
                    )}
                    <div className={styles.field1}>
                      <Field
                        name="name"
                        label={`Наименование ${requiredFields.includes("name") ? "*" : ""}`}
                        isSearchBar
                        searchBarValue={searchBarValue}
                        isSearchBarLoading={expendituresFueIsLoading}
                        onSearchBarChange={handleSearchBarChange}
                        onSearchBarSelectedItemChange={handleSelectedItemChange}
                        value={searchBarValue}
                        component={Select}
                        options={expendituresFueData}
                        validate={composeFieldValidators(
                          requiredFields.includes("name") ? required() : undefined,
                          maxLength(765)
                        )}
                        isDefaultSearchBarOpen={!presetValues?.name}
                      />
                    </div>
                    <div className={styles.field2}>
                      <Field
                        name="measure"
                        validate={composeFieldValidators(
                          requiredFields.includes("measure") ? required() : undefined,
                          maxLength(255)
                        )}
                        render={({ input }) => (
                          <MeasureSelect
                            input={input}
                            label={`Ед.изм. ${requiredFields.includes("measure") ? "*" : ""}`}
                          />
                        )}
                        isServices={isServices}
                        defaultValue={values?.measure}
                      />
                    </div>
                    <div className={styles.field3}>
                      <Field
                        name="count"
                        label={`Кол-во ${requiredFields.includes("count") ? "*" : ""}`}
                        type="number"
                        render={(props) => <InputBase {...props} valueType={VALUE_TYPES.NUMBER} />}
                        validate={composeFieldValidators(
                          mustBeNumber,
                          requiredFields.includes("count") ? required() : undefined,
                          maxLength(15)
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    {isInRow && <div className={styles.numberField} />}
                    {isEstimate ? (
                      <>
                        <div className={styles.field3}>
                          <Field
                            name="price"
                            label={`Цена ${requiredFields.includes("price") ? "*" : ""}`}
                            component={InputBase}
                            type="number"
                            validate={requiredFields.includes("price") ? required() : undefined}
                          />
                        </div>
                        <div className={styles.estimateField}>
                          <Field
                            name="justification"
                            label={`Обоснование ${requiredFields.includes("justification") ? "*" : ""}`}
                            component={InputBase}
                            validate={composeFieldValidators(
                              requiredFields.includes("justification") ? required() : undefined,
                              maxLength(155)
                            )}
                          />
                        </div>
                        <div className={styles.fieldRow5}>
                          <Field
                            name="expenditure_type"
                            validate={requiredFields.includes("expenditure_type") ? required() : undefined}
                            render={({ input }) => (
                              <Select
                                input={input}
                                options={EXPENDITURE_TYPES}
                                label={`Тип ${requiredFields.includes("expenditure_type") ? "*" : ""}`}
                                disabled={!isDefault}
                              />
                            )}
                          />
                        </div>
                        <div className={styles.fieldRow6}>
                          {values.expenditure_type && !values.expenditure_type?.includes(ExpenditureTypeEnum.work) && (
                            <Field
                              name="related_work_id"
                              validate={filteredExpendituresByType?.length > 0 ? required() : undefined}
                              render={({ input }) => (
                                <Select
                                  input={input}
                                  options={filteredExpendituresByType}
                                  label={`Связанная работа ${filteredExpendituresByType?.length > 0 ? "*" : ""}`}
                                />
                              )}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.fieldRow1}>
                          <Field
                            name="address"
                            label={`Производитель ${requiredFields.includes("address") ? "*" : ""}`}
                            component={InputBase}
                            validate={requiredFields.includes("address") ? required() : undefined}
                          />
                        </div>
                        <div className={styles.fieldRow2}>
                          <Field
                            name="number"
                            label={`Код ${requiredFields.includes("number") ? "*" : ""}`}
                            component={InputBase}
                            validate={requiredFields.includes("number") ? required() : undefined}
                          />
                        </div>
                        <div className={styles.fieldRow3}>
                          <Field
                            name="brand"
                            label={`Марка ${requiredFields.includes("brand") ? "*" : ""}`}
                            component={InputBase}
                            validate={requiredFields.includes("brand") ? required() : undefined}
                          />
                        </div>
                        <div className={styles.fieldRow5}>
                          <Field
                            name="type"
                            label={`Тип ${requiredFields.includes("type") ? "*" : ""}`}
                            component={Select}
                            options={materialsType}
                            disabled={values.created_from_estimate}
                            validate={requiredFields.includes("type") ? required() : undefined}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {!isEstimate && (
                    <div className={styles.fieldRow4}>
                      <Field
                        className={styles.checkbox}
                        name="created_from_estimate"
                        label={`Доп ${requiredFields.includes("created_from_estimate") ? "*" : ""}`}
                        type="checkbox"
                        component={CheckboxFieldComponent}
                        validate={requiredFields.includes("created_from_estimate") ? required() : undefined}
                      />
                    </div>
                  )}
                </div>
                {isInRow && (
                  <div className={styles.act2ions}>
                    <Actions
                      canSave
                      canRemove
                      onSave={() => handleSubmit()}
                      onRemove={onCancel}
                      className={styles.actions}
                      isThinCross
                      isThinCheck
                    />
                  </div>
                )}
              </div>
              {!isInRow && (
                <div className={styles.actionBlock}>
                  <ButtonBase type="submit" primary medium>
                    Сохранить
                  </ButtonBase>
                </div>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

export default React.memo(ExpenditureForm);
