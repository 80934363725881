import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { addSection, addSubSection } from "redux/modules/common/building/materials/specification";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { useMaterialModalsContext } from "../../common/MaterialsModalsContext/MaterialsModalsContext";
import Actions from "shared/ui/controls/Actions/Actions";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";
import InputBase from "shared/ui/inputs/InputBase";

import { useTypedParams } from "utils/hooks/useTypedParams";

import styles from "./styles.module.scss";

interface IProps {
  title?: string;
  activeTabId?: string;
  type: "section" | "subSection";
}

const MaterialsCreateSection = ({ title, type }: IProps) => {
  const { setIsOpenModalAddSection } = useMaterialModalsContext();

  return <AddButton className={styles.add} onClick={() => setIsOpenModalAddSection(true)} text={title} />;
};

export default MaterialsCreateSection;

export const MaterialsCreateSectionRow = ({ type }: IProps) => {
  const dispatch = useDispatch();
  const { isOpenModalAddSection, setIsOpenModalAddSection } = useMaterialModalsContext();
  const objectId = useObjectId();
  const { sectionId } = useTypedParams<{ sectionId: string }>();

  const [value, setValue] = useState("");

  const isSection = type === "section";

  const [isPending, setIsPending] = useState(false);

  const onSuccess = () => {
    setIsPending(false);
    setIsOpenModalAddSection(false);
  };

  const onSave = () => {
    if (!value) return;
    setIsPending(true);
    if (isSection) {
      dispatch(addSection(objectId, { name: value }, onSuccess));
    } else {
      dispatch(addSubSection(objectId, { name: value, parent_id: sectionId }, onSuccess));
    }
  };

  if (isOpenModalAddSection) {
    return (
      <TableReusableRow className={styles.row}>
        <TableReusableCell>
          <InputBase
            value={value}
            onChange={({ target }) => setValue(target.value)}
            className={styles.input}
            placeholder={isSection ? "Наименование раздела" : "Наименование подраздела"}
            onEnter={onSave}
          />
        </TableReusableCell>
        <TableReusableCell>
          <Actions
            //TODO сделать пендинг
            //isPending={isPending}
            disabled={isPending}
            canSave
            canRemove
            onRemoveDirectly={() => {
              setValue("");
              setIsOpenModalAddSection(false);
            }}
            onSave={onSave}
          />
        </TableReusableCell>
      </TableReusableRow>
    );
  }

  return null;
};
