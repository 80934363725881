import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  MATERIALS_TYPES,
  getSpecificationsExpenditures,
  isLoadingSpecificationSelector,
  setSpecificationExpenditures,
  specificationExpendituresSelector,
} from "redux/modules/common/building/materials/specification";

import Expenditure from "../../components/Expenditure/Expenditure";
import { useMaterialsInvalidationKeyContext } from "components/pages/Materials/common/_MaterialsInvalidationKeyContext/_MaterialsInvalidationKeyContext";
import { ADDITIONAL, SPECIFICATION } from "components/pages/Materials/constants";

import { RootState } from "app/store/rootReducer";
import { useTypedSelector } from "app/store/typedUseSelector";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import BackNavigationBar from "shared/ui/layout/BackNavigationBar/BackNavigationBar";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useTypedParams } from "utils/hooks/useTypedParams";

import materialsIcon from "images/icons/navigation/materials.svg";

interface IProps {
  canEdit: boolean;
}

const SpecificationExpenditures = ({ canEdit }: IProps) => {
  const invalidationKey = useTypedSelector((state: RootState) => state.specification.invalidationKey);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sectionId, objectId, subSectionId } = useTypedParams<{ subSectionId: string }>();
  const specificationSubExpenditures = useSelector(specificationExpendituresSelector);
  const isLoading = useSelector(isLoadingSpecificationSelector);
  const additionalProducts = useMemo(() => {
    return specificationSubExpenditures?.products?.filter((item: any) => item.type === ADDITIONAL) ?? [];
  }, [specificationSubExpenditures]);

  const outOfEstimateProducts = useMemo(() => {
    return (
      specificationSubExpenditures?.products?.filter((item: any) => item.type === MATERIALS_TYPES.OUT_OF_ESTIMATE) ?? []
    );
  }, [specificationSubExpenditures]);

  const specificationProducts = useMemo(() => {
    return specificationSubExpenditures?.products?.filter((item: any) => item.type === SPECIFICATION) ?? [];
  }, [specificationSubExpenditures]);

  const { invalidationKey: invalidationKey1 } = useMaterialsInvalidationKeyContext();

  useEffect(() => {
    dispatch(getSpecificationsExpenditures(objectId, SPECIFICATION, subSectionId, undefined));
    return () => {
      dispatch(setSpecificationExpenditures({}));
    };
  }, [sectionId, objectId, subSectionId, invalidationKey, invalidationKey1]);

  return (
    <div>
      <BackNavigationBar
        title={specificationSubExpenditures?.name}
        onBack={() => history.push(`/objects/${objectId}/materials/specification/${sectionId}`)}
      />
      {!specificationSubExpenditures?.products?.length && !isLoading && <EmptyPlaceholder img={materialsIcon} />}
      {/* spinner */}
      {isLoading ? (
        <Spinner isFixed />
      ) : (
        <div className={`styles.content`}>
          {specificationProducts?.length > 0 && (
            <div key="specificationProducts">
              <Expenditure
                objectId={objectId}
                data={specificationProducts}
                typeUrl={SPECIFICATION}
                canEdit={canEdit}
                isRetOwnMatCheckboxVisible
                subSectionId={subSectionId}
              />
            </div>
          )}
          {additionalProducts?.length > 0 && (
            <div key="additionalProducts" className={`styles.additional`}>
              <p>Допы:</p>
              <Expenditure
                objectId={objectId}
                data={additionalProducts}
                typeUrl={ADDITIONAL}
                canEdit={canEdit}
                isRetOwnMatCheckboxVisible={false}
                subSectionId={subSectionId}
              />
            </div>
          )}
          {outOfEstimateProducts?.length > 0 && (
            <div key="outOfEstimateProducts" className={`styles.additional`}>
              <p>Вне сметы:</p>
              <Expenditure
                objectId={objectId}
                data={outOfEstimateProducts}
                typeUrl={SPECIFICATION}
                canEdit={canEdit}
                isRetOwnMatCheckboxVisible={false}
                subSectionId={subSectionId}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SpecificationExpenditures;
