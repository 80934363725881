import { RefObject, useCallback, useEffect } from "react";

type Config = {
  excludeRefs?: React.RefObject<HTMLDivElement>[];
};

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: Function, config?: Config) => {
  const callHandlerIfEventTargetOutside = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (ref.current && e.target instanceof Node && ref.current.contains(e.target)) return;
      if (
        config?.excludeRefs?.some(
          (excludeRef) => excludeRef.current && e.target instanceof Node && excludeRef.current.contains(e.target)
        )
      ) {
        return;
      }

      handler();
    },
    [handler, ref.current, config?.excludeRefs]
  );

  useEffect(() => {
    document.addEventListener("mousedown", callHandlerIfEventTargetOutside);
    document.addEventListener("touchstart", callHandlerIfEventTargetOutside);

    return () => {
      document.removeEventListener("mousedown", callHandlerIfEventTargetOutside);
      document.removeEventListener("touchstart", callHandlerIfEventTargetOutside);
    };
  }, [ref, callHandlerIfEventTargetOutside, config?.excludeRefs]);
};

export default useOnClickOutside;
