import { IActions } from "app/store/store";

import { ProjectFilterType } from "./types";

export const projectActions = {
  invalidateKey: () =>
    ({
      type: "project/INVALIDATE_KEY",
      payload: true,
    } as const),
  setFilter: (payload: { filter: ProjectFilterType; value: boolean }) =>
    ({
      type: "project/SET_FILTER",
      payload,
    } as const),
};

export type IProjectActions = IActions<typeof projectActions>;
