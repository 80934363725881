import cn from "classnames";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { chartTreeSelector } from "redux/modules/common/chart/selectors";
import { IChartFactWork, IChartTree } from "redux/modules/common/chart/types";
import { findChartTreeNode } from "redux/modules/common/chart/utils";

import { CHART_TREE_LVL } from "components/pages/Chart/constants";
import { filterIntervalsInRange } from "components/pages/Chart/utils/filterIntervalsInRange";
import ManufacturingModal from "components/pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModal";
import { SharedBraceStatusType } from "components/pages/Manufacturing/constants";
import ModalContentSplitter from "components/UI/atoms/ModalContentSplitter/ModalContentSplitter";
import IntervalGroupModal from "components/UI/_TODO/Expenditure/components/IntervalGroupModal/IntervalGroupModal";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import modalStyles from "../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModal.module.scss";

import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import { SegmentWorkList } from "../ManufacturingPlanSegmentModal/SegmentWorkList";

import styles from "../ManufacturingPlanSectionModal/ManufacturingPlanSectionModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  intervaldata?: { id: number };
  objectId: string;
  treeUID: string;
  updateSharedStatus?: (status: SharedBraceStatusType) => void;
  startAt: string;
  endAt: string;
}

type TreeExtendedFactInterval = IChartTree & IChartFactWork;

const ManufacturingFactSegmentModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  objectId,
  treeUID,
  startAt,
  endAt,
  updateSharedStatus,
}) => {
  const chartTree = useSelector(chartTreeSelector);
  const [activeTree, setActiveTree] = useState<IChartTree | null>(findChartTreeNode(treeUID, chartTree));
  const [activeFact, setActiveFact] = useState<TreeExtendedFactInterval | null>(null);

  const innerChindren = useMemo(() => {
    if (!activeTree || !activeTree.children || activeTree.lvl > CHART_TREE_LVL.LSR) return [];
    const rangeFilterFn = filterIntervalsInRange({ start: startAt, end: endAt });
    return activeTree.children.filter((child) => child.bubbledIntervals?.fact_works?.filter(rangeFilterFn)?.length > 0);
  }, [activeTree?.children, startAt, endAt, activeTree?.lvl]);

  const expenditureLvlFacts: TreeExtendedFactInterval[] = useMemo(() => {
    if (!activeTree || !activeTree.bubbledIntervals?.fact_works?.length || activeTree.lvl <= CHART_TREE_LVL.LSR)
      return [];
    const rangeFilterFn = filterIntervalsInRange({ start: startAt, end: endAt });
    return activeTree.children.reduce((facts, child) => {
      const newIntervals: TreeExtendedFactInterval[] | undefined = child?.processedIntervals?.fact_works.filter(rangeFilterFn).map(
        (fact) =>
          Object.assign(
            {},
            fact,
            child
          ) as TreeExtendedFactInterval);
      if (!newIntervals) return facts;
      return facts.concat(newIntervals);
    }, [] as TreeExtendedFactInterval[]).sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  }, [activeTree?.children, startAt, endAt, activeTree?.lvl]);

  if (activeFact) {
    if (activeFact.isGroup) {
      return (
        <IntervalGroupModal
          isOpen
          onClose={() => setActiveFact(null)}
          activeModule="facts"
          date_start={activeFact.start}
          date_end={activeFact.end}
          expenditureId={activeFact.group?.group_id as number}
          expenditure={{}}
          sectionName={activeTree?.name || ""}
          objectId={objectId}
          sectionId={activeFact.group?.cs_id as number}
        />
      );
    }
    return (
      <ManufacturingModal
        isOpen
        onClose={() => setActiveFact(null)}
        type="work"
        date_start={activeFact.start}
        date_end={activeFact.end}
        objectId={objectId}
        // @ts-ignore
        intervaldata={activeFact.expenditure}
        expenditureId={activeFact.expenditure?.exp_id!}
        sectionId={activeFact.expenditure?.cs_id || (activeFact.expenditure?.ps_id as number)}
        modalType="expenditure"
        updateWorkStatus={updateSharedStatus}
      />
    );
  }

  if (!activeTree) return null;

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} name={activeTree.name} isFixedHeadline>
      {activeTree.lvl <= CHART_TREE_LVL.SECTION && activeTree.lvl > CHART_TREE_LVL.PROJECT && (
        <ButtonBack onClick={() => setActiveTree(activeTree.parent)} className={modalStyles.back} />
      )}
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={activeTree.plan_start}
          defaultDateEnd={activeTree?.plan_end}
          disabled
          classNameSelect={styles.calendar}
        />
      </div>
      {activeTree.estimate_amount !== undefined && (
        <div className={cn(styles.pair, styles.centered)}>
          <b>Бюджет (по проекту):</b>
          <InputBase
            isButtonActive
            value={activeTree.estimate_amount}
            valueType={VALUE_TYPES.PRICE} /* @ts-ignore */
            numberTransformOptions={{ withCurrencySign: true }}
            disabled
          />
        </div>
      )}
      {innerChindren.length > 0 && (
        <div>
          {innerChindren.map(
            (
              child: IChartTree,
              index: number //@ts-ignore
            ) => {
              return (
                <React.Fragment key={child._id}>
                  {(index === 0 || child.bubbledIntervals.fact_works[0].start !== innerChindren[index - 1]?.bubbledIntervals.fact_works[0].start) && (
                    <ModalContentSplitter label={moment(child.bubbledIntervals.fact_works[0].start).format("DD.MM.YY")} />
                  )}
                  <SegmentWorkList
                    onClick={() => setActiveTree(child)}
                    name={child.name || ""}
                    start={child.bubbledIntervals.fact_works[0].start || ""}
                    end={child.bubbledIntervals.fact_works[child.bubbledIntervals.fact_works.length - 1].end || ""}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
      {expenditureLvlFacts.length > 0 && (
        <div>
          {expenditureLvlFacts.map(
            (
              fact: TreeExtendedFactInterval,
              index: number //@ts-ignore
            ) => {
              return (
                <React.Fragment key={`${fact.type}_${fact[fact.type]?.id}`}>
                  {(index === 0 || fact.start !== expenditureLvlFacts[index - 1]?.start) && (
                    <ModalContentSplitter label={moment(fact.start).format("DD.MM.YY")} />
                  )}
                  <SegmentWorkList
                    onClick={() => setActiveFact(fact)}
                    name={fact.name || ""}
                    start={fact.start}
                    end={fact.end}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
    </ModalContainer>
  );
};
export default ManufacturingFactSegmentModal;
