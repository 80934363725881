import { IIdAndName } from "types/idAndName";

export const multiplePriceUtils = {
  getAvailableCopyName: (
    options: {
      name: string;
    }[],
    candidate: string
  ): string => {
    if (options.every((el) => el.name !== candidate)) {
      return candidate;
    }
    return multiplePriceUtils.getAvailableCopyName(options, candidate + "(1)");
  },
};
