import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";

import { deleteAttribute, editAttribute } from "../../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";
import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import Actions from "shared/ui/controls/Actions/Actions";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputBase from "shared/ui/inputs/InputBase";

import { IAttribute } from "../../model/types";

import styles from "./AttributeDetailModal.module.scss";

interface IProps {
  onClose: () => void;
  attr: IAttribute | null;
  objectId: string;
}

const AttributeDetailModal = ({ onClose, attr, objectId }: IProps) => {
  const dispatch = useDispatch();
  const loadings = useTypedSelector((state) => state.settingsReducer.attributesReducer.loadings);
  const invalidationKey = useTypedSelector((state) => state.settingsReducer.attributesReducer.invalidationKey);
  const isDeleting = loadings["delete"];
  const isEditing = loadings["edit"];

  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const [isEdit, setIsEdit] = useState(false);

  const [attrValue, setAttrValue] = useState(attr);
  const [shouldDelete, setShouldDelete] = useState<Record<string, boolean>>({});
  const [newAttrs, setNewAttrs] = useState<IAttribute["subsegments"]>([]);

  useEffect(() => {
    setAttrValue(attr);
  }, [attr]);

  return (
    <ModalContainer
      isOpen={!!attr}
      onClose={onClose}
      name={isEdit ? "" : attr?.name}
      contentClassName={styles.content}
      key={invalidationKey}
      customHeadline={
        isEdit ? (
          <InputBase
            value={attrValue?.name}
            onChange={(e) => setAttrValue((p) => ({ ...p, name: e.target.value } as IAttribute))}
          />
        ) : null
      }
    >
      <div className={styles.list}>
        <div className={styles.header}>
          Атрибут 2 {isEdit && <AddButton onClick={() => setNewAttrs((p) => [...p, { id: Date.now(), name: "" }])} />}
        </div>
        {attrValue?.subsegments.map((el, i) => {
          if (shouldDelete[el.id]) {
            return null;
          }
          return (
            <div key={el.id} className={styles.row}>
              {!isEdit && el.name}
              {isEdit && (
                <InputBase
                  value={el?.name}
                  onChange={(e) => {
                    if (!attrValue) return;
                    setAttrValue((p) => ({
                      ...(p as IAttribute),
                      subsegments: p!.subsegments.map((attr) => {
                        if (attr.id === el.id) {
                          return { ...attr, name: e.target.value };
                        } else {
                          return attr;
                        }
                      }),
                    }));
                  }}
                />
              )}
              {isEdit && (
                <Actions
                  canRemove
                  key={el.id}
                  onRemoveDirectly={() => setShouldDelete((p) => ({ ...p, [el.id]: true }))}
                />
              )}
            </div>
          );
        })}
        {newAttrs.map((el) => (
          <div key={el.id} className={styles.row}>
            <InputBase
              value={el?.name}
              onChange={(e) => {
                setNewAttrs((p) =>
                  p.map((attr) => {
                    if (attr.id === el.id) {
                      return { ...attr, name: e.target.value };
                    } else {
                      return attr;
                    }
                  })
                );
              }}
            />
            <Actions
              canRemove
              key={el.id}
              onRemoveDirectly={() => setNewAttrs((p) => p.filter((attr) => attr.id !== el.id))}
            />
          </div>
        ))}
      </div>
      <BottomControls isExists={isEdit} isDoubleBtns>
        <ButtonBase
          secondary
          onClick={() => {
            setShouldDelete({});
            setAttrValue(attr);
            setIsEdit(false);
            setNewAttrs([]);
          }}
        >
          Отменить
        </ButtonBase>
        <ButtonBase
          primary
          isLoading={isEditing}
          onClick={() => {
            dispatch(editAttribute(objectId, attr?.id!, attrValue!, shouldDelete, newAttrs, onClose));
          }}
        >
          Сохранить
        </ButtonBase>
      </BottomControls>
      <BottomControls isExists={!isEdit} isDoubleBtns>
        <ButtonBase
          danger
          isLoading={isDeleting}
          onClick={() => {
            openConfirmModal({
              isOpen: true,
              onClose: closeConfirmModal,
              action: () => {
                dispatch(deleteAttribute(objectId, attr?.id!, onClose));
                closeConfirmModal();
              },
              title: "Удалить аттрибут?",
              acceptButtonText: "Удалить",
              className: styles.confirmModal,
              variant: "secondary",
            });
          }}
        >
          Удалить
        </ButtonBase>
        <ButtonBase
          primary
          onClick={() => {
            setIsEdit(true);
          }}
        >
          Редактировать
        </ButtonBase>
      </BottomControls>
    </ModalContainer>
  );
};

export default AttributeDetailModal;
