import cn from "classnames";
import React from "react";

import styles from "./Spinner.module.scss";

export interface ISpinnerProps {
  isFixed?: boolean;
  isStatic?: boolean;
  isSmall?: boolean;
  isSmallGray?: boolean;
  className?: string;
  spinnerClassName?: string;
  isFullHeight?: boolean;
  isWhite?: boolean;
  isBlurred?: boolean;
  text?: string;
}

export const Spinner = React.memo(
  ({
    isFixed,
    className,
    isSmall,
    isSmallGray,
    isStatic,
    isFullHeight,
    isWhite,
    spinnerClassName,
    isBlurred,
    text,
  }: ISpinnerProps) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [offset, setOffset] = React.useState<number | null>(null);

    React.useEffect(() => {
      if (!containerRef.current || !isFullHeight) return;
      setOffset(containerRef.current.getBoundingClientRect().top);
    }, [isFullHeight]);

    return (
      <div
        className={cn(styles.container, "spinnerGlobal", className, {
          [styles.fixed]: isFixed,
          [styles.static]: isStatic,
          [styles.blurred]: isBlurred,
        })}
        style={isFullHeight ? { height: `calc(100vh - ${offset}px - 2.2rem)` } : {}}
      >
        {isSmallGray ? (
          <div className={styles.smallGray} />
        ) : (
          <div className={cn(styles.spinner, { [styles.small]: isSmall, [styles.white]: isWhite }, spinnerClassName)} />
        )}
        {text ? <span className={styles.text}>{text}</span> : null}
      </div>
    );
  }
);

export default Spinner;
