import axios from "axios";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { getImportList } from "redux/modules/common/building/materials/importMaterials";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

type Response = {
  status: "processing" | "need_processing" | "complete";
};

//TODO временный файл
export const useImportStatus = (successCallback?: () => void) => {
  const objectId = useObjectId();
  const dispatch = useDispatch();

  const [loadings, setLoadings] = useState<Record<string, any>>({});
  const timers = useRef<Record<string, NodeJS.Timer>>({});

  const addToQueue = (id: number) => {
    setLoadings((prev) => ({ [id]: true }));
    timers.current[id] = setInterval(() => {
      axios.get<Response>(`/building/${objectId}/products/import/${id}/`).then(({ data }) => {
        if (data.status === "complete") {
          clearInterval(timers.current[id]);
          delete timers.current[id];
          dispatch(
            getImportList(objectId, () => {
              setLoadings((prev) => ({ [id]: false }));
              successCallback?.();
            })
          );
        }
      });
    }, 2000);
  };

  return { addToQueue, loadings };
};
