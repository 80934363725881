import { Dispatch } from "redux";

import { multiplePricesApi } from "./api";
import { multiplePricesActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getPrices = (objectId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().multiplePricesReducer.prices[objectId]?.length) {
    dispatch(multiplePricesActions.setIsLoading({ key: objectId, status: true }));
  }
  multiplePricesApi
    .getList(objectId)
    .then(({ data }) => {
      dispatch(multiplePricesActions.setPrices({ key: objectId, data: data.results }));
    })
    .finally(() => {
      dispatch(multiplePricesActions.setIsLoading({ key: objectId, status: false }));
    });
};

export const addPrice =
  (objectId: string, name: string, successCb?: () => void) => (dispatch: Dispatch, getState: () => RootState) => {
    const id = String(Date.now());
    dispatch(multiplePricesActions.setIsLoading({ key: id, status: true }));
    dispatch(multiplePricesActions.addPriceOptimistic({ key: objectId, option: { name, id: +id } }));
    multiplePricesApi
      .createName(objectId, name)
      .then(({ data }) => {
        dispatch(multiplePricesActions.invalidateKey());
        successCb?.();
      })
      .catch((e: any) => {
        errorCatcher(e);
      })
      .finally(() => {
        dispatch(multiplePricesActions.setIsLoading({ key: id, status: false }));
      });
  };

export const editPrice =
  (objectId: string, name: string, id: number) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(multiplePricesActions.setIsLoading({ key: String(id), status: true }));
    multiplePricesApi
      .patchName(objectId, name, id)
      .then(() => {
        dispatch(multiplePricesActions.invalidateKey());
      })
      .catch((e: any) => {
        errorCatcher(e);
      })
      .finally(() => {
        dispatch(multiplePricesActions.setIsLoading({ key: String(id), status: false }));
      });
  };

export const deletePrice = (objectId: string, id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  dispatch(multiplePricesActions.setIsLoading({ key: String(id), status: true }));
  multiplePricesApi
    .deleteName(objectId, id)
    .then(() => {
      //replace
      dispatch(multiplePricesActions.invalidateKey());
    })
    .catch((e: any) => {
      dispatch(multiplePricesActions.setIsLoading({ key: String(id), status: false }));
      errorCatcher(e);
    });
};
