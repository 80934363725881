import { IProjectFilters } from "redux/modules/common/building/project/types";

import { ICreateGroupValues } from "./types";

export const DEFAULT_BUILDING_FILTERS: IProjectFilters = {
  material: false,
  machine: false,
  transport: false,
  equipment: false,
  attribute: false,
};

export const PROGRESS = "progress";
export const PLAN = "plan";
export const DOCUMENTATION = "documentation";

export const TABS_IDS = [PROGRESS, PLAN, DOCUMENTATION];

export const OUT_OF_ESTIMATE_REQUIRED_FIELDS = ["name", "measure", "count", "expenditure_type"];

export const CREATE_GROUP_INITIALS: ICreateGroupValues = {
  name: "",
  measure: "",
  count: "",
  price: "",
  amount: "",
  for_contractor: false,
};

export const EMPTY_BUILDING_INTERVALS = { plans: [], facts: [], donned: [], received: [], approveds: [] };
