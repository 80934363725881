import axios from "axios";

import { IListResponseData } from "types/ListResponse";

export const multiplePricesApi = {
  createName: (objectId: string, name: string) => {
    return axios.post(`/building/${objectId}/estimate/markup/`, { name });
  },
  getList: (objectId: string) => {
    return axios.get<IListResponseData<any>>(`/building/${objectId}/estimate/markup/`);
  },
  patchName: (objectId: string, name: string, priceId: number) => {
    return axios.patch(`/building/${objectId}/estimate/markup/${priceId}/`, { name });
  },
  deleteName: (objectId: string, priceId: number) => {
    return axios.delete(`/building/${objectId}/estimate/markup/${priceId}/`);
  },
};
