import cn from "classnames";
import React from "react";
import { useParams } from "react-router-dom";

import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock";
import MoreButton from "./components/MoreButton/MoreButton";
import PlanMaterialsTableHeader from "./components/TableHeader/PlanMaterialsTableHeader";
import PlanMaterialsTableRow from "./components/TableRow/PlanMaterialsTableRow";
import { filterRestPlannedMaterials } from "components/pages/Manufacturing/components/modals/ManufacturingModal/PlanEditing/utils";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import AddButton from "../../../../shared/ui/controls/AddButton/AddButton";
import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Expandable from "../../atoms/_TODO/Expandable/Expandable";

import { IMaterialInAddingInFact, ISerializedMaterialInPlan } from "types/interfaces/Materials";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useMaterialsPlanList } from "../../../../hooks/useMaterialsPlanList";

import materials from "images/icons/navigation/materials.svg";

import styles from "./AddingListInModal.module.scss";

interface IProps {
  addedMaterials: ISerializedMaterialInPlan[];
  selectedMaterials: ISerializedMaterialInPlan[];
  onAccept: () => boolean;
  onDecline: () => boolean;
  onChangeCount: (id: number, value: string, type: "added" | "selected") => void;
  onDelete: (id: number) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: ISerializedMaterialInPlan) => void;
  selectedIds: number[];
  addedIds: number[];
  expenditureId: number;
  presettedPercentage?: number;
  onAddPresettedMaterials?: (materials: ISerializedMaterialInPlan[]) => void;
  wholeWorkCount?: number;
  defaultMaterialsList?: IMaterialInAddingInFact[];
  isWorkGroup?: boolean;
}

const AddPlanMaterialsInModal: React.FC<IProps> = ({
  addedMaterials,
  selectedMaterials,
  onAccept,
  onDecline,
  onChangeCount,
  onDelete,
  onSelect,
  selectedIds,
  addedIds,
  expenditureId,
  presettedPercentage,
  onAddPresettedMaterials,
  wholeWorkCount,
  defaultMaterialsList,
  isWorkGroup,
}) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const { allMaterials, isLoading, loadMoreHandler, count, filterHandler, presettedPlanMaterials } =
    useMaterialsPlanList({
      objectId: Number(objectId),
      expId: expenditureId,
      presettedPercentage,
      defaultMaterialsList,
      isWorkGroup,
    });

  const [isAdding, setIsAdding] = React.useState(false);

  React.useEffect(() => {
    if (!presettedPlanMaterials?.length || isWorkGroup) return;
    onAddPresettedMaterials?.(presettedPlanMaterials);
  }, [presettedPlanMaterials, isWorkGroup]);

  const allMaterialsWithoutAdded = React.useMemo(() => {
    return filterRestPlannedMaterials(allMaterials, addedIds);
  }, [allMaterials, addedIds]);

  const canAddMoreMaterials = addedMaterials?.length < count;

  const withStopAdding = React.useCallback((cb: () => boolean) => {
    return () => cb?.() && setIsAdding(false);
  }, []);

  const showAddBtn = canAddMoreMaterials && !isAdding;

  return (
    <div className={styles.wrapper}>
      <div className={styles.expandableHeadline}>
        <span className={styles.title}>
          Материалы:{" "}
          {addedMaterials?.length ? (
            addedMaterials.length
          ) : showAddBtn ? (
            <AddButton onClick={() => setIsAdding(true)} />
          ) : (
            "-"
          )}
        </span>
      </div>
      {!!addedMaterials?.length && (
        <div className={styles.table}>
          <PlanMaterialsTableHeader />
          {addedMaterials?.map((el, i) => (
            <PlanMaterialsTableRow
              key={el.id + el.count}
              material={el}
              canDelete
              onChangeCount={(e) => onChangeCount(el.id, e.target.value, "added")}
              onDelete={() => onDelete(el.id)}
              canBeChecked={false}
              isPrefilled
              wholeWorkCount={wholeWorkCount}
            />
          ))}
        </div>
      )}
      {canAddMoreMaterials && isAdding && (
        <ConfirmBlock
          count={selectedMaterials?.length}
          onAccept={withStopAdding(onAccept)}
          onDecline={withStopAdding(onDecline)}
        />
      )}
      {canAddMoreMaterials && isAdding && (
        <div className={cn(styles.table, styles.bordered)}>
          <PlanMaterialsTableHeader
            onFiltersChange={isWorkGroup ? undefined : filterHandler}
            className={styles.header}
          />
          {isLoading && <Spinner />}
          {!!(!isLoading && allMaterials?.length) &&
            allMaterialsWithoutAdded.map((el) => (
              <PlanMaterialsTableRow
                key={el.id}
                material={el}
                onChangeCount={(e) => onChangeCount(el.id, e.target.value, "selected")}
                onCheck={onSelect}
                isSelected={selectedIds.includes(el.id)}
                wholeWorkCount={wholeWorkCount}
              />
            ))}
          {!isLoading && !allMaterials?.length && <EmptyPlaceholder img={materials} />}
          <MoreButton
            isLoading={isLoading}
            allCount={count}
            existingCount={allMaterials?.length}
            onLoadMore={loadMoreHandler}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(AddPlanMaterialsInModal);
