import { FinanceViews } from "pages/Finance/common/contexts/FinanceFiltersContext/FinanceFiltersContext";

import { LOCALIZATION_CONFIG } from "constants/localization";

export const getTableViewWidthPercent = (tableElement: any) => {
  const tableInnerElement = tableElement.querySelector(".ant-table-tbody");
  const widthPercent = (tableElement.offsetWidth / tableInnerElement.scrollWidth) * 100;
  return widthPercent;
};

export const scrollTable = (tableElement: any, scrollPosition: any, isNotTable?: any) => {
  if (!tableElement) return;
  if (isNotTable) {
    const scrollTo = tableElement.scrollWidth * (scrollPosition / 100);
    tableElement.scroll(scrollTo, 0);
    return;
  }
  const tableInnerElement = tableElement.querySelector(".ant-table-content");
  if (!tableInnerElement) return;
  const scrollTo = tableInnerElement.scrollWidth * (scrollPosition / 100);
  tableInnerElement.scroll(scrollTo, 0);
};

export const getFinanceTableKey = (
  key: string | number,
  type: "plan" | "fact" | "diff" | "diff_perc",
  year?: number
) => {
  let formattedKey = key;
  if (typeof key === "number") {
    formattedKey = String(+key + 1);
  }
  if (!["q1", "q2", "q3", "q4", "year"].includes(key as string)) {
    formattedKey = `m${formattedKey}`;
  }
  if (!["q1", "q2", "q3", "q4", "year"].includes(key as string)) {
    return `${formattedKey}_${type}_${year}`;
  }
  return `${formattedKey}_${type}`;
};

export const getFinanceDiffKey = (financeView: FinanceViews) => {
  if (financeView === FinanceViews.currency) return "diff";
  return "diff_perc";
};

export function getFinanceTableCellISPercents(financeView: FinanceViews) {
  if (financeView === FinanceViews.currency) return false;
  return true;
}

export function getFinanceTableColumnTitle(financeView: FinanceViews, measureInTitle: string) {
  return (title: string, isDiff?: boolean) => {
    if (financeView === FinanceViews.percents && isDiff) {
      return `${title} %`;
    }
    return `${title} ${measureInTitle}${LOCALIZATION_CONFIG.currency}`;
  };
}
