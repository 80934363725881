import React from "react";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import Select from "components/UI/atoms/Select";
import FinancePricesSettings from "pages/Finance/common/widgets/FinancePricesSettings/FinancePricesSettings";

import { Switch } from "shared/ui/inputs/Switch/Switch";
import Modal from "shared/ui/modal/Modal";
import { IBaseModalProps } from "shared/ui/modal/SliderModal/SliderModal";

import { useTypedParams } from "utils/hooks/useTypedParams";

import styles from "./SummarySettingsModal.module.scss";

interface IProps extends IBaseModalProps {}

const SummarySettingsModal = ({ isOpen, onClose }: IProps) => {
  const { objectId } = useTypedParams();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Настройки финансов"
      className={styles.modal}
      headerClassName={styles.title}
    >
      <FinancePricesSettings objectId={objectId} />
    </Modal>
  );
};

export default SummarySettingsModal;
