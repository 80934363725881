import React, { useMemo } from "react";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import ISection from "types/interfaces/Section";

import CheckDouble from "images/icons/CheckDouble";

import styles from "./BuildingSectionProvidersPopup.module.scss";

interface IProps {
  providers?: ISection["providers"];
}

const BuildingSectionProvidersPopup = ({ providers }: IProps) => {
  const iconsVariant = useMemo(() => {
    if (!!providers?.length && providers.every((p) => p.accepted)) {
      return "primary";
    }
    if (!!providers?.length && providers.some((p) => p.accepted)) {
      return "semi";
    }
    return "default";
  }, [providers]);

  return (
    <PopoverOverlay
      openType="hover"
      placement="top"
      popoverBorderColor="primary"
      content={
        <div className={styles.providersList}>
          {providers?.map((p) => (
            <div className={styles.providerItem} key={p?.entity_id}>
              <span className={styles.status}>{p?.accepted ? "Передано" : "Ожидает подтверждения"}</span>
              <span className={styles.providerName}>{p?.entity_name}</span>
            </div>
          ))}
        </div>
      }
    >
      <CheckDouble className={styles.checkDouble} variant={iconsVariant} />
    </PopoverOverlay>
  );
};

export default BuildingSectionProvidersPopup;
