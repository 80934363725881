import axios, { AxiosResponse } from "axios";

import { errorCatcher } from "utils/helpers/errorCatcher";

const MAX_RETRY_COUNT = 1;

/**
Для GET-запросов длинных списков (больше разумного параметра "limit"),
использует поле "next" из ответа бэкенда для повторных GET-запросов, пока в ответе есть очередной "next".
*/
const getLongList = async (getPromise: Promise<AxiosResponse<any, any>>): Promise<AxiosResponse<any, any>> => {
  const promise = await getPromise;
  let retries = 0;
  while (promise.data?.results && promise.data?.next && retries < MAX_RETRY_COUNT) {
    await axios
      .get(promise.data.next.replaceAll("http:", "https:"))
      .then(({ data }) => {
        promise.data.results = promise.data.results.concat(data.results);
        promise.data.next = data.next;
      })
      .catch((e) => {
        errorCatcher(e);
        retries++;
      });
  }
  return promise;
};

export default getLongList;
