import axios from "axios";

import { IntervalType } from "components/pages/Manufacturing/constants";

import { MATCH_STRATEGY } from "./IntervalMapper";

import { IProjectTreeResponse } from "../building/manufacturing/types";
import {
  ChartStatusRecord,
  IChartFactResource,
  IChartFactWork,
  IChartPlanResource,
  IChartPlanSection,
  IChartPlanWork,
  IPlanIntervalSegment,
  IPlanRelation,
  IPlanRelationCore,
} from "./types";

import { queryParamsFormatter } from "utils/formatters/queryParamsFormatter";

export const chartApi = {
  // tree
  getTree: (
    projectId: number | string,
    treeType: IntervalType | IntervalType[],
    signal: AbortSignal,
    strategy: MATCH_STRATEGY
  ) => {
    const params = Array.isArray(treeType)
      ? queryParamsFormatter({}, treeType, "expenditure_type")
      : { expenditure_type: treeType };
    if (strategy === MATCH_STRATEGY.SECTIONS_ONLY) {
      if (params instanceof URLSearchParams) {
        params.append("only_sections", "1");
      } else {
        params.only_sections = "1";
      }
    }
    return axios.get<IProjectTreeResponse>(`/tree/buildings/${projectId}/`, {
      params,
      signal,
    });
  },
  getWorkersTree: (projectId: number | string, signal: AbortSignal) => {
    return axios.get<IProjectTreeResponse>(`/tree/buildings/${projectId}/worker/`, {
      signal,
    });
  },
  getSegmentsTree: (projectId: number | string, treeType: IntervalType | IntervalType[], signal: AbortSignal) => {
    const params = Array.isArray(treeType)
      ? queryParamsFormatter({}, treeType, "expenditure_type")
      : { expenditure_type: treeType };
    return axios.get<IProjectTreeResponse>(`/tree/buildings/${projectId}/segments/`, {
      signal,
      params,
    });
  },
  // statuses
  getStatuses: () => axios.get<ChartStatusRecord>(`/profile/status_color/`),
  /** Используется только при первом изменении статусов */
  createStatuses: (statuses: ChartStatusRecord) => axios.post<ChartStatusRecord>(`/profile/status_color/`, statuses),
  /** Каждое обновление статусов */
  patchStatuses: (statuses: ChartStatusRecord) => axios.patch<ChartStatusRecord>(`/profile/status_color/`, statuses),
  // intervals
  getFactIntervalsWorks: (projectId: number | string, startAt: string, endAt: string, signal?: AbortSignal) => {
    return axios.get<IChartFactWork[]>(`/chart/fact_intervals/works/`, {
      params: {
        building_ids: JSON.stringify([projectId]),
        start_at: startAt,
        end_at: endAt,
      },
      signal,
    });
  },
  getPlanIntervalsWorks: (projectId: number | string, startAt: string, endAt: string, signal?: AbortSignal) => {
    return axios.get<IChartPlanWork[]>(`/chart/plan_intervals/works/`, {
      params: {
        building_ids: JSON.stringify([projectId]),
        start_at: startAt,
        end_at: endAt,
      },
      signal,
    });
  },
  getPlanIntervalsSections: (projectId: number | string, startAt: string, endAt: string, signal?: AbortSignal) => {
    return axios.get<IChartPlanSection[]>(`/chart/plan_intervals/sections/`, {
      params: {
        building_ids: JSON.stringify([projectId]),
        start_at: startAt,
        end_at: endAt,
      },
      signal,
    });
  },
  getPlannedSegments: (projectId: number | string, signal?: AbortSignal) => {
    return axios.get<IPlanIntervalSegment[]>(`/building/${projectId}/estimate/plan/segments/`, {
      signal,
    });
  },
  getFactIntervalsResources: (
    projectId: number | string,
    types: IntervalType[],
    startAt: string,
    endAt: string,
    signal?: AbortSignal
  ) => {
    return axios.get<IChartFactResource[]>(`/chart/fact_intervals/resources/`, {
      params: {
        building_ids: JSON.stringify([projectId]),
        types: JSON.stringify(types),
        start_at: startAt,
        end_at: endAt,
      },
      signal,
    });
  },
  getPlanIntervalsResources: (
    projectId: number | string,
    types: IntervalType[],
    startAt: string,
    endAt: string,
    signal?: AbortSignal
  ) => {
    return axios.get<IChartPlanResource[]>(`/chart/plan_intervals/resources/`, {
      params: {
        building_ids: JSON.stringify([projectId]),
        types: JSON.stringify(types),
        start_at: startAt,
        end_at: endAt,
      },
      signal,
    });
  },
  shiftInterval: (intervalId: number, days: number, intervalType: "plan_work" | "plan_group") => {
    return axios.post(`/shift/chart/`, { interval_id: intervalId, interval_type: intervalType, days });
  },
  // relations
  getPlanRelations: ({
    from_interval: from_interval_id,
    to_interval: to_interval_id,
    from_group: from_group_id,
    to_group: to_group_id,
    from_section: from_section_id,
    to_section: to_section_id,
    building_id,
    start_date,
    end_date,
    signal,
  }: {
    from_interval?: number | string;
    to_interval?: number | string;
    from_group?: number | string;
    to_group?: number | string;
    from_section?: number | string;
    to_section?: number | string;
    building_id: number | string;
    start_date: string;
    end_date: string;
    signal?: AbortSignal;
  }) => {
    return axios.get<IPlanRelation[]>(`/building/${building_id}/intervals/plan/relations/`, {
      params: {
        building_id,
        from_interval_id,
        from_group_id,
        to_interval_id,
        to_group_id,
        from_section_id,
        to_section_id,
        start_date,
        end_date,
        limit: 300,
      },
      signal,
    });
  },
  createPlanRelation: (projectId: number, relation: IPlanRelationCore) =>
    axios.post<IPlanRelation>(`/building/${projectId}/intervals/plan/relations/`, relation),
  getCheckpoints: ({
    objectId,
    startDate,
    endDate,
    signal,
  }: {
    objectId: number | string;
    startDate: string;
    endDate: string;
    signal?: AbortSignal;
  }) =>
    axios.get(`/check_points/`, {
      params: {
        building: objectId,
        check_point_date_gt: startDate,
        check_point_date_lt: endDate,
        limit: 500,
      },
      signal,
    }),
};
