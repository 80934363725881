import cn from "classnames";
import moment from "moment";
import React, { useState } from "react";

import { IExpenditureInActing } from "redux/modules/common/building/journal/acting/types";
import { IAookPreviewInJournalExpenditure } from "redux/modules/common/building/journal/journalExecution/types";

import AookExistingModal from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aook/AookExistingModal/AookExistingModal";
import AosrExistingModal from "components/pages/Journal/components/JournalDone/components/JournalExecTicket/components/Aosr/AosrExistingModal/AosrExistingModal";

import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";

interface IProps {
  styles: Record<string, string>;
  act: IAookPreviewInJournalExpenditure;
  expenditure: IExpenditureInActing;
  sectionId: number;
  type?: "aosr" | "aook";
}

const JournalActingAosrAook = ({ styles, act, sectionId, expenditure, type = "aosr" }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const isAosr = type === "aosr";
  const isClosed = act.status === "close";

  const datePrefix = isAosr ? `АОСР от ` : `АООК от `;

  return (
    <>
      <BlueLabel
        className={cn(styles.justification, styles.aosr, isClosed && styles.closed)}
        blueFont
        onClick={() => setIsOpen(true)}
      >
        {datePrefix} {moment(act?.act_date).format("DD.MM.YYYY")}
      </BlueLabel>
      {isAosr && (
        <AosrExistingModal
          canEdit={false}
          canImportAosr={false}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          ticket={{ expenditure } as any}
          sectionId={sectionId}
          externalActPreview={act}
        />
      )}
      {!isAosr && (
        <AookExistingModal
          key={act?.id}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          sectionId={sectionId}
          ticket={{ expenditure } as any}
          activeAook={act!}
        />
      )}
    </>
  );
};

export default JournalActingAosrAook;
