import cn from "classnames";
import moment from "moment";
import React, { useMemo, useState } from "react";

import PlanModal from "components/UI/_TODO/Expenditure/components/PlanModal/PlanModal";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";

import { BuildingIntervalsByCategory } from "../../types";
import { IExpenditure } from "types/interfaces/Expenditure";

import { daysPluralSuffix } from "utils/formatters/daysPluralSuffix";

import { ReactComponent as CalendarBlack } from "images/icons/navigation/calendar-black.svg";

import styles from "./ExpenditureAllPlansPopup.module.scss";

export interface ExpenditureAllPlansPopupProps {
  intervals: BuildingIntervalsByCategory["plans"];
  className?: string;
  buildingId: number;
  expenditure: IExpenditure;
  sectionName: string | undefined;
  sectionId: number;
  canDeleteFiles: boolean;
  loadProduct: () => void;
  constructionDateStart: string;
  constructionDateEnd: string;
}

const ExpenditureAllPlansPopup: React.FC<ExpenditureAllPlansPopupProps> = ({
  buildingId,
  intervals,
  className,
  expenditure,
  sectionName,
  sectionId,
  loadProduct,
  canDeleteFiles,
  constructionDateStart,
  constructionDateEnd,
}) => {
  const intervalsList = useMemo(
    () => intervals.sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime()),
    [intervals]
  );

  const [isPlanModalOpen, setIsPlanModalOpened] = useState(false);
  const openPlanModal = () => {
    setIsPlanModalOpened(true);
  };
  const closePlanModal = () => {
    setIsPlanModalOpened(false);
  };
  return (
    <>
      <PopoverOverlay
        openType="hover"
        placement="left"
        popoverBorderColor="primary"
        content={
          <div className={styles.content} onClick={openPlanModal}>
            <div className={styles.title}>План производства</div>
            {intervalsList.map((i) => {
              const diff = moment(i.end_at).diff(moment(i.start_at), "d") + 1;
              return (
                <div className={styles.row} key={i.id}>
                  <span>{`${moment(i.start_at).format("DD/MM/YYYY")} - ${moment(i.end_at).format("DD/MM/YYYY")}`}</span>
                  <span className={styles.duration}>{`Продолжительность – ${diff} ${daysPluralSuffix(diff)}`}</span>
                </div>
              );
            })}
          </div>
        }
      >
        <div className={cn(styles.mark, className)} onClick={openPlanModal}>
          <CalendarBlack />
        </div>
      </PopoverOverlay>
      {isPlanModalOpen && (
        <PlanModal
          objectId={buildingId}
          isOpen
          modalTitle="План производства"
          expidentureId={expenditure.id}
          onClose={closePlanModal}
          expidentureObj={expenditure}
          sectionName={sectionName || ""}
          date_start={constructionDateStart}
          date_end={constructionDateEnd}
          canDeleteFiles={canDeleteFiles}
          loadProduct={loadProduct}
          sectionId={sectionId}
        />
      )}
    </>
  );
};

export default React.memo(ExpenditureAllPlansPopup);
