import React, { useCallback } from "react";

import CheckedExpenditure from "./components/CheckedExpenditure/CheckedExpenditure";

import { Spinner } from "../../../../../../shared/ui/atoms/Spinner/Spinner";
import HideBlock from "../../../../atoms/_TODO/HideBlock/HideBlock";

import styles from "./HiddenSubsection.module.scss";

const HiddenSubsection = ({
  subsection,
  expenditures,
  opened,
  expendituresAreLoading,
  selectedEstimateTarget,
  checkedExpenditures,
  onCheckExpenditure,
  onOpenHiddenSubsection,
  showCount,
  renderExp = undefined,
  expClassName = "",
  renderTitle = undefined,
}) => {
  const handleOnCheckExpenditure = useCallback(
    (expenditureId, isChecked, expenditure) =>
      onCheckExpenditure({ subsectionId: subsection.id, expenditureId, data: expenditure }, isChecked),
    [onCheckExpenditure, subsection.id]
  );

  return (
    <HideBlock
      titleClassName={styles.subSectionTitleClassName}
      title={renderTitle ? renderTitle(subsection.name) : subsection.name}
      isHiddenDefault={!(opened || selectedEstimateTarget?.subsectionId === subsection?.id)}
      onOpenAction={() => onOpenHiddenSubsection?.(subsection.id)}
    >
      {!expendituresAreLoading ? (
        expenditures.map((expenditure) => (
          <CheckedExpenditure
            expenditure={expenditure}
            isChecked={checkedExpenditures[expenditure.id]}
            onCheck={handleOnCheckExpenditure}
            key={expenditure.id}
            showCount={showCount}
            suffix={renderExp?.(expenditure)}
            expClassName={expClassName}
          />
        ))
      ) : (
        <Spinner isSmall isStatic />
      )}
    </HideBlock>
  );
};

export default React.memo(HiddenSubsection);
