import { EXPENDITURE_TYPES } from "constants/constant";

export const applyRelatedResources = (expendituresRes) => {
  expendituresRes?.results?.forEach((exp) => {
    if (exp.expenditure_type === EXPENDITURE_TYPES.WORK) {
      exp.related_resources = expendituresRes.results.filter(
        (resource) =>
          resource.expenditure_type &&
          resource.expenditure_type !== EXPENDITURE_TYPES.WORK &&
          resource.related_work?.id === exp.id
      );
    }
  });
}
