import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IOption } from "components/UI/atoms/Select";

import {
  COEFFICIENT_MTR_CLARIFICATION_OPTIONS,
  COEFFICIENT_SMR_CLARIFICATION_OPTIONS,
  COEFFICIENT_STEP_OPTIONS,
} from "../../HandlerCoefficients";
import { handlerCoefficientsInvalidateKeySelector } from "../../model/selectors";
import { editHandlerCoefficient } from "../../model/thunks";
import HandlerCoefficientsForm from "../HandlerCoefficientsForm/HandlerCoefficientsForm";
import Actions from "shared/ui/controls/Actions/Actions";

import { ICreateCoefficient, IHandlerCoefficient } from "../../model/types";

import { formatCoefficientValue } from "../../utils/formatCoefficientValue";
import { preProcessCoefficientCreation } from "../../utils/preProcessCoefficientCreation";
import { sliceTextByConstraint } from "utils/formatters/sliceTextByConstraint";
import { transformDigitToFinancial } from "utils/formatters/transformDigitToFinancial";

import styles from "./HandlerCoefficientsModal.module.scss";

interface IProps {
  values: IHandlerCoefficient;
  onRemove: (id: number) => void;
  isPending: boolean;
  coefficientsForSteps: IOption[];
  coefficientsById: Record<number, IHandlerCoefficient>;
  objectId: string;
}

const HandlerCoefficientsRow: React.FC<IProps> = ({
  values,
  onRemove,
  isPending,
  coefficientsForSteps,
  coefficientsById,
  objectId,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const invalidateKey = useSelector(handlerCoefficientsInvalidateKeySelector);

  const assigned = useMemo(() => {
    const arr = COEFFICIENT_STEP_OPTIONS.filter((option) => values.coefficient_step === option.id)
      .map((option) => option.name)
      .concat(
        [
          values.is_mtr && "МТР",
          values.is_smr && "СМР",
          values.applying_coefficient && `Коэффициент ${coefficientsById[values.applying_coefficient]?.number || "*"}`,
        ].filter((el) => !!el) as string[]
      );

    return arr.join(", ");
  }, [values]);

  const clarificationSMR = useMemo(
    () =>
      COEFFICIENT_SMR_CLARIFICATION_OPTIONS.filter((option) => values[option.id as keyof typeof values])
        .map((option) => option.name)
        .join(", "),
    [values]
  );

  const clarificationMTR = useMemo(
    () =>
      COEFFICIENT_MTR_CLARIFICATION_OPTIONS.filter((option) => values[option.id as keyof typeof values])
        .map((option) => option.name)
        .join(", "),
    [values]
  );

  useEffect(() => {
    setIsEdit(false);
  }, [invalidateKey]);

  const onEdit = (vals: Partial<ICreateCoefficient>) => {
    const validatedVals = preProcessCoefficientCreation(vals);

    dispatch(editHandlerCoefficient(objectId, values.id, validatedVals));
  };

  if (isEdit) {
    return (
      <HandlerCoefficientsForm
        onSubmit={onEdit}
        values={values}
        onCancel={() => setIsEdit(false)}
        isPending={isPending}
        coefficientsForSteps={coefficientsForSteps}
      />
    );
  }

  return (
    <div className={classNames(styles.cols, styles.row)}>
      <div>{values.number}</div>
      <div className={styles.nameWrapper}>
        <div className={styles.name} title={values.name}>
          {values.name}
        </div>
      </div>
      <div className={styles.index}>{formatCoefficientValue(values)}</div>
      <div className={styles.centered} title={assigned}>
        {assigned}
      </div>
      <div title={`СМР: ${clarificationSMR || ""}. МТР: ${clarificationMTR || ""}.`} className={styles.clarification}>
        {clarificationSMR ? (
          <span>
            <span className={styles.clarificationTitle}>СМР: </span>
            {clarificationSMR}
          </span>
        ) : null}
        {clarificationMTR ? (
          <span>
            <span className={styles.clarificationTitle}>МТР: </span>
            {clarificationMTR}
          </span>
        ) : null}
      </div>
      <div>
        <div className={classNames(styles.createdAt)}>
          <div className={styles.author}>{sliceTextByConstraint(values.author.name, 15)}</div>
          <div className={styles.day}>
            <div className={styles.date}>{moment(values.created_at).format("DD.MM.YY")}</div>
            <div className={styles.time}>в {moment(values.created_at).format("HH:mm")}</div>
          </div>
        </div>
      </div>
      <div>{transformDigitToFinancial(values.coefficient_cost, { withFloat: true })}</div>
      <div className={styles.actions}>
        {
          <Actions
            disabled={isPending}
            canEdit
            onEdit={() => setIsEdit(true)}
            canRemove
            onRemove={() => onRemove(values.id)}
          />
        }
      </div>
    </div>
  );
};

export default HandlerCoefficientsRow;
