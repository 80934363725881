import cn from "classnames";
import React, { Fragment, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock";
import MoreButton from "./components/MoreButton/MoreButton";
import MaterialsTableHeader from "./components/TableHeader/MaterialsTableHeader";
import MaterialsTableRow from "./components/TableRow/MaterialTableRow";

import { SimpleResourceBlock } from "../../../../features/simpleResources/ui/SimpleResourceBlock/SimpleResourceBlock";
import { SimpleResourceEditBlock } from "../../../../features/simpleResources/ui/SimpleResourceEditBlock/SimpleResourceEditBlock";
import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import AddButton from "../../../../shared/ui/controls/AddButton/AddButton";
import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Expandable from "../../atoms/_TODO/Expandable/Expandable";

import { ResourceTypeEnum } from "../../../../features/simpleResources/types/simpleResources";
import { IRouterParamsWithObjectId } from "../../../../types/routerTypes";
import { IMaterialInAddingInFact } from "types/interfaces/Materials";

import { useMaterialsList } from "../../../../hooks/useMaterialsList";
import { TResource } from "./hooks/useSimpleResources";

import { isNanChecker } from "../../../../utils/formatters/isNanChecker";

import materials from "images/icons/navigation/materials.svg";

import styles from "./AddingListInModal.module.scss";

interface IProps {
  addedMaterials: any[] | IMaterialInAddingInFact[];
  selectedUsers: IMaterialInAddingInFact[];
  onAccept: () => boolean;
  onDecline: () => boolean;
  onChangeCount: (id: number, count: number | string, type: "selected" | "added") => void;
  onDelete: (id: number) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: IMaterialInAddingInFact) => void;
  selectedIds: number[];
  addedIds: number[];
  isDisabled: boolean;
  activeSection: number | null;
  activeWork: number | null;
  defaultMaterialsList?: IMaterialInAddingInFact[];
  isWorkGroup?: boolean;
  groupId?: number;
  externalMaterialsData?: {
    allMaterials: any[];
    isLoading: boolean;
    loadMoreHandler: () => void;
    count: number;
    filterHandler: () => void;
  };
  isValidatingCount?: boolean;
  simpleResources?: TResource[];
  createNewSimpleResource?: () => void;
  deleteSimpleResource?: (r: TResource) => void;
  isSimpleResourceAdding?: boolean;
  toggleIsSimpleResourceEditing?: (r: TResource) => void;
  updateSimpleResource?: (r: TResource) => void;
}

const AddMaterialsInModal: React.FC<IProps> = ({
  addedMaterials,
  selectedUsers,
  onAccept,
  onDecline,
  onChangeCount,
  onDelete,
  onSelect,
  selectedIds,
  addedIds,
  isDisabled,
  activeSection,
  activeWork,
  defaultMaterialsList,
  isWorkGroup,
  groupId,
  externalMaterialsData,
  isValidatingCount,
  simpleResources,
  createNewSimpleResource,
  deleteSimpleResource,
  isSimpleResourceAdding,
  toggleIsSimpleResourceEditing,
  updateSimpleResource,
}) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const { allMaterials, isLoading, loadMoreHandler, count, filterHandler } = useMaterialsList({
    objectId,
    new_section_id: activeSection,
    exp_work_id: activeWork,
    defaultMaterialsList,
    isWorkGroup,
    groupId,
    isSkippingRequests: !!externalMaterialsData,
  });

  const [isAdding, setIsAdding] = useState(false);

  const withStopAdding = React.useCallback((cb: () => boolean) => {
    return () => cb?.() && setIsAdding(false);
  }, []);

  //TODO переделать, сбивает с толка
  const loading = externalMaterialsData?.isLoading ?? isLoading;

  const allAvailableMaterials = externalMaterialsData?.allMaterials ?? allMaterials;
  const availableCount = externalMaterialsData?.count ?? count;

  const isAddSimpleMaterialDisabled = isSimpleResourceAdding;
  const onAddSimpleMaterialClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (!isAddSimpleMaterialDisabled) {
      /* @ts-ignore */
      createNewSimpleResource?.({ type: ResourceTypeEnum.material });
    }
  };

  const simpleResourcesWithoutDeleted = useMemo(() => {
    return simpleResources?.filter((r) => !r.delete) ?? [];
  }, [simpleResources]);

  const onSaveSimpleResource = useCallback(
    (r: TResource) => (resource: TResource) => {
      updateSimpleResource?.({ ...r, ...resource });
    },
    [updateSimpleResource]
  );

  const onCancelEditSimpleResource = useCallback(
    (r: TResource) => () => {
      if (r.isAdding) {
        deleteSimpleResource?.(r);
      } else {
        toggleIsSimpleResourceEditing?.(r);
      }
    },
    [deleteSimpleResource, toggleIsSimpleResourceEditing]
  );

  const toggleEditSimpleResource = useCallback(
    (r: TResource) => () => {
      toggleIsSimpleResourceEditing?.(r);
    },
    [toggleIsSimpleResourceEditing]
  );

  const onDeleteSimpleResource = useCallback(
    (r: TResource) => () => {
      deleteSimpleResource?.(r);
    },
    [deleteSimpleResource]
  );

  const resourcesCount = useMemo(() => {
    return isNanChecker(addedMaterials?.length) + isNanChecker(simpleResourcesWithoutDeleted?.length);
  }, [addedMaterials, simpleResourcesWithoutDeleted]);

  const showAddBtn = !isAdding && !loading && count !== addedMaterials?.length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.expandableHeadline}>
        <span className={styles.title}>
          Материалы:{" "}
          {resourcesCount ? (
            resourcesCount
          ) : showAddBtn ? (
            <AddButton className={styles.addButton} isDisabled={isDisabled} onClick={() => setIsAdding(true)} />
          ) : (
            "-"
          )}
        </span>
        <button
          className={cn(styles.subTitleButton, isAddSimpleMaterialDisabled && styles.disabled)}
          disabled={isAddSimpleMaterialDisabled} /* @ts-ignore */
          onClick={onAddSimpleMaterialClick}
        >
          + добавить новый
        </button>
      </div>
      {simpleResourcesWithoutDeleted.map((r) => {
        if (r.isAdding || r.isEditing) {
          return (
            <SimpleResourceEditBlock
              key={r.tmpId ?? r.id}
              name={r.name}
              count={r.count}
              measure={r.measure}
              type={r.type}
              onSave={onSaveSimpleResource(r)}
              onCancel={onCancelEditSimpleResource(r)}
            />
          );
        } else {
          return (
            <SimpleResourceBlock
              key={r.tmpId ?? r.id}
              canInteract
              name={r.name}
              count={r.count}
              measure={r.measure!}
              onEdit={toggleEditSimpleResource(r)}
              onDelete={onDeleteSimpleResource(r)}
            />
          );
        }
      })}
      {!!addedMaterials?.length && (
        <div className={cn(styles.table, styles.marginTop)}>
          <MaterialsTableHeader />
          {externalMaterialsData?.isLoading ? (
            <Spinner isSmall isStatic className={styles.spinner} />
          ) : (
            addedMaterials?.map((el, i) => (
              <MaterialsTableRow
                key={el.id}
                material={el}
                isCanDelete
                onChangeCount={(e) => onChangeCount(el.id, e.target.value, "added")}
                onDelete={() => onDelete(el.id)}
                isCanBeChecked={false}
                isValidatingCount={isValidatingCount}
              />
            ))
          )}
        </div>
      )}
      {isAdding && (
        <ConfirmBlock
          count={selectedUsers?.length}
          onAccept={withStopAdding(onAccept)}
          onDecline={withStopAdding(onDecline)}
        />
      )}
      {isAdding && (
        <div
          className={cn(styles.table, styles.bordered, {
            [styles.minHeight]: loading,
          })}
        >
          <MaterialsTableHeader
            onFiltersChange={isWorkGroup ? undefined : externalMaterialsData?.filterHandler ?? filterHandler}
            className={styles.header}
          />
          {loading && <Spinner />}
          {!!(!loading && allAvailableMaterials?.length) &&
            allAvailableMaterials
              ?.filter((el: any) => !addedIds.includes(el.id))
              .map((item: any) => (
                <MaterialsTableRow
                  key={item.id}
                  material={item}
                  onChangeCount={(e) => onChangeCount(item.id, e.target.value, "selected")}
                  onCheck={onSelect}
                  isSelected={selectedIds.includes(item.id)}
                />
              ))}
          {!loading && !allAvailableMaterials?.length && <EmptyPlaceholder img={materials} />}
          <MoreButton
            isLoading={loading}
            allCount={availableCount}
            existingCount={allAvailableMaterials?.length}
            onLoadMore={externalMaterialsData?.loadMoreHandler ?? loadMoreHandler}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(AddMaterialsInModal);
