import moment from "moment";

import { IChartIntervalBase } from "redux/modules/common/chart/types";

interface IfilterIntervalsInRange {
  /** Дата в формате YYYY-MM-DD */
  start: string;
  /** Дата в формате YYYY-MM-DD */
  end: string;
}

export const filterIntervalsInRange = ({ start, end }: IfilterIntervalsInRange) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  return (interval: IChartIntervalBase) => {
    const intervalStartMoment = moment(interval.start);
    return intervalStartMoment.isSameOrAfter(startMoment, "day") && intervalStartMoment.isSameOrBefore(endMoment, "day");
  }
};
