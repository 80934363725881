import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import AddRelationsModal from "components/UI/_TODO/Expenditure/components/AddRelationsModal/AddRelationsModal";

import ProductInfoModal from "entities/ProductInfoModal/ProductInfoModal";
import MatchingIcon from "shared/ui/dataDisplay/MatchingIcon/MatchingIcon";

interface IProps {
  buildingId: number;
  expenditure: any;
  parentId: any;
  loadSectionExpenditures: any;
}

const BuildingMatchingModal = ({ buildingId, expenditure, parentId, loadSectionExpenditures }: IProps) => {
  const dispatch = useDispatch();
  const [isInfo, setIsInfo] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const isMatched = !!expenditure?.estimate_expenditures?.length;

  const onClick = () => {
    if (!isMatched) {
      setIsMatching(true);
    } else {
      setIsInfo(true);
    }
  };

  //
  const hz = useMemo(() => {
    if (!isMatching) return null;
    return { id: expenditure.id, editable: true };
  }, [expenditure, isMatching]);

  //TODO перенести в редакс когда у нас будет слайс для этой сущности
  const [data, setData] = useState<any>();
  const [isLoaidng, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isInfo) return;
    setIsLoading(true);
    axios
      .get(`/building/${buildingId}/estimate/expenditure/${expenditure.estimate_expenditures[0]}/`)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isInfo, buildingId]);

  return (
    <div>
      <MatchingIcon onClick={onClick} variant={isMatched ? "green" : "black"} isWithAttentionSign={!isMatched} />
      <AddRelationsModal
        relationOriginInfo={hz}
        handleClose={() => setIsMatching(false)}
        objectId={String(buildingId)}
        updateExpenditure={() => {
          loadSectionExpenditures?.();
          setIsMatching(false);
        }}
        type={undefined}
      />
      <ProductInfoModal
        isOpen={isInfo}
        onClose={() => setIsInfo(false)}
        onOpenEditing={() => {
          setIsInfo(false);
          setIsMatching(true);
        }}
        canEdit
        isLoading={isLoaidng}
        section={data?.section?.name}
        number={data?.number}
        justification={data?.justification}
        expenditureName={data?.name}
        price={data?.price}
        count={data?.count}
        /* title={"???"}
        labelBudget={"???"}
        labelCount={"???"} */
      />
    </div>
  );
};

export default BuildingMatchingModal;
