import { Field } from "react-final-form";

import MeasureSelect from "entities/MeasureSelect/MeasureSelect";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";

import { composeFieldValidators, maxLength, mustBeNumber, required } from "utils/formHelpers/validations";

import styles from "./MaterialsExpenditureForm.module.scss";

type formKeys = "name" | "measure" | "count" | "price" | "producer" | "number" | "brand" | "type" | "from_estimate";

const fields: Array<formKeys> = [
  "name",
  "measure",
  "count",
  "price",
  "producer",
  "number",
  "brand",
  "type",
  "from_estimate",
];

export interface MaterialsExpenditureItem {
  name: string;
  measure: string;
  count: number;
  price: string;
  producer: string;
  number: string;
  brand: string;
  type: string;
  from_estimate: boolean;
}

interface IProps {
  itemFieldNames?: Record<formKeys, string>;
  onDelete?: () => void;
  isPending?: boolean;
  canSave?: boolean;
  onSave?: () => void;
  canRemove?: boolean;
  onRemoveDirectly?: () => void;
  isFromEstimate?: boolean;
}

const MaterialsExpenditureForm = ({
  itemFieldNames = Object.fromEntries(fields.map((f) => [f, f])) as Record<formKeys, string>,
  isPending,
  canSave,
  onSave,
  canRemove,
  onRemoveDirectly,
  isFromEstimate,
}: IProps) => {
  return (
    <div className={styles.form}>
      <div className={styles.row1}>
        <RowGrid percents={[50, 15, 15, 15, 5]}>
          <Field
            name={itemFieldNames.name}
            render={(props) => <InputBase {...props} classNameLabel={styles.label} />}
            label="Наименование позиции:"
          />
          <Field
            name={itemFieldNames.measure}
            validate={composeFieldValidators(required())}
            render={({ input }) => <MeasureSelect input={input} label={`Ед.изм.`} classNameLabel={styles.label} />}
          />
          <Field
            name={itemFieldNames.count}
            label={`Кол-во`}
            type="number"
            render={(props) => <InputBase {...props} valueType={VALUE_TYPES.NUMBER} classNameLabel={styles.label} />}
            validate={composeFieldValidators(mustBeNumber, required(), maxLength(15))}
          />
          <Field
            name={itemFieldNames.price}
            label={`Цена`}
            render={(props) => <InputBase {...props} classNameLabel={styles.label} />}
            type="number"
          />
          {(canRemove || canSave) && (
            <Actions
              canRemove={canRemove}
              onRemoveDirectly={onRemoveDirectly}
              canSave={canSave}
              onSave={onSave}
              disabled={isPending}
            />
          )}
        </RowGrid>
      </div>
      <RowGrid percents={[25, 25, 30, 20]}>
        <Field
          name={itemFieldNames.producer}
          label={`Производитель:`}
          render={(props) => <InputBase {...props} classNameLabel={styles.label} />}
        />

        <Field
          name={itemFieldNames.number}
          label={`Код:`}
          render={(props) => <InputBase {...props} classNameLabel={styles.label} />}
        />

        <Field
          name={itemFieldNames.brand}
          label={`Марка:`}
          render={(props) => <InputBase {...props} classNameLabel={styles.label} />}
        />
      </RowGrid>
    </div>
  );
};

export default MaterialsExpenditureForm;
