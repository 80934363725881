import React from "react";

import ExpendituresTableHeader from "./components/TableHeader/ExpendituresTableHeader";
import ExpenditureTableRow from "./components/TableRow/ExpenditureTableRow";

import Expandable from "../../atoms/_TODO/Expandable/Expandable";

import { IMimsInAdding } from "types/interfaces/Mims";

import styles from "./AddingListInModal.module.scss";

interface IAddExpendituresInModalProps {
  addedExpenditures: any[] | IMimsInAdding[];
  onChangeCount: (id: number, count: number | string) => void;
}

const AddExpendituresInModal: React.FC<IAddExpendituresInModalProps> = ({ addedExpenditures, onChangeCount }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.expandableHeadline}>
        <span className={styles.title}>Расценки: {addedExpenditures?.length || "-"}</span>
      </div>
      {!!addedExpenditures?.length && (
        <div className={styles.table}>
          <ExpendituresTableHeader />
          {addedExpenditures?.map((expenditure) => (
            <ExpenditureTableRow
              key={expenditure.id}
              expenditure={expenditure}
              onChangeCount={(event) => onChangeCount(expenditure.id, event.target.value)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(AddExpendituresInModal);
