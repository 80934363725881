import { Table as AntTable } from "antd";
import cn from "classnames";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { IFinanceTableRowData } from "pages/Finance/Summary/model/types";
import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { isFinanceFooter, isQuartalColumns } from "pages/Finance/_TODO/isFinanceFooter";
import { summaryActions } from "pages/Finance/_TODO/summaryScrollReducer/actions";
import { SCROLL_EVENT_TARGETS } from "pages/Finance/_TODO/summaryScrollReducer/constants";
import { summaryYearSelector, tableScrollPositionSelector } from "pages/Finance/_TODO/summaryScrollReducer/selectors";
import { useTableHover } from "pages/Finance/_TODO/useTableHover";
import { useFinanceFiltersContext } from "pages/Finance/common/contexts/FinanceFiltersContext/FinanceFiltersContext";
import { IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import renderMoneyCell from "./_internal/MoneyCell";
import { useTypedSelector } from "app/store/typedUseSelector";
import { useFinanceMeasure } from "features/financeMeasure";

import { MonthAndQuarterArray, MonthArray } from "constants/constant";
import { LOCALIZATION_CONFIG } from "constants/localization";

import {
  getFinanceDiffKey,
  getFinanceTableCellISPercents,
  getFinanceTableColumnTitle,
  getFinanceTableKey,
  getTableViewWidthPercent,
  scrollTable,
} from "./utils";

import styles from "./Table.module.scss";

const { Column, ColumnGroup } = AntTable;

interface IProps {
  data: IFinanceTableRowData[];
  totalsData?: IFinanceTreeItem | null;
}

const Table = (props: IProps) => {
  const objectId = useObjectId(3);
  const { projectId } = useParams<any>();
  const { data, totalsData } = props;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const scrollPosition = useSelector(tableScrollPositionSelector);
  const year = useSelector(summaryYearSelector) as number;

  const { measure, measureInTitle } = useFinanceMeasure();

  useTableHover();

  useEffect(() => {
    const handleResize = () => {
      const tableViewWidthPercent = getTableViewWidthPercent(tableRef.current);
      dispatch(summaryActions.setTableViewWidthPercent(tableViewWidthPercent));
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    /* @ts-ignore */
    if (scrollPosition.eventFrom !== SCROLL_EVENT_TARGETS.TABLE) {
      /* @ts-ignore */
      scrollTable(tableRef.current, scrollPosition.percent);
    }
  }, [scrollPosition]);

  //TODO вынести все связанное со скроллом
  useEffect(() => {
    /* @ts-ignore */
    const tableScrollableElement = tableRef.current.querySelectorAll(".ant-table-content")[0];
    /* @ts-ignore */
    const tableScrollableElementTotal = tableRef.current.querySelectorAll(".ant-table-content")[1];
    const header = document.querySelector(".ant-table-content");
    const footer = document.querySelector(".tableFooter");
    /* @ts-ignore */
    header.style.scrollBehavior = "auto";
    tableScrollableElement.style.scrollBehavior = "auto";
    tableScrollableElement.scrollLeft = 0;
    if (!!footer) {
      /* @ts-ignore */
      footer.style.scrollBehavior = "auto";
      footer.scrollLeft = 0;
    }
    if (!!tableScrollableElementTotal) {
      tableScrollableElementTotal.style.scrollBehavior = "auto";
      tableScrollableElementTotal.scrollLeft = 0;
    }

    const debouncedHandler = debounce((e) => {
      dispatch(
        summaryActions.setTableScrollPosition({
          scrollPosition: tableScrollableElement.scrollLeft || tableScrollableElementTotal?.scrollLeft,
          containerWidth: tableScrollableElement.scrollWidth || tableScrollableElementTotal?.scrollWidth,
          eventFrom: SCROLL_EVENT_TARGETS.TABLE,
        })
      );
    }, 50);
    /* @ts-ignore */
    const handleTableScroll = (e) => {
      /* @ts-ignore */
      debouncedHandler();
      requestAnimationFrame(() => {
        /* @ts-ignore */
        header.scroll(e.target.scrollLeft, 0);
        tableScrollableElementTotal?.scroll(e.target.scrollLeft, 0);
        footer?.scroll(e.target.scrollLeft, 0);
      });
    };
    /* @ts-ignore */
    const handleTableTotalScroll = (e) => {
      /* @ts-ignore */
      debouncedHandler();
      requestAnimationFrame(() => {
        /* @ts-ignore */
        header.scroll(e.target.scrollLeft, 0);
        tableScrollableElement?.scroll(e.target.scrollLeft, 0);
        footer?.scroll(e.target.scrollLeft, 0);
      });
    };

    tableScrollableElement?.addEventListener("scroll", handleTableScroll);
    tableScrollableElementTotal?.addEventListener("scroll", handleTableTotalScroll);

    return () => {
      tableScrollableElement?.removeEventListener("scroll", handleTableScroll);
      tableScrollableElementTotal?.removeEventListener("scroll", handleTableTotalScroll);
    };
  }, [objectId, projectId, totalsData]);

  const { financeView } = useFinanceFiltersContext();

  const diffKey = getFinanceDiffKey(financeView);

  return (
    <div ref={tableRef} className={cn(styles.tableWrapper, { [styles.hideScroll]: isFinanceFooter(objectId) })}>
      <AntTable dataSource={data} size="small" scroll={{ x: 1300 }} pagination={false}>
        {isQuartalColumns(objectId, projectId)
          ? MonthAndQuarterArray.map((month) => (
              <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                <Column
                  /* titles are in <FinanceTableHead /> */
                  title={""}
                  dataIndex={getFinanceTableKey(month.id, "plan", year)}
                  key={getFinanceTableKey(month.id, "plan", year)}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure, false, data < 0 ? styles.negative : "")}
                  ellipsis
                />
                <Column
                  title={""}
                  dataIndex={getFinanceTableKey(month.id, "fact", year)}
                  key={getFinanceTableKey(month.id, "fact", year)}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure)}
                  ellipsis
                />
                <Column
                  title={""}
                  dataIndex={getFinanceTableKey(month.id, diffKey, year)}
                  key={getFinanceTableKey(month.id, diffKey, year)}
                  width={116}
                  render={(data) =>
                    renderMoneyCell(data, measure, true, "", getFinanceTableCellISPercents(financeView))
                  }
                  ellipsis
                />
              </ColumnGroup>
            ))
          : MonthArray.map((month, idx) => (
              <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
                <Column
                  title={""}
                  dataIndex={getFinanceTableKey(month.id, "plan", year)}
                  key={getFinanceTableKey(month.id, "plan", year)}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure, false, data < 0 ? styles.negative : "")}
                  ellipsis
                />
                <Column
                  title={""}
                  dataIndex={getFinanceTableKey(month.id, "fact", year)}
                  key={getFinanceTableKey(month.id, "fact", year)}
                  width={116}
                  render={(data) => renderMoneyCell(data, measure)}
                  ellipsis
                />
                <Column
                  title={""}
                  dataIndex={getFinanceTableKey(month.id, diffKey, year)}
                  key={getFinanceTableKey(month.id, diffKey, year)}
                  width={116}
                  render={(data) =>
                    renderMoneyCell(data, measure, true, "", getFinanceTableCellISPercents(financeView))
                  }
                  ellipsis
                />
              </ColumnGroup>
            ))}
      </AntTable>
      {/* TODO_V2_FINANCE */}
      {!!totalsData && isFinanceFooter(objectId, projectId) && (
        <AntTable
          dataSource={summaryUtils.extractTableData([totalsData!])}
          size="small"
          scroll={{ x: 1300 }}
          pagination={false}
          className={styles.totalString}
        >
          {MonthAndQuarterArray.map((month) => (
            <ColumnGroup title={`${month.label} ${year}`} key={month.name}>
              <Column
                title={""}
                dataIndex={getFinanceTableKey(month.id, "plan", year)}
                key={getFinanceTableKey(month.id, "plan", year)}
                width={116}
                render={(data) => renderMoneyCell(data, measure, false, data < 0 ? styles.negative : "")}
                ellipsis
              />
              <Column
                title={""}
                dataIndex={getFinanceTableKey(month.id, "fact", year)}
                key={getFinanceTableKey(month.id, "fact", year)}
                width={116}
                render={(data) => renderMoneyCell(data, measure)}
                ellipsis
              />
              <Column
                title={""}
                dataIndex={getFinanceTableKey(month.id, diffKey, year)}
                key={getFinanceTableKey(month.id, diffKey, year)}
                width={116}
                render={(data) => renderMoneyCell(data, measure, true, "", getFinanceTableCellISPercents(financeView))}
                ellipsis
              />
            </ColumnGroup>
          ))}
        </AntTable>
      )}
    </div>
  );
};

export default React.memo(Table);
