import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";

import ConfirmBlock from "components/UI/_TODO/AddingListInModal/components/ConfirmBlock/ConfirmBlock";
import Select from "components/UI/atoms/Select";
import Expandable from "components/UI/atoms/_TODO/Expandable/Expandable";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useGetDrawingSetsQuery, useGetDrawingsQuery } from "pages/Estimate/Blueprints/model/blueprintsQuery";

import Actions from "shared/ui/controls/Actions/Actions";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import InputBase from "shared/ui/inputs/InputBase";
import InputDoubleOrSingle from "shared/ui/inputs/InputDoubleOrSingle/InputDoubleOrSingle";

import { ICreateAxesArg, ICreateAxesForm } from "./model/types";

import { axesUtils } from "./utils";
import { composeFieldValidators, required } from "utils/formHelpers/validations";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./AxesAndMarks.module.scss";

interface IProps {
  isOpen: boolean;
  onSubmit: (v: ICreateAxesArg) => void;
  initialValues?: ICreateAxesForm;
  onDeleteAxis?: (id: number) => void;
  onMarkEdited?: (id: number) => void;
  isNotEmpty?: boolean;
  expenditureIds?: number[];
  onAddNew?: () => void;
  onCancel?: () => void;
  onSplitInput?: (id: number) => void;
  setIsDirty?: (status: boolean) => void;
}

const AxesAndMarksForm: React.FC<IProps> = ({
  isOpen = true,
  onSubmit,
  initialValues,
  onDeleteAxis,
  onMarkEdited,
  isNotEmpty,
  expenditureIds,
  onAddNew,
  onCancel,
  onSplitInput,
  setIsDirty,
}) => {
  const [isAdding, setIsAdding] = useState(isNotEmpty);
  const buildingId = useObjectId();

  const { data: allDrawingsData } = useGetDrawingsQuery(
    { buildingId, expenditure_ids: expenditureIds },
    { skip: !isOpen }
  );

  const drawingsSets = useMemo(() => {
    return axesUtils.getDrawingsSetsFromDrawings(allDrawingsData?.results);
  }, [allDrawingsData]);

  const { data: drawingSetsData } = useGetDrawingSetsQuery({ buildingId }, { skip: !isOpen });

  const drawingSetsOptions = useMemo(() => {
    return drawingSetsData?.results?.map(({ id, title }) => ({ id, name: title })) ?? [];
  }, [drawingSetsData]);

  const [selectedDrawingSet, setSelectedDrawingSet] = useState(0);
  const { data: drawingsData } = useGetDrawingsQuery(
    { buildingId, drawing_set: selectedDrawingSet, expenditure_ids: expenditureIds },
    { skip: !isOpen || !selectedDrawingSet }
  );

  const onSelectDrawingSet = (id: number | string) => {
    setSelectedDrawingSet(+id);
    setSelectedDrawing(0);
  };

  const [selectedDrawing, setSelectedDrawing] = useState(0);
  const drawingsOptions = useMemo(() => {
    return drawingsData?.results?.map(({ id, title }) => ({ id, name: title })) ?? [];
  }, [drawingsData]);

  const [items, setItems] = useState<any[]>([`n_${Date.now()}`]);

  const submitForm = (values: ICreateAxesForm) => {
    const axis = axesUtils.extractAxisValuesFromForm(values);
    onSubmit({ ...values, drawing: selectedDrawing, axis });
  };

  useEffect(() => {
    if (!!initialValues) {
      setItems(Object.entries(initialValues?.axis!)?.map(([id]) => id));
      setSelectedDrawingSet(+initialValues.drawing_set!);
      setSelectedDrawing(+initialValues.drawing);
    }
  }, [initialValues]);

  // Автозаполнение чертежа если доступен только 1
  useEffect(() => {
    if (!initialValues && drawingsSets?.length === 1) {
      onSelectDrawingSet(drawingsSets[0].id);
    }
    if (!initialValues && drawingsOptions?.length === 1) {
      setSelectedDrawing(drawingsOptions[0].id);
    }
  }, [initialValues, drawingsSets, drawingsOptions]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submitForm}
      mutators={{
        deleteAxis: ([id], state, { changeValue }) => {
          //@ts-ignore
          const newAxis = { ...state.formState.values.axis };
          delete newAxis[`${id}`];
          changeValue(state, "axis", () => newAxis);
        },
      }}
      render={({ handleSubmit, form, hasValidationErrors }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.expandableTitle} onClick={stopEventPropagation}>
            Документация:
            {!isAdding && (
              <AddButton
                onClick={() => {
                  setIsAdding(true);
                  onAddNew?.();
                  setIsDirty?.(true);
                }}
              />
            )}
          </div>
          {isAdding && (
            <ConfirmBlock
              disabled={hasValidationErrors || !selectedDrawing || !selectedDrawingSet}
              onAccept={() => {
                setIsDirty?.(false);
                handleSubmit();
              }}
              onDecline={() => {
                setIsAdding(false);
                onCancel?.();
                setIsDirty?.(false);
              }}
              isWithoutCount
            />
          )}
          {isAdding && (
            <div className={styles.form}>
              <div className={styles.first}>
                <Select
                  value={selectedDrawingSet}
                  onChange={onSelectDrawingSet}
                  label="Комплект чертежей"
                  options={drawingsSets}
                  containerClassName={styles.select}
                />
                <Select
                  value={selectedDrawing}
                  onChange={(id) => setSelectedDrawing(+id)}
                  label="Чертеж"
                  options={drawingsOptions}
                  disabled={!selectedDrawingSet}
                  containerClassName={styles.select}
                />
                {/*  <Field name="construction" component={InputBase as any} label="Конструкция" /> */}
              </div>
              <div className={styles.second}>
                <div className={styles.row}>
                  <label>Ось</label>
                  <label>Отметка</label>
                </div>
                {items?.map((el, i) => {
                  const isFirst = i === 0;
                  const id = el.id ?? el;
                  const [numericId] = axesUtils.extractIdFromFormKey(id);

                  return (
                    <div className={styles.row} key={id}>
                      <Field
                        name={`axis.${id}.axis`}
                        validate={composeFieldValidators(required())}
                        render={({ input }) => (
                          <InputBase
                            value={input.value}
                            placeholder="Укажите"
                            onChange={(e) => {
                              input.onChange(e.target.value);
                              onMarkEdited?.(+numericId);
                            }}
                          />
                        )}
                      />
                      <InputDoubleOrSingle
                        validate={composeFieldValidators(required())}
                        formValues={form.getState().values}
                        firstFieldName={`axis.${id}.mark_start`}
                        secondFieldName={`axis.${id}.mark_end`}
                        placeholder="Укажите"
                        onChange={() => onMarkEdited?.(+numericId)}
                        isDefaultDoubled={
                          form.getState().values?.axis?.[id]?.mark_start !==
                          form.getState().values?.axis?.[id]?.mark_end
                        }
                        onSplit={() => onSplitInput?.(id)}
                      />
                      <div className={styles.control}>
                        {isFirst ? (
                          <AddButton onClick={() => setItems((p) => [...p, `n_${Date.now()}_new`])} />
                        ) : (
                          <Actions
                            canRemove
                            onRemove={() => {
                              setItems((p) => p.filter((el) => el !== id && el.id !== id));
                              form.mutators.deleteAxis(id);
                              onDeleteAxis?.(+numericId);
                            }}
                            className={cn(styles.delete)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </form>
      )}
    />
  );
};

export default AxesAndMarksForm;
