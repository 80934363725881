import { useAddIntervals } from "./useAddIntervals";
import { useBuilidngExpIndicators } from "./useBuilidngExpIndicators";
import { useIntervals } from "./useIntervals";
import { useOverViewState } from "./useOverViewState";

export const buildingExpHooks = {
  useIntervals,
  useBuilidngExpIndicators,
  useOverViewState,
  useAddIntervals,
};
