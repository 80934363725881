//@ts-nocheck
import { message } from "antd";
import axios from "axios";
import { Dispatch } from "redux";

import { journalActions, setGroupDetailInfo } from "./actions";
import { journalApi } from "./journalApi";
import { journalExecutionActions } from "./journalExecution/actions";
import { RootState } from "app/store/rootReducer";

import { IChangeJournalPriceTypeRequest, IConfirmGroupData } from "./types";
import { ITicketJournalDelivery } from "types/interfaces/Tickets";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const updateStockUsingMaterialsPrice =
  (buildingId: number, data: IChangeJournalPriceTypeRequest) => (dispatch: Dispatch) => {
    journalApi
      .changeMaterialPrice(buildingId, data)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        dispatch(journalExecutionActions.changeInvalidateKey());
        dispatch(journalExecutionActions.changeMaterialsInvalidateKey());
        message.success("Стоимость успешно выбрана");
      })
      .catch(errorCatcher);
  };

export const patchTicket = (
  objectId: string,
  ticketId: number,
  ticketData: Record<string, string | number>,
  successCallback?: () => void,
  successMsg?: string,
  scCb?: () => void
) => {
  return (dispatch: Dispatch) => {
    journalApi
      .patchTicket(objectId, ticketId, ticketData)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        successCallback && successCallback();
        successMsg && message.success(successMsg);
        scCb?.();
      })
      .catch(errorCatcher);
  };
};

interface ICreateTicketAdditionalConfig {
  finallyCallback?: () => void;
  successCallback?: (data: any) => void;
}

export const createTicket = (
  objectId: string,
  ticketData: Partial<ITicketJournalDelivery>,
  files: any[] = [],
  locationArray: string[] = [],
  { finallyCallback, successCallback }: ICreateTicketAdditionalConfig = {}
) => {
  return (dispatch: Dispatch) => {
    journalApi
      .createTicket(objectId, ticketData)
      .then((response) => {
        if (locationArray.includes("journal")) {
          message.success("Запись успешно добавлена");
        } else {
          message.success("Запись успешно добавлена в Журнал Учета");
        }
        dispatch(journalActions.changeInvalidateKey());

        successCallback?.(response.data);

        if (files.length) {
          const formData = new FormData();
          files.forEach((file) => formData.append("file", file.file));
          axios.post(`/building/${objectId}/fact-intervals/${response.data.id}/files/`, formData).catch(errorCatcher);
        }
        successCallback?.(response.data.id);
      })
      .catch(errorCatcher)
      .finally(() => {
        finallyCallback?.();
      });
  };
};

export const getGroupDetailInfo = (objectId: string, groupId: number) => (dispatch: Dispatch) => {
  journalApi
    .getGroupDetailInfo(objectId, groupId)
    .then(({ data }) => {
      dispatch(setGroupDetailInfo(data));
    })
    .catch(errorCatcher);
};

export const confirmGroup = (
  objectId: string,
  groupId: number,
  groupData: IConfirmGroupData,
  successCallback?: () => void
) => {
  return (dispatch: Dispatch) => {
    journalApi
      .postGroup(objectId, groupId, groupData)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
        successCallback?.();
      })
      .catch(errorCatcher);
  };
};

export const approveTicket = (buildingId: string, expenditureId: number, isGroup: boolean) => {
  return (dispatch: Dispatch) => {
    const api = isGroup ? journalApi.approveGroupTicket : journalApi.approveTicket;
    return api(buildingId, expenditureId)
      .then(() => {
        dispatch(journalActions.changeInvalidateKey());
      })
      .catch(errorCatcher);
  };
};
