import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import {
  handlerIndexesInvalidateKeySelector,
  handlerIndexesLoadingSelector,
  handlerIndexesSelector,
} from "./model/selectors";
import { getHandlerIndexes } from "./model/thunks";
import HandlerIndexesModal from "./ui/HandlerIndexesModal/HandlerIndexesModal";
import HandlerIndexesPopover from "./ui/HandlerIndexesPopover/HandlerIndexesPopover";
import ButtonBase from "shared/ui/controls/ButtonBase";

import { IIdAndName } from "types/idAndName";

import styles from "./HandlerIndexes.module.scss";

interface IProps {
  section: IIdAndName & { sum_estimate_amount_source_before_index: string };
  amounts: Record<string, string>;
  className?: string;
}

const HandlerIndexes: React.FC<IProps> = ({ section, amounts, className }) => {
  const objectId = useObjectId(3);
  const dispatch = useDispatch();
  const indexes = useSelector(handlerIndexesSelector)[section?.id!];
  const isLoading = useSelector(handlerIndexesLoadingSelector)[section?.id!];
  const invalidateKey = useSelector(handlerIndexesInvalidateKeySelector);

  const loadIndexes = () => {
    dispatch(getHandlerIndexes(objectId, section?.id!));
  };

  useEffect(() => {
    loadIndexes();
  }, [invalidateKey, objectId, section?.id]);

  const openModal = () => {
    setIsModal(true);
  };

  const [isModal, setIsModal] = useState(false);

  const defaultAmount = section.sum_estimate_amount_source_before_index;

  return (
    <div className={styles.container}>
      <PopoverOverlay
        content={
          <HandlerIndexesPopover
            defaultAmount={String(defaultAmount)}
            onClick={openModal}
            isLoading={isLoading}
            indexes={indexes}
          />
        }
        placement="left"
        popoverBorderColor="primary"
        openType="hover"
        className={styles.popoverTarget}
      >
        <ButtonBase primary onClick={openModal} className={cn(styles.buttonGroup, className)}>
          Индексы
        </ButtonBase>
      </PopoverOverlay>
      <HandlerIndexesModal
        defaultAmount={String(defaultAmount)}
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        section={section}
        indexes={indexes}
        isLoading={isLoading}
        allAmount={amounts?.all!}
        objectId={objectId}
      />
    </div>
  );
};

export default HandlerIndexes;
