import { groupBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";

import { VARIANTS } from "components/UI/_TODO/Expenditure/constants";

interface IProps {
  intervals: any;
  approvedIntervals: any;
  isExpanded: any;
  isProgressVariant: any;
  planIntervals: any;
  factIntervals: any;
  tab: any;
}
//вообще хз что тут происходит
export const useIntervals = ({
  intervals,
  approvedIntervals,
  isExpanded,
  isProgressVariant,
  planIntervals,
  factIntervals,
  tab,
}: IProps) => {
  const slicedIntervals = useMemo(() => {
    return {
      plans: intervals?.plans ? intervals?.plans?.slice(0, isExpanded ? intervals?.plans?.length : 3) : [],
      facts: intervals?.facts ? intervals?.facts?.slice(0, isExpanded ? intervals?.facts?.length : 3) : [],
      donned: intervals?.donned ? intervals?.donned?.slice(0, isExpanded ? intervals?.donned?.length : 3) : [],
      received: intervals?.received ? intervals?.received?.slice(0, isExpanded ? intervals?.received?.length : 3) : [],
      approved: approvedIntervals ? approvedIntervals?.slice(0, isExpanded ? approvedIntervals?.length : 3) : [],
    };
  }, [intervals, isExpanded, approvedIntervals]);

  const planIntervalItems = useMemo(() => {
    if (!intervals && !approvedIntervals) return [];
    return isProgressVariant ? intervals?.donned || [] : approvedIntervals || [];
  }, [intervals, isProgressVariant, approvedIntervals]);

  const factIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant ? intervals?.received || [] : intervals.facts || [];
  }, [intervals, isProgressVariant]);

  const inProductionIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant ? intervals?.facts || [] : intervals?.plans || [];
  }, [intervals, isProgressVariant]);

  const groupedPlanGroupIntervals = useMemo(() => groupBy(planIntervals, (item) => item.week), [planIntervals]);
  const groupedFactGroupIntervals = useMemo(() => groupBy(factIntervals, (item) => item.week), [factIntervals]);

  const reducedPlanGroupIntervals = useMemo(
    () =>
      Object.values(groupedPlanGroupIntervals).flatMap((item) =>
        item.reduce(
          (prevValue, currentValue, index) => ({
            ...item[index],
            count: prevValue.count + +currentValue?.count,
            start_at:
              moment(currentValue?.start_at).isBefore(prevValue.start_at) || !prevValue.start_at
                ? currentValue?.start_at
                : prevValue.start_at,
            end_at:
              moment(currentValue?.end_at).isAfter(prevValue.end_at) || !prevValue.end_at
                ? currentValue?.end_at
                : prevValue.end_at,
          }),
          {
            count: 0,
            start_at: "",
            end_at: "",
          }
        )
      ) || [],
    [groupedPlanGroupIntervals]
  );

  const reducedFactGroupIntervals = useMemo(
    () =>
      Object.values(groupedFactGroupIntervals).flatMap((item) =>
        item.reduce(
          (prevValue, currentValue, index) => ({
            ...item[index],
            count: +currentValue?.count + prevValue.count,
            start_at:
              moment(currentValue?.start_at).isBefore(prevValue.start_at) || !prevValue.start_at
                ? currentValue?.start_at
                : prevValue.start_at,
            end_at:
              moment(currentValue?.end_at).isAfter(prevValue.end_at) || !prevValue.end_at
                ? currentValue?.end_at
                : prevValue.end_at,
          }),
          {
            count: 0,
            start_at: "",
            end_at: "",
          }
        )
      ) || [],
    [groupedFactGroupIntervals]
  );

  const currentGroupIntervals = useMemo(
    () => (tab === VARIANTS.PROGRESS ? reducedFactGroupIntervals : reducedPlanGroupIntervals),
    [tab, reducedFactGroupIntervals, reducedPlanGroupIntervals]
  );

  const currentInProductionIntervals = useMemo(() => {
    if (tab === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => !item.is_confirmed);
    } else if (tab === VARIANTS.PLAN && reducedPlanGroupIntervals?.length > 0) {
      return reducedPlanGroupIntervals;
    }
    return inProductionIntervalItems;
  }, [tab, reducedFactGroupIntervals, reducedPlanGroupIntervals, inProductionIntervalItems]);

  const currentPlanIntervals = useMemo(() => {
    if (tab === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => item.is_confirmed && !item.is_accepted);
    } else {
      return planIntervalItems;
    }
  }, [tab, planIntervalItems, reducedFactGroupIntervals]);

  const currentFactIntervals = useMemo(() => {
    if (tab === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => item.is_confirmed && item.is_accepted);
    } else if (tab === VARIANTS.PLAN && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals;
    }
    return factIntervalItems;
  }, [tab, reducedFactGroupIntervals, factIntervalItems]);

  const isInProductionIntervalCompleted = useMemo(() => {
    if (factIntervals?.length > 0 || planIntervals?.length > 0) {
      return !currentGroupIntervals?.length && (currentFactIntervals?.length || planIntervalItems?.length);
    }

    if (tab === VARIANTS.PROGRESS) {
      return !slicedIntervals.facts?.length && (slicedIntervals.donned?.length || slicedIntervals.received?.length);
    } else {
      //@ts-ignore
      return !slicedIntervals.plans?.length && !slicedIntervals.approveds?.length && slicedIntervals.facts?.length;
    }
  }, [tab, slicedIntervals, factIntervals, planIntervals, planIntervalItems]);

  const isPlanIntervalsCompleted = useMemo(() => {
    if (factIntervals?.length > 0 || planIntervals?.length > 0) {
      return !planIntervalItems?.length && currentFactIntervals?.length;
    }
    if ([VARIANTS.PROGRESS, VARIANTS.HANDLER, VARIANTS.HANDLER_DRAFT].indexOf(tab) !== -1) {
      return !slicedIntervals.donned?.length && slicedIntervals.received?.length && !slicedIntervals.facts?.length;
    } else {
      //@ts-ignore
      return !slicedIntervals.approveds?.length && slicedIntervals.facts?.length && !slicedIntervals.plans?.length;
    }
  }, [tab, slicedIntervals, factIntervals, planIntervals]);

  return {
    slicedIntervals,
    planIntervalItems,
    factIntervalItems,
    inProductionIntervalItems,
    isInProductionIntervalCompleted,
    isPlanIntervalsCompleted,
    currentPlanIntervals,
    currentInProductionIntervals,
    groupedFactGroupIntervals,
    groupedPlanGroupIntervals,
    currentFactIntervals,
  };
};
