import React, { useMemo, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import TemplateSimple from "_LEGACY/UI/_LEGACY_TemplateSimple/TemplateSimple";
import ForbiddenPage from "app/routes/components/ForbiddenPage/ForbiddenPage";
import { useAutoRedirectIntoOnlyObject } from "features/templates/Header/components/Breadcrumbs/useAutoRedirectIntoOnlyObject";

import Audit from "./Audit/Audit";
import Forecast from "./Forecast/Forecast";
import Summary from "./Summary/Summary";
import { FinanceFiltersContextProvider } from "./common/contexts/FinanceFiltersContext/FinanceFiltersContext";
import TabBar from "shared/ui/controls/TabBar/TabBar";

import {
  VIEW_FINANCE_AUDIT,
  VIEW_FINANCE_FORECAST,
  VIEW_FINANCE_SUMMARY,
} from "constants/permissions/constructingPermissions";
import { ROUTES } from "constants/routes";

import { useGetFinanceTabs } from "./common/hooks/useGetFinanceTabs";
import usePermission from "hooks/usePermission";

import styles from "./Finance.module.scss";

const Finance = () => {
  const haveViewFinanceAuditPermission = usePermission(VIEW_FINANCE_AUDIT);
  const haveViewFinanceSummaryPermission = usePermission(VIEW_FINANCE_SUMMARY);
  const haveViewFinanceForecastPermission = usePermission(VIEW_FINANCE_FORECAST);

  const { tabs, ableTab } = useGetFinanceTabs();

  const [tabsOpen, setTabsOpen] = useState(false);

  //useAutoRedirectIntoOnlyObject();

  const TabsComponent = () => {
    return (
      <div className={styles.tabs}>
        <TabBar
          className={styles.tabsContainer}
          tabs={tabs}
          isDirectlyOpen={tabsOpen}
          setIsDirectlyOpen={setTabsOpen}
        />
      </div>
    );
  };

  return (
    <TemplateSimple dataTestId="page_finances" contentClassName={styles.templateContent}>
      <div className={styles.finance}>
        <Switch>
          {haveViewFinanceAuditPermission && (
            <Route
              path={ROUTES.CONSTRUCTING_FINANCE_AUDIT}
              render={() => (
                <Audit>
                  <TabsComponent />
                </Audit>
              )}
            />
          )}
          {haveViewFinanceSummaryPermission && (
            <Route
              path={ROUTES.CONSTRUCTING_FINANCE_SUMMARY}
              render={() => (
                <FinanceFiltersContextProvider>
                  <Summary>
                    <TabsComponent />
                  </Summary>
                </FinanceFiltersContextProvider>
              )}
            />
          )}
          {haveViewFinanceForecastPermission && (
            <Route
              path={ROUTES.CONSTRUCTING_FINANCE_FORECAST}
              render={() => (
                <FinanceFiltersContextProvider>
                  <Forecast>
                    <TabsComponent />
                  </Forecast>
                </FinanceFiltersContextProvider>
              )}
            />
          )}
          {!!ableTab && (
            <Route path={ROUTES.CONSTRUCTING_FINANCE}>
              <Redirect to={ableTab} />
            </Route>
          )}
          {!ableTab && <ForbiddenPage />}
        </Switch>
      </div>
    </TemplateSimple>
  );
};

export default Finance;
