import { AmountsType, AMOUNTS_VARIANTS } from "./components/BuildingSection/Amounts/Amounts";

import { MeasureType } from "features/financeMeasure";

import moment from "moment";
import { IBuildingSection } from "./model/types";

export type sharingBarExistingCheckerPropsType = {
  isShared: boolean;
  haveSharingPlanPermission: boolean;
  haveSharingProgressPermission: boolean;
  activeTab?: "plan" | "progress";
};

export const sharingBarExistingChecker = ({
  isShared,
  haveSharingPlanPermission,
  haveSharingProgressPermission,
  activeTab,
}: sharingBarExistingCheckerPropsType): boolean => {
  if (isShared) return false;
  if ((activeTab === "progress" || !activeTab) && haveSharingProgressPermission) return true;
  return false;
};

type addIntervalBtnExistingCheckerPropsType = {
  haveAddingPlanPermission: boolean;
  haveAddingProgressPermission: boolean;
  activeTab: "plan" | "progress";
  moduleRoute: string;
};

export const addIntervalChecker = ({
  activeTab,
  haveAddingPlanPermission,
  haveAddingProgressPermission,
  moduleRoute,
}: addIntervalBtnExistingCheckerPropsType): boolean => {
  if (activeTab === "plan" && haveAddingPlanPermission) return true;
  if (activeTab === "progress" && haveAddingProgressPermission && moduleRoute === "objects") return true;
  return false;
};

export const sectionAmounts = ({
  indicators,
  aggregation_data,
  isSegment,
  measure,
}: {
  indicators: IBuildingSection["indicators"];
  aggregation_data: IBuildingSection["aggregation_data"];
  isSegment: boolean;
  measure: MeasureType;
}): AmountsType => {
  if (isSegment && aggregation_data) {
    return {
      all: Number(aggregation_data.sum_estimate_amount),
      completed: Number(aggregation_data.sum_work_completed),
      accepted: Number(aggregation_data.sum_work_accepted),
      invested: Number(aggregation_data.sum_invested),
      completed_percent: Number(aggregation_data.completed_percent),
      variant: AMOUNTS_VARIANTS.INLINE,
      measure,
    };
  }
  if (!indicators) {
    return {
      all: 0,
      completed: 0,
      accepted: 0,
      invested: 0,
      completed_percent: 0,
      variant: AMOUNTS_VARIANTS.INLINE,
      measure,
    };
  }
  return {
    all: Number(indicators.estimate_amount),
    completed: Number(indicators.work_completed),
    accepted: Number(indicators.amount_accepted),
    invested: Number(indicators.invested),
    completed_percent: Number(indicators.completed_percent),
    variant: AMOUNTS_VARIANTS.INLINE,
    measure,
  };
};

export const makeDatesLimitFilter = ({ dateStart, dateEnd }: { dateStart: string, dateEnd: string }) => {
  const filterStartMoment = moment(dateStart);
  const filterEndMoment = moment(dateEnd);
  return (item: { start_at: string }) => moment(item.start_at).isSameOrAfter(filterStartMoment, 'day') && moment(item.start_at).isSameOrBefore(filterEndMoment, 'day')
}
