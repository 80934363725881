import React, { PropsWithChildren, createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { summaryYearSelector } from "pages/Finance/_TODO/summaryScrollReducer/selectors";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export enum FinanceViews {
  currency = "currency",
  percents = "percents",
}

const FinanceFiltersContext = createContext<FinanceFiltersContextType>({
  filter: {},
  setFilter: () => {},
  actualKey: "",
  financeView: FinanceViews.currency,
  setFinanceView: () => {},
});

export const FinanceFiltersContextProvider = ({ children }: PropsWithChildren) => {
  //TODO отделить summary и forecast
  const year = useSelector(summaryYearSelector);

  const [filter, setFilter] = useState<IFinanceCommonFilter>({});
  const [financeView, setFinanceView] = useState<FinanceViews>(FinanceViews.currency);

  const actualKey = generateStorageKey({ year, ...filter });

  return (
    <FinanceFiltersContext.Provider value={{ filter, setFilter, actualKey, financeView, setFinanceView }}>
      {children}
    </FinanceFiltersContext.Provider>
  );
};

export const useFinanceFiltersContext = () => useContext(FinanceFiltersContext);

export type IFinanceCommonFilter = {
  markup_id?: number;
  budget?: true;
  accepted?: true;
  completed?: true;
  todo?: true;
  to_pay?: true;
};

type FinanceFiltersContextType = {
  filter: IFinanceCommonFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFinanceCommonFilter>>;
  actualKey: string;
  financeView: FinanceViews;
  setFinanceView: React.Dispatch<React.SetStateAction<FinanceViews>>;
};
