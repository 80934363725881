import cn from "classnames";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { chartTreeSelector } from "redux/modules/common/chart/selectors";
import { IChartPlanWork, IChartTree } from "redux/modules/common/chart/types";
import { findChartTreeNode } from "redux/modules/common/chart/utils";

import { CHART_TREE_LVL } from "components/pages/Chart/constants";
import { filterIntervalsInRange } from "components/pages/Chart/utils/filterIntervalsInRange";
import { ManufacturingModalsTypesEnum } from "components/pages/Manufacturing/components/modals/const";
import { ManufacturingPlanModal } from "components/pages/Manufacturing/components/modals/ManufacturingPlanModal/ManufacturingPlanModal";
import ModalContentSplitter from "components/UI/atoms/ModalContentSplitter/ModalContentSplitter";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import modalStyles from "../../pages/Manufacturing/components/modals/ManufacturingModal/ManufacturingModal.module.scss";

import ButtonBack from "shared/ui/controls/ButtonBack/ButtonBack";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import InputBase, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import { SegmentWorkList } from "./SegmentWorkList";

import styles from "../ManufacturingPlanSectionModal/ManufacturingPlanSectionModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  intervaldata?: { id: number };
  objectId: string;
  treeUID: string;
  onEditCallback?: () => void;
  onAddToJournalCallback?: () => void;
  onBackButtonClick?: () => void;
}

type TreeExtendedPlanInterval = IChartTree & IChartPlanWork;

const ManufacturingPlanSegmentModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  objectId,
  treeUID,
  onEditCallback,
  onAddToJournalCallback,
}) => {
  const chartTree = useSelector(chartTreeSelector);
  const [activeTree, setActiveTree] = useState<IChartTree | null>(findChartTreeNode(treeUID, chartTree));
  const [activePlan, setActivePlan] = useState<TreeExtendedPlanInterval | null>(null);

  const innerChindren = useMemo(() => {
    if (!activeTree || !activeTree.children || activeTree.lvl > CHART_TREE_LVL.LSR) return [];
    const rangeFilterFn =
      activeTree.lvl < CHART_TREE_LVL.LSR || !activeTree.plan_start || !activeTree.plan_end
        ? () => true
        : filterIntervalsInRange({ start: activeTree.plan_start, end: activeTree.plan_end });
    return activeTree.children.filter(
      (child) => child.plan_start && child.plan_end && rangeFilterFn({ start: child.plan_start, end: child.plan_end })
    );
  }, [activeTree?.children, activeTree?.plan_start, activeTree?.plan_end, activeTree?.lvl]);

  const expenditureLvlPlans: TreeExtendedPlanInterval[] = useMemo(() => {
    if (
      !activeTree ||
      !activeTree.bubbledIntervals?.plan_works?.length ||
      !activeTree.plan_start ||
      !activeTree.plan_end ||
      activeTree.lvl <= CHART_TREE_LVL.LSR
    )
      return [];
    const rangeFilterFn = filterIntervalsInRange({ start: activeTree.plan_start, end: activeTree.plan_end });
    return activeTree.children.reduce((plans, child) => {
      const newIntervals: TreeExtendedPlanInterval[] | undefined = child?.processedIntervals?.plan_works.filter(rangeFilterFn).map(
        (plan) =>
          Object.assign(
            {},
            plan,
            child
          ) as TreeExtendedPlanInterval);
      if (!newIntervals) return plans;
      return plans.concat(newIntervals);
    }, [] as TreeExtendedPlanInterval[]).sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  }, [activeTree?.children, activeTree?.plan_start, activeTree?.plan_end, activeTree?.lvl]);

  if (activePlan) {
    return (
      <ManufacturingPlanModal
        isOpen
        onClose={() => setActivePlan(null)}
        dateStart={activePlan.start}
        dateEnd={activePlan.end}
        data={activePlan}
        expenditureId={(activePlan.expenditure?.exp_id || activePlan.group?.group_id) as number}
        sectionId={(activePlan[activePlan.type]?.cs_id || activePlan[activePlan.type]?.ps_id) as number}
        modalType={ManufacturingModalsTypesEnum.EXPENDITURE}
        onEditCallback={onEditCallback}
        onAddToJournalCallback={onAddToJournalCallback}
        isGroupPlan={Boolean(activePlan.isGroup)}
        projectId={objectId}
      />
    );
  }

  if (!activeTree) return null;

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} name={activeTree.name} isFixedHeadline>
      {activeTree.lvl <= CHART_TREE_LVL.SECTION && activeTree.lvl > CHART_TREE_LVL.PROJECT && (
        <ButtonBack onClick={() => setActiveTree(activeTree.parent)} className={modalStyles.back} />
      )}
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={activeTree.plan_start}
          defaultDateEnd={activeTree?.plan_end}
          disabled
          classNameSelect={styles.calendar}
        />
      </div>
      {activeTree.estimate_amount !== undefined && (
        <div className={cn(styles.pair, styles.centered)}>
          <b>Бюджет (по проекту):</b>
          <InputBase
            isButtonActive
            value={activeTree.estimate_amount}
            valueType={VALUE_TYPES.PRICE} /* @ts-ignore */
            numberTransformOptions={{ withCurrencySign: true }}
            disabled
          />
        </div>
      )}
      {innerChindren.length > 0 && (
        <div>
          {innerChindren.map(
            (
              child: IChartTree,
              index: number //@ts-ignore
            ) => {
              // const isGroup = !!el.group;
              return (
                <React.Fragment key={child._id}>
                  {(index === 0 || child.plan_start !== innerChindren[index - 1]?.plan_start) && (
                    <ModalContentSplitter label={moment(child.plan_start).format("DD.MM.YY")} />
                  )}
                  <SegmentWorkList
                    onClick={() => setActiveTree(child)}
                    name={child.name || ""}
                    start={child.plan_start || ""}
                    end={child.plan_end || ""}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
      {expenditureLvlPlans.length > 0 && (
        <div>
          {expenditureLvlPlans.map(
            (
              plan: TreeExtendedPlanInterval,
              index: number //@ts-ignore
            ) => {
              return (
                <React.Fragment key={`${plan.type}_${plan[plan.type]?.id}`}>
                  {(index === 0 || plan.start !== expenditureLvlPlans[index - 1]?.start) && (
                    <ModalContentSplitter label={moment(plan.start).format("DD.MM.YY")} />
                  )}
                  <SegmentWorkList
                    onClick={() => setActivePlan(plan)}
                    name={plan.name || ""}
                    start={plan.start}
                    end={plan.end}
                  />
                </React.Fragment>
              );
            }
          )}
        </div>
      )}
    </ModalContainer>
  );
};
export default ManufacturingPlanSegmentModal;
