import { useCallback, useState } from "react";

interface IProps {
  expenditureOrGroup: any;
  isExpendituresGroup: boolean;
}

export const useOverViewState = ({ expenditureOrGroup, isExpendituresGroup }: IProps) => {
  const [overviewState, setOverviewState] = useState({});

  const handleSetOverviewState = useCallback(() => {
    if (!expenditureOrGroup.indicators && expenditureOrGroup.estimate_amount) {
      setOverviewState({
        all: isExpendituresGroup ? expenditureOrGroup.amount : expenditureOrGroup.estimate_amount,
        completed: 0,
        accepted: 0,
        count_completed: 0,
        count_accepted: 0,
      });
    }
    if (!expenditureOrGroup.indicators) return;
    setOverviewState({
      all: expenditureOrGroup.indicators.estimate_amount,
      completed: expenditureOrGroup.indicators.work_completed,
      accepted: expenditureOrGroup.indicators.work_accepted,
      count_completed: expenditureOrGroup.indicators.count_completed,
      count_accepted: expenditureOrGroup.indicators.count_accepted,
    });
  }, [expenditureOrGroup.indicators, isExpendituresGroup]);

  return {
    handleSetOverviewState,
    overviewState,
  };
};
