import { createSelector, weakMapMemoize } from "reselect";

import { checkIsAttribute } from "./thunks";
import { RootState } from "app/store/rootReducer";

export const stateSelector = (state: RootState) => state.chart;

// scroll
export const chartScrollYearSelector = createSelector(stateSelector, (state) => state.scrollState.year);
export const chartScrollMonthSelector = createSelector(stateSelector, (state) => state.scrollState.month);
export const chartScrollTouchedYearsSelector = createSelector(stateSelector, (state) => state.scrollState.touchedYears);
export const chartScrollMarkersSelector = createSelector(stateSelector, (state) => state.scrollState.markers);

// relations
// @ts-ignore
export const chartArrowsSelector = createSelector(stateSelector, (state) => state.relations.arrows[state.tab]);
export const chartArrowsHashSelector = createSelector(stateSelector, (state) => state.relations.hash);
export const chartDraggedArrowSelector = createSelector(stateSelector, (state) => state.relations.draggedArrow);
export const chartJustAddedArrowSelector = createSelector(stateSelector, (state) => state.relations.justAddedArrow);

export const chartCheckpointsMarksSelector = createSelector(stateSelector, (state) => state.checkpoinsMarks);

// controls
export const chartTabSelector = createSelector(stateSelector, (state) => state.tab);
export const chartViewModeSelector = createSelector(stateSelector, (state) => state.viewMode);
export const chartActionsSelector = createSelector(stateSelector, (state) => state.actions);
export const chartStatusesSelector = createSelector(stateSelector, (state) => state.statuses.data);
// @ts-ignore
export const chartCurrentStatusesSelector = createSelector(stateSelector, (state) => state.statuses.data[state.tab]);
export const chartStatusesRLUColorsSelector = createSelector(stateSelector, (state) => state.statuses.rluColors);
export const chartLoadersSelector = createSelector(stateSelector, (state) => state.loaders);
export const chartHashSelector = createSelector(stateSelector, (state) => state.hash);
export const chartLoadedIntervalsByYearsSelector = createSelector(
  stateSelector,
  (state) => state.loadedIntervalsByYears
);

// tree
// @ts-ignore
export const chartTreeSelector = createSelector(stateSelector, (state) => state.trees[state.tab], {
  memoize: weakMapMemoize,
  argsMemoize: weakMapMemoize,
});
export const chartTreeFiltersSelector = createSelector(stateSelector, (state) => state.filters);
export const chartTreeDatesDistanceSelector = createSelector(
  stateSelector,
  // @ts-ignore
  (state) => state.trees[state.tab]?.datesDistance
);
export const chartIsAttributesSelector = createSelector(
  (state: RootState) => state,
  // @ts-ignore
  (state) => checkIsAttribute(state, chartTabSelector(state))
);
// @ts-ignore
export const chartTreeExpanderSelector = createSelector(stateSelector, (state) => state.treeState.expander[state.tab]);
export const chartTreeCachedOpenSelector = createSelector(
  stateSelector,
  // @ts-ignore
  (state) => state.treeState.cachedOpen[state.tab]
);
export const chartProjectSliceIndexSelector = createSelector(stateSelector, (state) => state.projectSliceIndex);
export const chartProjectIdsSelector = createSelector(stateSelector, (state) => state.projectIds);

export const chartModuleSelector = createSelector(stateSelector, (state) => state.module);
