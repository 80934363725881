import React from "react";

import useEstimatedPriceDoubleInput from "../../../../../../pages/Handler/ui/ProHandler/hooks/useEstimatedPriceDoubleInput";

import Amount from "../../../../../../shared/ui/dataDisplay/Amount/Amount";
import DoubleInput, { Measure } from "../../../../../../shared/ui/inputs/DoubleInput/DoubleInput";

import { beautifyNumberValue } from "../../../../../../utils/formatters/beautifyNumberValue";
import { sliceTextByConstraint } from "../../../../../../utils/formatters/sliceTextByConstraint";
import {
  parseTransformedNumber,
  transformDigitToFinancial,
} from "../../../../../../utils/formatters/transformDigitToFinancial";
import { stopEventPropagation } from "../../../../../../utils/helpers/stopEventPropagation";

import blackCheckIcon from "../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../images/icons/blueCheckIcon";

import styles from "../../EstimateSection.module.scss";

const PricingModeEstimatedPriceIndicators = ({ amounts, changeCost }) => {
  const { editableCost, onChangeCost } = useEstimatedPriceDoubleInput({
    cost: amounts.estimatedCost,
    count: 1,
    changeCost,
  });

  return (
    <>
      <Amount
        titleClassName={styles.budgetTitle}
        title="По смете"
        value={amounts.all}
        measure={amounts.measure}
        isBold
        isLeft
      />
      <div className={styles.discount} onClick={stopEventPropagation}>
        {/* b-960 */}
        <DoubleInput
          firstValue={editableCost}
          firstInputSize={1}
          firstValueMeasure={Measure.currency}
          onChangeFirstValue={onChangeCost}
          secondValueMeasure={Measure.currency}
          fontWeight={500}
          generalLabel="Расчетная цена"
          inputFontSize={+parseTransformedNumber(editableCost) > 1_000_000_000_000 ? 0.875 : 1}
          oneInputMode
        />
        {+parseTransformedNumber(editableCost) !== 0 ? (
          {
            ...blueCheckIcon,
            props: { ...blueCheckIcon.props, width: 18, height: 18 },
          }
        ) : (
          <span className={styles.withoutDiscountIcon} title="Скидка отсутствует">
            {{
              ...blackCheckIcon,
              props: { ...blackCheckIcon.props, width: 18, height: 18 },
            }}
          </span>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.budgetCol}>
        <Amount
          titleClassName={styles.budgetTitle}
          htmlTitle={transformDigitToFinancial(+amounts.all - +parseTransformedNumber(editableCost), {
            withFloat: true,
            dropZeros: true,
          })}
          title={"Разница"}
          value={sliceTextByConstraint(
            transformDigitToFinancial(+amounts.all - +parseTransformedNumber(editableCost), {
              withFloat: true,
              dropZeros: true,
            }),
            14
          )}
          measure={amounts.measure}
          isBold
          isLeft
        />
      </div>
    </>
  );
};

export default React.memo(PricingModeEstimatedPriceIndicators);
