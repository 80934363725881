import { IHandlerCoefficient } from "../model/types";

export const formatCoefficientValue = ({
  value,
  from_operation,
  including_items_costs,
  showing_in_percent,
}: Partial<IHandlerCoefficient>) => {
  if (!value) return value;
  if (from_operation === "add") {
    return "Сложение";
  }
  if (from_operation === "sub") {
    return "Вычитание";
  }
  const formattedNum = showing_in_percent ? `${parseFloat(value) * 100}%` : value;
  if (including_items_costs) {
    return `ЛСР + ${formattedNum}`;
  }
  return formattedNum;
};
