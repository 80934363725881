import cn from "classnames";
import React, { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";

import ImportModal from "./components/ImportModal/ImportModal";
import styles from "pages/Handler/ui/ProHandler/components/ImportExtraordinaryDocumentation/ImportDocumentation.module.scss";

import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Modal from "shared/ui/modal/Modal";

import { ReactComponent as ImportDocumentationIcon } from "../../../../../../shared/assets/icons/importDocumentation.svg";

interface IRouterParamsWithBuildingId {
  buildingId: string;
}

interface IProps {
  refreshSections: any;
  className?: string;
  renderBtn?: (onClick: () => void) => ReactNode;
}

const ImportDocumentation: React.FC<IProps> = ({ refreshSections, className, renderBtn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countOfPositions, setCountOfPositions] = useState(0);
  const [errList, setErrList] = useState({});
  const [isErr, setIsErr] = useState(false);

  const handleImportDocumentation: () => void = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <>
      {!renderBtn && (
        <button className={cn(styles.importButton, className)} onClick={handleImportDocumentation}>
          <span className={styles.text}>Импорт</span>
          <ImportDocumentationIcon />
        </button>
      )}
      {!!renderBtn && renderBtn(handleImportDocumentation)}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsSuccess(false);
          setCountOfPositions(0);
          setErrList({});
          setIsLoading(false);
          setIsErr(false);
        }}
        title={
          isLoading
            ? "Идет обработка файла"
            : isSuccess
            ? "Файл успешно загружен"
            : isErr
            ? "Ошибка обработки файла"
            : "Выберите файл для загрузки"
        }
        className={isErr ? styles.err : styles.modal}
        headerClassName={styles.title}
        closeOnClickOutside
        darkShadow
        children={
          isLoading ? (
            <Spinner />
          ) : (
            <ImportModal
              setIsLoading={setIsLoading}
              setIsSuccess={setIsSuccess}
              isSuccess={isSuccess}
              setIsModalOpen={setIsModalOpen}
              countOfPositions={countOfPositions}
              setCountOfPositions={setCountOfPositions}
              setErrList={setErrList}
              errList={errList}
              isErr={isErr}
              setIsErr={setIsErr}
              refreshSections={refreshSections}
            />
          )
        }
      />
    </>
  );
};

export default React.memo(ImportDocumentation);
