import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { PLAN } from "../constants";
import {
  VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN,
  VIEW_CONSTRUCTING_CREATE_SECTION_PLAN,
} from "constants/permissions/constructingPermissions";
import {
  VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN,
  VIEW_MANUFACTURING_CREATE_SECTION_PLAN,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";
import { useUrlModule } from "utils/hooks/useUrlModule";
import { IExpenditureTypesFiltersInBuilding } from "../types";

export const _TEMP_useBuildingPageUIConfig = ({
  setSelectedSection,
  canSharing,
  canGroup,
  activeTabId,
  filters
}: {
  setSelectedSection: any;
  canSharing: boolean;
  canGroup: boolean;
  activeTabId: any;
  filters: IExpenditureTypesFiltersInBuilding
}) => {
  const history = useHistory();
  const urlModule = useUrlModule();

  const handleBackClick = useCallback(() => {
    setSelectedSection(null);
    history.replace({ search: "" });
  }, []);

  const backPath = useMemo(() => (urlModule === "constructing" ? "/constructing/projects" : undefined), [urlModule]);

  const canCheck = canSharing || canGroup;

  const haveCreateSupersectionPlanConstructingPermission = usePermission(VIEW_CONSTRUCTING_CREATE_DIRECTIVE_PLAN);
  const haveCreateSectionPlanConstructingPermission = usePermission(VIEW_CONSTRUCTING_CREATE_SECTION_PLAN);
  const haveCreateSupersectionPlanManufacturingPermission = usePermission(VIEW_MANUFACTURING_CREATE_DIRECTIVE_PLAN);
  const haveCreateSectionPlanManufacturingPermission = usePermission(VIEW_MANUFACTURING_CREATE_SECTION_PLAN);

  const canAddSupersectionPlan =
    activeTabId === PLAN && !filters.attribute &&
    ((urlModule === "constructing" && haveCreateSupersectionPlanConstructingPermission) ||
      (urlModule === "objects" && haveCreateSupersectionPlanManufacturingPermission));

  const canAddSectionPlan =
    activeTabId === PLAN && !filters.attribute &&
    ((urlModule === "constructing" && haveCreateSectionPlanConstructingPermission) ||
      (urlModule === "objects" && haveCreateSectionPlanManufacturingPermission));

  return {
    handleBackClick,
    canCheck,
    backPath,
    canAddSupersectionPlan,
    canAddSectionPlan,
  };
};
