import cn from "classnames";
import moment from "moment";
import { useMemo } from "react";

import { INTERVAL_TYPES } from "../../../../../pages/Manufacturing/constants";

import { IEmployee } from "types/personsTypes";

import { daysPluralSuffix } from "utils/formatters/daysPluralSuffix";
import getShortFullName from "utils/formatters/getShortFullName";
import { splitThousands } from "utils/formatters/splitThousands";

import styles from "./WorkListItem.module.scss";

const calendarIcon = (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3.82422H17V14.8242C17 15.6199 16.6839 16.3829 16.1213 16.9455C15.5587 17.5081 14.7956 17.8242 14 17.8242H3C2.20435 17.8242 1.44129 17.5081 0.87868 16.9455C0.31607 16.3829 0 15.6199 0 14.8242L0 3.82422Z"
      fill="white"
    />
    <path
      d="M0.5 4.32422H16.5V14.8242C16.5 15.4873 16.2366 16.1231 15.7678 16.592C15.2989 17.0608 14.663 17.3242 14 17.3242H3C2.6717 17.3242 2.34661 17.2596 2.04329 17.1339C1.73998 17.0083 1.46438 16.8241 1.23223 16.592C0.763392 16.1231 0.5 15.4873 0.5 14.8242V4.32422Z"
      stroke="#BABABA"
    />
    <path
      d="M4 7.32422H3C2.72386 7.32422 2.5 7.54808 2.5 7.82422V8.82422C2.5 9.10036 2.72386 9.32422 3 9.32422H4C4.27614 9.32422 4.5 9.10036 4.5 8.82422V7.82422C4.5 7.54808 4.27614 7.32422 4 7.32422Z"
      stroke="#BABABA"
      strokeDasharray="1 1"
    />
    <path
      d="M9 6.82422H8C7.44772 6.82422 7 7.27193 7 7.82422V8.82422C7 9.3765 7.44772 9.82422 8 9.82422H9C9.55228 9.82422 10 9.3765 10 8.82422V7.82422C10 7.27193 9.55228 6.82422 9 6.82422Z"
      fill="white"
    />
    <path
      d="M9 7.32422H8C7.72386 7.32422 7.5 7.54808 7.5 7.82422V8.82422C7.5 9.10036 7.72386 9.32422 8 9.32422H9C9.27614 9.32422 9.5 9.10036 9.5 8.82422V7.82422C9.5 7.54808 9.27614 7.32422 9 7.32422Z"
      stroke="#BABABA"
    />
    <path
      d="M14 6.82422H13C12.4477 6.82422 12 7.27193 12 7.82422V8.82422C12 9.3765 12.4477 9.82422 13 9.82422H14C14.5523 9.82422 15 9.3765 15 8.82422V7.82422C15 7.27193 14.5523 6.82422 14 6.82422Z"
      fill="white"
    />
    <path
      d="M14 7.32422H13C12.7239 7.32422 12.5 7.54808 12.5 7.82422V8.82422C12.5 9.10036 12.7239 9.32422 13 9.32422H14C14.2761 9.32422 14.5 9.10036 14.5 8.82422V7.82422C14.5 7.54808 14.2761 7.32422 14 7.32422Z"
      stroke="#BABABA"
    />
    <path
      d="M9 11.8242H8C7.44772 11.8242 7 12.2719 7 12.8242V13.8242C7 14.3765 7.44772 14.8242 8 14.8242H9C9.55228 14.8242 10 14.3765 10 13.8242V12.8242C10 12.2719 9.55228 11.8242 9 11.8242Z"
      fill="white"
    />
    <path
      d="M9 12.3242H8C7.72386 12.3242 7.5 12.5481 7.5 12.8242V13.8242C7.5 14.1004 7.72386 14.3242 8 14.3242H9C9.27614 14.3242 9.5 14.1004 9.5 13.8242V12.8242C9.5 12.5481 9.27614 12.3242 9 12.3242Z"
      stroke="#BABABA"
    />
    <path
      d="M4 12.3242H3C2.72386 12.3242 2.5 12.5481 2.5 12.8242V13.8242C2.5 14.1004 2.72386 14.3242 3 14.3242H4C4.27614 14.3242 4.5 14.1004 4.5 13.8242V12.8242C4.5 12.5481 4.27614 12.3242 4 12.3242Z"
      stroke="#BABABA"
      strokeDasharray="1 1"
    />
    <path
      d="M16.4992 4.5187H0.5V3.7497C0.5 2.40467 1.56779 1.30436 2.9002 1.25195V2.0361C2.9002 3.015 3.69619 3.8114 4.6746 3.8114C5.6535 3.8114 6.4499 3.015 6.4499 2.0361V1.25H10.5493V2.0361C10.5493 3.015 11.3457 3.8114 12.3246 3.8114C13.3035 3.8114 14.0999 3.015 14.0999 2.0361V1.25199C15.4318 1.3049 16.4992 2.40501 16.4992 3.7497V4.5187Z"
      fill="#BABABB"
    />
    <path
      d="M15.9992 4.0187V3.7497C15.9992 2.85616 15.4101 2.09762 14.5999 1.84206V2.0361C14.5999 2.88562 14.1318 3.62793 13.4401 4.0187H15.9992ZM11.2091 4.0187C10.5174 3.62793 10.0493 2.88562 10.0493 2.0361V1.75H6.9499V2.0361C6.9499 2.88562 6.48184 3.62793 5.79014 4.0187H11.2091ZM3.5595 4.0187C2.86807 3.62793 2.4002 2.88562 2.4002 2.0361V1.84178C1.58952 2.09705 1 2.85582 1 3.7497V4.0187H3.5595ZM16.9992 5.0187H0V3.7497C0 2.09566 1.34566 0.75 2.9997 0.75H3.4002V2.0361C3.4002 2.7393 3.97189 3.3114 4.6746 3.3114C5.3778 3.3114 5.9499 2.7393 5.9499 2.0361V0.75H11.0493V2.0361C11.0493 2.7393 11.6214 3.3114 12.3246 3.3114C13.0278 3.3114 13.5999 2.7393 13.5999 2.0361V0.75H13.9995C15.6535 0.75 16.9992 2.09566 16.9992 3.7497V5.0187Z"
      fill="#BABABA"
    />
  </svg>
);

interface JournalListItemProps {
  name?: string;
  measure?: string;
  passDate?: string;
  acceptDate?: string;
  passName?: string | IEmployee;
  acceptName?: string;
  onClick: () => void;
  passCount?: string | number;
  acceptCount?: string | number;
  passAmount?: string | number;
  acceptAmount: string | number;
  hideAccepted?: boolean;
  type?: INTERVAL_TYPES;
  modalType?: string;
  startAt?: string;
  endAt?: string;
}

function JournalListItem({
  name,
  measure,
  passDate,
  acceptDate,
  passName,
  acceptName,
  onClick,
  passCount,
  acceptCount,
  passAmount,
  acceptAmount,
  hideAccepted,
  type = INTERVAL_TYPES.work,
  modalType,
  startAt,
  endAt,
}: JournalListItemProps) {
  const passLabel = type === INTERVAL_TYPES.plan ? "Автор:" : "Сдал:";
  const isLSR = modalType === "lsr";

  const duration = useMemo(() => {
    if (!startAt || !endAt) return null;
    const startMoment = moment(startAt);
    const endMoment = moment(endAt);
    const diff = endMoment.diff(startMoment, "d") + 1;
    const datesFormatted = `${startMoment.format("DD/MM/YYYY")} - ${endMoment.format("DD/MM/YYYY")}`;
    return (
      <div className={styles.duration}>
        <span className={styles.label}>Продолжительность:</span>
        <span>
          {datesFormatted} ({diff} {daysPluralSuffix(diff)})
        </span>
      </div>
    );
  }, [startAt, endAt]);

  return (
    <div className={styles.container} onClick={onClick}>
      <p className={styles.name}>{name}</p>
      <div className={styles.grid}>
        {duration}
        <div className={cn(styles.pair, styles.jcsb)}>
          <div className={styles.date}>{passDate ? moment(passDate).format("DD/MM/YYYY") : "--/--/----"}</div>
          {calendarIcon}
          <div className={styles.time}>в {passDate ? moment(passDate).format("HH:mm") : "--:--"}</div>
        </div>
        <div className={styles.pair}>
          <p className={styles.label}>{passLabel}</p>
          <b className={styles.person}>{typeof passName !== "string" ? getShortFullName(passName) : passName}</b>
        </div>

        {isLSR ? (
          <div className={styles.pair} />
        ) : (
          <div className={styles.countPair}>
            <span className={styles.label}>Кол-во:</span>
            <span className={styles.count}>{passCount}</span>
            <span className={styles.measure}>({measure})</span>
          </div>
        )}
        <div className={styles.pair}>
          <p className={styles.label}>{isLSR ? "Бюджет:" : "Стоимость:"}</p>
          <b>{splitThousands(passAmount)}</b>
        </div>
      </div>
      {!hideAccepted && <div className={styles.hr}></div>}
      {!hideAccepted && (
        <div className={styles.grid}>
          <div className={cn(styles.pair, styles.jcsb)}>
            <div className={styles.date}>{acceptDate ? moment(acceptDate).format("DD/MM/YYYY") : "--/--/----"}</div>
            {calendarIcon}
            <div className={styles.time}>в {acceptDate ? moment(acceptDate).format("HH:mm") : "--:--"}</div>
          </div>
          <div className={styles.pair}>
            <p className={styles.label}>Принял:</p>
            <b className={styles.person}>
              {typeof acceptName === "string" ? acceptName : getShortFullName(acceptName)}
            </b>
          </div>
          <div className={styles.countPair}>
            <span className={styles.label}>Кол-во:</span>
            <span className={styles.count}>{acceptCount}</span>
            <span className={styles.measure}>({measure})</span>
          </div>
          <div className={styles.pair}>
            <p className={styles.label}>Стоимость:</p>
            <b>{splitThousands(acceptAmount)}</b>
          </div>
        </div>
      )}
      {/* <svg
        width="8"
        height="16"
        viewBox="0 0 8 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.arrow}
      >
        <path d="M0.580078 0.226562L7.98907 7.63556L0.580078 15.0446" fill="#969696" />
      </svg> */}
    </div>
  );
}

export default JournalListItem;
