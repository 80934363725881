import { message } from "antd";
import { Dispatch } from "redux";

import { extraForecastActions } from "pages/Finance/Forecast/widgets/AddExtraExpenseModal/model/extraForecastSlice";
import { summaryUtils } from "pages/Finance/Summary/model/utils";
import { IFinanceCommonFilter } from "pages/Finance/common/contexts/FinanceFiltersContext/FinanceFiltersContext";
import { generateFinanceKey } from "pages/Finance/common/model/commonFinanceUtils";
import { FinanceTreeItemsTypes, IFinanceTreeItem } from "pages/Finance/common/model/interfaces";

import { forecastApi } from "./api";
import { forecastActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IIdAndName } from "types/idAndName";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getForecastList = (year: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const key = generateStorageKey({ type: "all", year });
  if (getState().financeReducer.forecast.loadings[key] === undefined) {
    dispatch(forecastActions.setLoading({ key, status: true }));
  }
  forecastApi
    .getList()
    .then(({ data }) => {
      dispatch(forecastActions.setProjects({ data, year }));
    })
    .finally(() => {
      dispatch(forecastActions.setLoading({ key, status: false }));
    });
};

export const getForecastProject =
  (projectId: string, year: number, filter?: IFinanceCommonFilter) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ projectId, type: "project", year });
    const wasntInCommonList = !getState().financeReducer.forecast.dataTree[year]?.find((el) => el.id === +projectId);
    if (wasntInCommonList) {
      dispatch(forecastActions.setLoading({ key, status: true }));
    }
    forecastApi
      .getProject(projectId, year, filter)
      .then(({ data }) => {
        dispatch(forecastActions.setProject({ data, projectId: +projectId, year }));
        if (data[0]?.buildings?.length === 1) {
          const child = summaryUtils.findNode(getState().financeReducer.forecast.dataTree[year], {
            id: data[0]?.buildings[0].id,
            type: "object",
            key: generateFinanceKey(data[0]?.buildings[0].id, "object"),
          });
          if (child) {
            dispatch(toggleForecastTree(child, year) as any);
          }
        }
      })
      .finally(() => {
        dispatch(forecastActions.setLoading({ key, status: false }));
      });
  };

export const getForecastObject =
  (projectId: string, objectId: string, year: number, filter?: IFinanceCommonFilter) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const actualKey = generateStorageKey({ year, ...filter });
    const key = generateStorageKey({ buildingId: objectId, type: "object", year });
    const wasntInCommonList = !getState()
      .financeReducer.forecast.dataTree[year]?.find((el) => el.id === +projectId)
      ?.children?.find((el) => el.id === +objectId)?.children?.length;
    if (wasntInCommonList) {
      dispatch(forecastActions.setLoading({ key, status: true }));
    }
    forecastApi
      .getObject(+objectId, year, filter)
      .then(({ data }) => {
        dispatch(forecastActions.setObject({ data, projectId: +projectId, objectId: +objectId, year }));
        //@ts-expect-error data type
        dispatch(extraForecastActions.setExtraExpense({ key: String(objectId), data: data.extra_costs }));
      })
      .finally(() => {
        dispatch(forecastActions.setLoading({ key, status: false }));
      });
  };

export const toggleForecastTree =
  (subTree: IFinanceTreeItem, year: number) => (dispatch: Dispatch, getState: () => RootState) => {
    if (subTree.isOpen) {
      dispatch(forecastActions.closeItem({ year, item: subTree }));
    } else {
      dispatch(forecastActions.openItem({ year, item: subTree }));
      if (subTree.type === "object" && subTree.shouldBeLoaded) {
        const tree = getState().financeReducer.forecast.dataTree[year] ?? [];
        const parent = tree.find((project) => project.children?.some((obj) => obj.id === subTree.id));
        dispatch(getForecastObject(String(parent?.id), String(subTree.id), year) as any);
      }
    }
  };

export const refetchForecast =
  (
    projectId: string,
    objectId: string,
    type: Extract<FinanceTreeItemsTypes, "extraRoot" | "warranty" | "advance">,
    year: number
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (type === "extraRoot") {
      dispatch(forecastActions.setLoading({ key: generateStorageKey({ type, buildingId: objectId }), status: true }));
    } else {
      dispatch(
        forecastActions.setLoading({
          key: generateStorageKey({ type: "warranty", buildingId: objectId }),
          status: true,
        })
      );
      dispatch(
        forecastActions.setLoading({ key: generateStorageKey({ type: "advance", buildingId: objectId }), status: true })
      );
    }

    forecastApi
      .getObject(+objectId)
      .then(({ data }) => {
        dispatch(forecastActions.setAnyExtras({ data, projectId: +projectId, objectId: +objectId, year }));
      })
      .finally(() => {
        dispatch(
          forecastActions.setLoading({ key: generateStorageKey({ type, buildingId: objectId }), status: false })
        );
        dispatch(
          forecastActions.setLoading({
            key: generateStorageKey({ type: "warranty", buildingId: objectId }),
            status: false,
          })
        );
        dispatch(
          forecastActions.setLoading({
            key: generateStorageKey({ type: "advance", buildingId: objectId }),
            status: false,
          })
        );
      });
  };

export const downloadForecastBdr =
  (buildingId: number, dateStart: string, dateEnd: string) => (dispatch: Dispatch, getState: () => RootState) => {
    let reportName = `БДР ${dateStart}-${dateEnd}.xlsx`;
    if (buildingId) {
      const buildingName = getState().objects.objectsBreadcrumbs?.results?.find(
        (el: IIdAndName) => el.id == buildingId
      )?.name;
      if (buildingName) {
        reportName = `БДР по ${buildingName} ${dateStart}-${dateEnd}.xlsx`;
      }
    }

    let buildingIds: number[];
    if (buildingId) {
      buildingIds = [buildingId];
    } else {
      buildingIds = getState().objects.objectsBreadcrumbs?.results?.map((el: IIdAndName) => el.id);
    }

    return forecastApi.downloadBDR(buildingIds, dateStart, dateEnd, reportName);
  };

export const downloadForecastReport = (buildigId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const buildingName = getState().objects.objectsBreadcrumbs?.results?.find(
    (el: IIdAndName) => el.id == buildigId
  )?.name;
  const reportName = buildingName ? `Прогноз по ${buildingName}.xlsx` : "Прогноз.xlsx";
  forecastApi.downloadReport(buildigId, reportName);
};
