import { FC } from "react";

import { IEstimateTarget } from "components/UI/_TODO/Expenditure/components/AddRelationsModal/AddRelationModalSections/AddRelationModalSections";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import { InputSearchRound } from "shared/ui/inputs/InputSearchRound/InputSearchRound";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import SpecificationSplitModal from "../SpecificationSplitModal/SpecificationSplitModal";
import SpecificationRelationsSections from "./SpecificationRelationsSections/SpecificationRelationsSections";

import { IProduct } from "types/interfaces/Product";

import { useSpecificationRelationsData } from "./hooks/useSpecificationRelationsData";
import { useSpecificationRelationsSelectTarget } from "./hooks/useSpecificationRelationsSelectTarget";
import { useSpecificationRelationsSubmit } from "./hooks/useSpecificationRelationsSubmit";
import { useSpecificationsSearch } from "./hooks/useSpecificationsSearch";

import styles from "./SpecificationAddRelationsModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  itemId: number;
  subSectionId: number;
  successCallback?: (data: IProduct) => void;
}

export interface IEstimateTargetWithType
  extends IEstimateTarget<{
    count: string;
    measure: string;
    name: string;
    segment?: { name: string; parent?: { name: string } };
  }> {}

const SpecificationAddRelationsModal: FC<IProps> = ({ isOpen, onClose, itemId, subSectionId, successCallback }) => {
  const { actualData, isLoading, tab, tabs, setTab } = useSpecificationRelationsData({ isOpen });

  const { debouncedOnChangeSearchInput, searchedSections, search } = useSpecificationsSearch(actualData);

  const { selectedEstimateTarget, selectedRdTarget, onSelectExpenditure } = useSpecificationRelationsSelectTarget({
    tab,
  });

  const { setIsSplitting, isPending, isSplitting, handleSubmit } = useSpecificationRelationsSubmit({
    itemId,
    selectedEstimateTarget,
    subSectionId,
    tab,
    onClose,
    successCallback,
  });

  const submitDisabled = !selectedEstimateTarget && !selectedRdTarget.length;

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={onClose} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleBlock}>
              <div className={styles.title}>Связь</div>
            </div>
            <div className={styles.searchBlock}>
              <InputSearchRound onChange={debouncedOnChangeSearchInput} className={styles.inputClassName} />
              <ButtonBase
                isLoading={isPending}
                className={styles.btn}
                primary
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                Связать
              </ButtonBase>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            {!isLoading && (
              <div className={styles.tabs}>
                <TabBarNotLinks tabs={tabs} activeId={tab} onClick={setTab} isOnlyTabsView />
              </div>
            )}
            <div className={styles.content}>
              {!isLoading ? (
                <SpecificationRelationsSections
                  selectedEstimateTarget={selectedEstimateTarget}
                  sections={searchedSections}
                  opened={!!search.length}
                  onCheckExpenditure={onSelectExpenditure}
                  selectedRdTarget={selectedRdTarget}
                  tab={tab}
                />
              ) : (
                <Spinner isStatic />
              )}
            </div>
          </div>
          <BottomControls isEmpty isExists />
        </div>
      </SliderModal>
      <SpecificationSplitModal
        isOpen={isSplitting}
        onClose={() => setIsSplitting(false)}
        expenditureId={selectedEstimateTarget?.expenditureId}
        itemId={itemId}
        selectedRdTarget={selectedRdTarget}
      />
    </>
  );
};

export default SpecificationAddRelationsModal;
