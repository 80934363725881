import { useEffect, useState } from "react";

import { usePriceSelectContext } from "features/MultiplePrices/contexts/PriceSelectContext";

import { EstimateStateId } from "../types";
import { PricingModes } from "features/MultiplePrices/utils/constants";

const usePricingMode = ({ activeEstimateStateId }: { activeEstimateStateId: EstimateStateId }) => {
  const { activePricingMode, setActivePricingMode } = usePriceSelectContext();

  useEffect(() => {
    setActivePricingMode(PricingModes.view);
  }, [activeEstimateStateId]);

  return { activePricingMode, setActivePricingMode };
};

export default usePricingMode;
