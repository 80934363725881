import { message } from "antd";
import cn from "classnames";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Select, { IOption } from "components/UI/atoms/Select";

import { addPrice, deletePrice, editPrice } from "../model/thunks";
import RenderMultiplePriceOption from "./RenderMultiplePriceOption";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase from "shared/ui/inputs/InputBase";

import { PricingModes } from "../utils/constants";

import { useGetMultiplePrices } from "../hooks/useGetMultiplePrices";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./MultiplePricesSelect.module.scss";

interface IProps {
  activePricingMode: any;
  setActivePricingMode: any;
  objectId: string;
}

const MultiplePricesSelect = ({ activePricingMode, setActivePricingMode, objectId }: IProps) => {
  const dispatch = useDispatch();

  const { multiplePricesLoadings, multiplePricesOptions } = useGetMultiplePrices(objectId);

  const [isAdding, setIsAdding] = useState(false);
  const [newName, setNewName] = useState("");

  const onAdd = () => {
    if (!checkIsValid(newName)) return;
    dispatch(addPrice(objectId, newName));
    setIsAdding(false);
    setNewName("");
  };

  const [editingOption, setEditingOption] = useState<IOption | null>(null);

  const onEdit = () => {
    if (!editingOption) return;
    if (!checkIsValid(editingOption?.name as string)) return;
    dispatch(editPrice(objectId, editingOption.name as string, editingOption.id as number));
    setEditingOption(null);
  };

  const onSelect = (priceId: any, priceName: any) => {
    setActivePricingMode(priceId);
  };

  const displayingValue = useMemo(() => {
    if ([PricingModes.budget, /* PricingModes.estimatedPrice, */ PricingModes.view].includes(activePricingMode)) {
      return activePricingMode;
    }
    const candidate = multiplePricesOptions?.find((el) => el.id === activePricingMode);
    if (candidate) return candidate.id;
    return PricingModes.view;
  }, [activePricingMode, multiplePricesOptions]);

  return (
    <Select
      className={styles.estimateStateSelect}
      onChange={onSelect}
      value={displayingValue}
      options={multiplePricesOptions}
      isLoading={multiplePricesLoadings[objectId]}
      endDecorator={
        isAdding && (
          <div className={cn(styles.inputWrapper, styles.adding)} onClick={stopEventPropagation}>
            <InputBase value={newName} onChange={({ target }) => setNewName(target.value)} />
            <Actions color="blue" canSave canRemove onRemoveDirectly={() => setIsAdding(false)} onSave={onAdd} />
          </div>
        )
      }
      renderOption={(option, onClick) => (
        <RenderMultiplePriceOption
          activePricingMode={activePricingMode}
          option={option}
          onClick={onClick}
          multiplePricesLoadings={multiplePricesLoadings}
          editingOption={editingOption!}
          onEdit={onEdit}
          setEditingOption={setEditingOption}
          setIsAdding={setIsAdding}
          isAdding={isAdding}
          objectId={objectId}
          multiplePricesOptions={multiplePricesOptions}
        />
      )}
    />
  );
};

export default MultiplePricesSelect;

function checkIsValid(name?: string) {
  if (!name?.length) {
    message.error("Введите наименование");
    return false;
  }
  if (name.length > 15) {
    message.error("Наименование не должно превышать 15 символов");
    return false;
  }
  return true;
}
