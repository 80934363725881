import cn from "classnames";
import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { loadAggregations } from "../../../../../../../../../Aggregations/model/thunks";
import { simpleHandlerActions } from "../../../../../../../../model/slice";
import { reloadActiveSubSection, setDiscountCost } from "../../../../../../../../model/thunks";
import DoubleInput, { Measure } from "shared/ui/inputs/DoubleInput/DoubleInput";

import { IExpenditure } from "../../../../../../../../../../../../types/interfaces/Expenditure";

import useBudgetDoubleInput from "../../../../../../../../../ProHandler/hooks/useBudgetDoubleInput";

import { beautifyNumberValue } from "../../../../../../../../../../../../utils/formatters/beautifyNumberValue";
import { transformDigitToFinancial } from "../../../../../../../../../../../../utils/formatters/transformDigitToFinancial";

import blackCheckIcon from "../../../../../../../../../../../../images/icons/blackCheckIcon";
import blueCheckIcon from "../../../../../../../../../../../../images/icons/blueCheckIcon";

import styles from "../../Expenditure.module.scss";

interface IProps {
  expenditure: IExpenditure;
}

export const BudgetColumns = memo<IProps>(({ expenditure }) => {
  const dispatch = useDispatch();

  const amountSource = expenditure.estimate_amount_source_before_coefficient ?? expenditure.indicators.estimate_amount_source;

  const changeDiscountCost = useCallback(
    (v: string) => {
      dispatch(
        setDiscountCost(expenditure.id, +v, (data) => {
          dispatch(simpleHandlerActions.setExpenditureDiscount(data));
          dispatch(reloadActiveSubSection());
          dispatch(loadAggregations());
        })
      );
    },
    [dispatch, expenditure]
  );

  const { changedDiscountCost, changedDiscountPercent, onChangeDiscountPercent, onChangeDiscountCost } =
    useBudgetDoubleInput({
      amountSource,
      discountPercent: expenditure.discount_percent.toString(),
      discountCost: expenditure.discount_cost.toString(),
      changeDiscountCost,
    });

  return (
    <>
      <div className={cn(styles.column, styles.isCentered)}>
        {expenditure.count} ({expenditure.measure})
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        {transformDigitToFinancial(amountSource)}
        <br />({transformDigitToFinancial(+amountSource / +expenditure.count)} за ед.)
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        <div className={styles.discount}>
          <DoubleInput
            firstValue={(changedDiscountPercent ?? 0).toString()}
            secondValue={(changedDiscountCost ?? 0).toString()}
            firstInputSize={1}
            secondInputSize={2}
            onChangeFirstValue={onChangeDiscountPercent}
            onChangeSecondValue={onChangeDiscountCost}
            firstValueMeasure={Measure.percent}
            secondValueMeasure={Measure.currency}
          />
          {+beautifyNumberValue(changedDiscountCost) !== 0 ? (
            {
              ...blueCheckIcon,
              props: { ...blueCheckIcon.props, width: 18, height: 18 },
            }
          ) : (
            <span className={styles.withoutDiscountIcon} title="Скидка отсутствует">
              {{
                ...blackCheckIcon,
                props: { ...blackCheckIcon.props, width: 18, height: 18 },
              }}
            </span>
          )}
        </div>
      </div>
      <div className={cn(styles.column, styles.isCentered)}>
        {transformDigitToFinancial(+amountSource - +beautifyNumberValue(changedDiscountCost))}
        <br />(
        {transformDigitToFinancial((+amountSource - +beautifyNumberValue(changedDiscountCost)) / +expenditure.count)} за
        ед.)
      </div>
    </>
  );
});

BudgetColumns.displayName = "BudgetColumns";
