import { message } from "antd";
import { Dispatch } from "redux";

import { RootState } from "app/store/rootReducer";
import { loadAggregations } from "../../../../Aggregations/model/thunks";
import { handlerCoefficientsActions } from "./actions";
import { coefficientsApi } from "./api";

import { ICreateCoefficient, ICreateOperation } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createHandlerCoefficient = (buildingId: string, vals: ICreateCoefficient) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ type: "pending", buildingId });
  dispatch(handlerCoefficientsActions.setLoading(key, true));
  coefficientsApi
    .createCoefficient(buildingId, vals)
    .then(() => {
      dispatch(handlerCoefficientsActions.invalidateKey());
      dispatch(loadAggregations(buildingId) as any);
      message.success("Коэффициент успешно добавлен");
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(handlerCoefficientsActions.setLoading(key, false));
    });
};

export const getHandlerCoefficients =
  (buildingId: string, sectionId: number) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = String(sectionId);
    if (!getState().handlerCoefficientsReducer.coefficients[sectionId]) {
      dispatch(handlerCoefficientsActions.setLoading(key, true));
    }
    coefficientsApi
      .getcoefficients(buildingId, sectionId)
      .then(({ data }) => {
        dispatch(
          handlerCoefficientsActions.setcoefficients(
            sectionId,
            data.results?.sort((a, b) => a.number - b.number)
          )
        );
      })
      .finally(() => {
        dispatch(handlerCoefficientsActions.setLoading(key, false));
      });
  };

export const editHandlerCoefficient =
  (buildingId: string, indexId: number, vals: Partial<ICreateCoefficient>) => (dispatch: Dispatch) => {
    const key = generateStorageKey({ type: "pending", buildingId });
    dispatch(handlerCoefficientsActions.setLoading(key, true));
    coefficientsApi
      .editCoefficient(buildingId, indexId, vals)
      .then(() => {
        dispatch(handlerCoefficientsActions.invalidateKey());
        message.success("Коэффициент успешно сохранен");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(handlerCoefficientsActions.setLoading(key, false));
      });
  };

export const deleteHandlerCoefficient = (buildingId: string, indexId: number) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ type: "pending", buildingId });
  dispatch(handlerCoefficientsActions.setLoading(key, true));
  coefficientsApi
    .deleteCoefficient(buildingId, indexId)
    .then(() => {
      dispatch(handlerCoefficientsActions.invalidateKey());
      dispatch(loadAggregations(buildingId) as any);
      message.success("Коэффициент успешно удален");
    })
    .catch((e) => {
      if (e?.response?.status == 404) {
        message.error("Нельзя удалить коэффициент, если были произведены работы по смете");
      }
    })
    .finally(() => {
      dispatch(handlerCoefficientsActions.setLoading(key, false));
    });
};

export const createOperationCoefficient = (buildingId: string, vals: ICreateOperation) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ type: "pending", buildingId });
  dispatch(handlerCoefficientsActions.setLoading(key, true));
  coefficientsApi
    .createOperation(buildingId, vals)
    .then(() => {
      dispatch(handlerCoefficientsActions.invalidateKey());
      dispatch(loadAggregations(buildingId) as any);
      message.success("Операция выполнена успешно");
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch(handlerCoefficientsActions.setLoading(key, false));
    });
};
