import React, { useRef, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { addSpecificationMultipleExpenditures } from "redux/modules/common/building/materials/specification";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import MaterialsCreateExpenditureForm from "./MaterialsCreateExpenditureForm";
import { useTypedSelector } from "app/store/typedUseSelector";
import AddButton from "shared/ui/controls/AddButton/AddButton";
import ButtonBase from "shared/ui/controls/ButtonBase";
import Modal from "shared/ui/modal/Modal";

import { ADDITIONAL } from "../../constants";

import TwoHorizontalSmallLinesIcon from "images/icons/TwoHorizontalSmallLinesIcon";

import styles from "./MaterialsCreateExpenditure.module.scss";

const MaterialsCreateExpenditure = () => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const [isOpenModalAddExpenditures, setIsOpenModalAddExpenditures] = useState(false);
  const { tab: activeTabId, subSectionId: activeSectionId } = useParams<any>();
  const isPending = useTypedSelector((state: any) => state.specification.loadings)["addExps"];

  const submitAddPositionsForm = (values: { items: any[] }) => {
    dispatch(
      addSpecificationMultipleExpenditures(
        objectId,
        values.items.map((el) => ({ ...el, section_id: activeSectionId, ret_own_mat: false })),
        () => {
          setIsOpenModalAddExpenditures(false);
        }
      )
    );
  };

  const formRef = useRef<HTMLFormElement>(null);

  const onDelete = (index: number) => {};

  return (
    <>
      <AddButton className={styles.add} onClick={() => setIsOpenModalAddExpenditures(true)} text="Добавить позицию" />
      <Modal
        isOpen={isOpenModalAddExpenditures}
        onClose={() => setIsOpenModalAddExpenditures(false)}
        title="Добавить позицию"
        className={styles.modal}
      >
        <Form
          keepDirtyOnReinitialize
          onSubmit={submitAddPositionsForm}
          initialValues={{ items: [{}] }}
          render={({ values, handleSubmit, form }) => {
            return (
              <form
                ref={formRef}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                onReset={() => form.restart()}
              >
                <div className={styles.list}>
                  {values.items.map((el: any, i: number) => (
                    <MaterialsCreateExpenditureForm
                      values={values}
                      key={el}
                      index={i}
                      onDelete={onDelete}
                      form={form as any}
                      isPending={isPending}
                    />
                  ))}
                  <AddButton
                    isWide
                    text="Добавить ресурс"
                    onClick={() => {
                      const newValues = form.getState().values.items;
                      form.change("items", [...newValues, {}]);
                    }}
                  />
                </div>
                <div className={styles.bottom}>
                  <ButtonBase primary type="submit" isLoading={isPending}>
                    Сохранить
                  </ButtonBase>
                </div>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default MaterialsCreateExpenditure;
