import moment from "moment";
import { Dispatch } from "redux";

import { chartActions } from "./actions";

import {
  ChartProcessedInterval,
  ChartTreeMatcherType, CHART_TABS, IChartPlanSection,
  IChartTree,
  INTERVAL_MAPPING_TYPES
} from "./types";

const scheduler = (() => {
  if ("scheduler" in global) {
    // @ts-ignore
    return global.scheduler;
  }
  if ("requestIdleCallback" in window) {
    return {
      postTask: (fn: () => void) => window.requestIdleCallback(fn),
    };
  }
  return {
    postTask: (fn: () => void) => fn(),
  };
})();

const mappingTaskOptions = {
  priority: "user-visible",
};

export enum MATCH_STRATEGY {
  ALL,
  SECTIONS_ONLY,
}

export interface IScheduleIntervalMapping {
  data: ChartProcessedInterval[];
  dispatch: Dispatch;
  tab: CHART_TABS;
  type: INTERVAL_MAPPING_TYPES;
  signal: AbortSignal;
  projectId: number;
}

const expenditureAndGroupsUpdateMatcher: MakeUpdateMatcher =
  ({ intervalsByExpenditureId, intervalsByGroupId }) =>
  (t) =>
    Boolean((t.isExpenditure && intervalsByExpenditureId.has(t.id)) || (t.isGroup && intervalsByGroupId.has(t.id)));

const sectionsUpdateMatcher: MakeUpdateMatcher =
  ({ intervalsByExpenditureId }) =>
  (t) =>
    Boolean(t.isSection && intervalsByExpenditureId.has(t.id));

const idUpdateMatcher: MakeUpdateMatcher =
  ({ intervalsByExpenditureId }) =>
  (t) =>
    intervalsByExpenditureId.has(t.id);

const getExpenditureId: ExpenditureIdGetter = (interval) => {
  // @ts-ignore
  const id = interval.expenditure?.exp_id || interval.exp_id;
  if (!id) return;
  return [id];
};
const getGroupId: ExpenditureIdGetter = (interval) => {
  // @ts-ignore
  const id = interval.group?.group_id || interval.group_id;
  if (!id) return;
  return [id];
};

const getParentId: ExpenditureIdGetter = (interval) => {
  const id =
    // @ts-ignore
    interval.cs_id ||
    // @ts-ignore
    interval.expenditure?.cs_id ||
    // @ts-ignore
    interval.group?.cs_id ||
    // @ts-ignore
    interval.ps_id ||
    // @ts-ignore
    interval.expenditure.ps_id ||
    // @ts-ignore
    interval.group?.ps_id;
  if (!id) return;
  return [id];
};

const getPlanSectionId: ExpenditureIdGetter = (interval) => {
  const id = (interval as IChartPlanSection).cs_id;
  if (!id) return;
  return [id];
};

const getSegmentId: ExpenditureIdGetter = (interval) => {
  const id =
    // @ts-ignore
    interval.segment_id ||
    // @ts-ignore
    interval.expenditure?.segment_id ||
    // @ts-ignore
    interval.group?.segment_id;
  if (!id) return;
  return [id];
};

const getParentSegmentId: ExpenditureIdGetter = (interval) => {
  const id =
    // @ts-ignore
    interval.parent_segment_id ||
    // @ts-ignore
    interval.expenditure.parent_segment_id ||
    // @ts-ignore
    interval.group?.parent_segment_id;
  if (!id) return;
  return [id];
};

const getWorkersId: ExpenditureIdGetter = (interval) => {
  // @ts-ignore
  const workersIds = interval.workers;
  if (!workersIds) return;
  return workersIds;
};

export interface IntervalMapperParams {
  strategy: MATCH_STRATEGY;
  type: INTERVAL_MAPPING_TYPES;
  tab: CHART_TABS;
  isAttribute: boolean;
}

export class IntervalMapper {
  type: INTERVAL_MAPPING_TYPES;
  tab: CHART_TABS;
  strategy: MATCH_STRATEGY;
  isAttribute: boolean;

  constructor({ type, strategy, tab, isAttribute }: IntervalMapperParams) {
    this.type = type;
    this.tab = tab;
    this.strategy = strategy;
    this.isAttribute = isAttribute;
  }

  private getUpdateMatcher(): MakeUpdateMatcher {
    if (this.type === INTERVAL_MAPPING_TYPES.PLAN_SECTIONS) {
      return sectionsUpdateMatcher;
    }
    if (this.strategy === MATCH_STRATEGY.SECTIONS_ONLY) {
      return idUpdateMatcher;
    }
    return expenditureAndGroupsUpdateMatcher;
  }

  private getExpenditureId(): ExpenditureIdGetter {
    if (this.tab === CHART_TABS.WORKERS) {
      return getWorkersId;
    }
    if (this.type === INTERVAL_MAPPING_TYPES.PLAN_SECTIONS) {
      return this.isAttribute ? getSegmentId : getPlanSectionId;
    }
    if (this.strategy === MATCH_STRATEGY.SECTIONS_ONLY) {
      return this.isAttribute ? getParentSegmentId : getParentId;
    }
    return getExpenditureId;
  }

  private getGroupId(): ExpenditureIdGetter {
    if (this.type === INTERVAL_MAPPING_TYPES.PLAN_SECTIONS || this.tab === CHART_TABS.WORKERS) {
      return () => undefined;
    }
    if (this.strategy === MATCH_STRATEGY.SECTIONS_ONLY) {
      return this.isAttribute ? getParentSegmentId : getParentId;
    }
    return getGroupId;
  }

  public mapIntervals({ data, dispatch, signal, projectId }: Omit<IScheduleIntervalMapping, "type" | "tab">) {
    if (!data.length) return;
    mapIntervals({
      getExpenditureId: this.getExpenditureId(),
      getGroupId: this.getGroupId(),
      makeUpdateMatcher: this.getUpdateMatcher(),
      data,
      dispatch,
      tab: this.tab,
      type: this.type,
      signal,
      projectId,
      strategy: this.strategy,
    });
  }
}

type ExpenditureIdGetter = (interval: ChartProcessedInterval) => number[] | undefined;
type ProcessedIntervalMap = Map<number, ChartProcessedInterval[]>;
type MakeUpdateMatcher = ({
  intervalsByExpenditureId,
  intervalsByGroupId,
}: {
  intervalsByExpenditureId: ProcessedIntervalMap;
  intervalsByGroupId: ProcessedIntervalMap;
}) => ChartTreeMatcherType;

interface IMapIntervals extends IScheduleIntervalMapping {
  getExpenditureId?: ExpenditureIdGetter;
  getGroupId?: ExpenditureIdGetter;
  makeUpdateMatcher: MakeUpdateMatcher;
  strategy: MATCH_STRATEGY;
}

const mapIntervals = ({
  getExpenditureId,
  getGroupId,
  makeUpdateMatcher,
  data,
  dispatch,
  tab,
  type,
  signal,
  projectId,
  strategy,
}: IMapIntervals) => {
  if (!data.length) return;

  try {
    const intervalsByExpenditureId = new Map<number, ChartProcessedInterval[]>();
    const intervalsByGroupId = new Map<number, ChartProcessedInterval[]>();
    for (let i = 0; i < data.length; i++) {
      const interval = data[i];
      const expenditureIds = getExpenditureId?.(interval);
      const groupIds = getGroupId?.(interval);
      if (expenditureIds !== undefined) {
        for (let e = 0; e < expenditureIds.length; e++) {
          const expenditureId = expenditureIds[e];
          const currentIntervals = intervalsByExpenditureId.get(expenditureId) || [];
          intervalsByExpenditureId.set(expenditureId, currentIntervals.concat(interval));
        }
      }
      if (groupIds !== undefined) {
        for (let g = 0; g < groupIds.length; g++) {
          const groupId = groupIds[g];
          const currentIntervals = intervalsByGroupId.get(groupId) || [];
          intervalsByGroupId.set(groupId, currentIntervals.concat(interval));
        }
      }
    }

    const matcher = makeUpdateMatcher({ intervalsByExpenditureId, intervalsByGroupId });

    scheduler.postTask(
      () =>
        dispatch(
          chartActions.updateTree({
            tab,
            params: {
              projectId,
              matcher,
              updater: (t) => {
                let intervalsToAdd = [];
                if (t.isGroup) {
                  intervalsToAdd = intervalsByGroupId.get(t.id) || [];
                  intervalsByGroupId.delete(t.id);
                } else {
                  intervalsToAdd = intervalsByExpenditureId.get(t.id) || [];
                  intervalsByExpenditureId.delete(t.id);
                }
                if (strategy === MATCH_STRATEGY.SECTIONS_ONLY) {
                  const absorbedIntervals: ChartProcessedInterval[] = [];
                  absorbIntervals(intervalsToAdd, absorbedIntervals);
                  intervalsToAdd = absorbedIntervals;
                }
                if (t.processedIntervals) {
                  // @ts-ignore
                  t.processedIntervals[type] = t.processedIntervals[type].concat(intervalsToAdd);
                }
                bubbleIntervals(t, intervalsToAdd, type);
                return t;
              },
              continueTraversalCondition: () =>
                !signal?.aborted && (intervalsByExpenditureId.size > 0 || intervalsByGroupId.size > 0),
            },
          })
        ),
      { ...mappingTaskOptions, signal }
    );
  } catch (e) {
    console.error(e);
  }
};

const bubbleIntervals = (tree: IChartTree, intervals: ChartProcessedInterval[], type: INTERVAL_MAPPING_TYPES) => {
  let parent = tree.parent;

  if (!parent?.bubbledIntervals?.[type]) return;

  const toBubbleIntervals: ChartProcessedInterval[] = [];

  absorbIntervals(intervals, parent.bubbledIntervals[type], toBubbleIntervals);

  parent = parent.parent;

  while (parent) {
    if (parent?.bubbledIntervals?.[type]) {
      // @ts-ignore
      parent.bubbledIntervals[type] = parent.bubbledIntervals[type].concat(toBubbleIntervals);
    }
    parent = parent.parent;
  }
};

const absorbIntervals = (
  intervals: ChartProcessedInterval[],
  target: ChartProcessedInterval[],
  accumulator?: ChartProcessedInterval[]
) => {
  for (const interval of intervals) {
    let absorbed = false;

    for (let i = target.length - 1; i >= 0; i--) {
      const active = target[i];
      // @ts-ignore
      const intervalStartMoment = moment(interval.start || interval.date);
      // @ts-ignore
      const activeEndMoment = moment(active.end || active.date);
      if (intervalStartMoment.isBefore(activeEndMoment)) {
        absorbed = true;
        break;
      }
    }

    if (!absorbed) {
      target.push(interval);
      accumulator?.push(interval);
    }
  }
};
