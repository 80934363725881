import { financeUtils, generateFinanceKey } from "pages/Finance/common/model/commonFinanceUtils";
import {
  FinanceExpendituresItemsTypes,
  IFinanceTreeItem,
  IMonthItem,
  IPreparedMonthData,
} from "pages/Finance/common/model/interfaces";

import {
  IFinanceTableRowData,
  IV2SummaryObject,
  IV2SummaryObjectResponse,
  IV2SummaryProject,
  IV2SummaryProjectsListResponse,
} from "./types";

interface ISummaryContext {
  oldTree: IFinanceTreeItem[];
}

export const summaryUtils = {
  mapProjectData: (project: IV2SummaryProject, ctx: ISummaryContext): IFinanceTreeItem => {
    const { id, name } = project;

    const oldProject = ctx.oldTree.find((el) => el.key === generateFinanceKey(id, "project"));
    const children = summaryUtils.mergeObjects(project.buildings, oldProject?.children, ctx);

    return {
      id,
      name,
      type: "project",
      key: generateFinanceKey(id, "project"),
      depth: -2,
      shouldBeLoaded: false,
      isOpen: oldProject?.isOpen,
      data: summaryUtils.getProjectData(project),
      children,
    };
  },

  mergeObjects: (
    objectsFromProject: IV2SummaryProject["buildings"] = [],
    objectsAlreadyExists: IFinanceTreeItem[] = [],
    ctx: ISummaryContext
  ): IFinanceTreeItem[] => {
    const detailedObjectsMap = objectsAlreadyExists.reduce<Record<string, IFinanceTreeItem>>((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    const newObjectsWithReplacingIfOldExists = objectsFromProject.map((obj) => {
      if (detailedObjectsMap[obj.id]) {
        const candidate = { ...detailedObjectsMap[obj.id] };
        delete detailedObjectsMap[obj.id];
        return candidate;
      } else {
        return summaryUtils.mapObjects(obj, ctx);
      }
    });

    return [...newObjectsWithReplacingIfOldExists, ...Object.values(detailedObjectsMap)];
  },

  getProjectData: (project: Pick<IV2SummaryProject, "data" | "q_data">) => {
    const acc: IPreparedMonthData = {};

    project.data.forEach((el) => {
      acc[`m${el.month}_diff_${el.year}` as keyof IPreparedMonthData] = el.diff;
      acc[`m${el.month}_fact_${el.year}` as keyof IPreparedMonthData] = el.fact;
      acc[`m${el.month}_plan_${el.year}` as keyof IPreparedMonthData] = el.plan;
      acc[`m${el.month}_diff_perc_${el.year}` as keyof IPreparedMonthData] = el.diff_perc;
    });

    Object.entries(project.q_data).forEach(([key, data]) => {
      // TODO_V2_FINANCE YEAR
      acc[`${key}_diff` as keyof IPreparedMonthData] = data.diff;
      acc[`${key}_fact` as keyof IPreparedMonthData] = data.fact;
      acc[`${key}_plan` as keyof IPreparedMonthData] = data.plan;
      acc[`${key}_diff_perc` as keyof IPreparedMonthData] = data.diff_perc;
    });

    return acc;
  },

  mapObjects: (object: IV2SummaryProject["buildings"][number], ctx: ISummaryContext): IFinanceTreeItem => {
    const { id, name } = object;

    const oldObject = summaryUtils.findNode(ctx.oldTree, { id, type: "object", key: generateFinanceKey(id, "object") });

    const isOnlyOneObject = false; //= Object.keys(ctx.objects ?? {}).length < 2;

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: -1,
      shouldBeLoaded: !oldObject?.children?.length,
      isOpen: oldObject?.isOpen || isOnlyOneObject,
      data: summaryUtils.getProjectData(object),
      children: oldObject?.children ?? [],
    };
  },

  mapSections: (
    section: IV2SummaryObject["sections"][number],
    ctx: { object: IV2SummaryObjectResponse }
  ): IFinanceTreeItem => {
    const { id, name } = section;

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: 0,
      shouldBeLoaded: false,
      isOpen: ctx.object.sections.length < 2,
      data: summaryUtils.getProjectData(section),
      children: section.subsections.map((el) => summaryUtils.mapSubsections(el, { section })),
    };
  },

  mapSubsections: (
    section: IV2SummaryObject["sections"][number]["subsections"][number],
    ctx: { section: IV2SummaryObjectResponse["sections"][number] }
  ): IFinanceTreeItem => {
    const { id, name } = section;

    const work = summaryUtils.mapExpenditure(section.works, "work", { section });
    const material = summaryUtils.mapExpenditure(section.materials, "material", { section });
    const service = summaryUtils.mapExpenditure(section.services, "service", { section });

    return {
      id,
      name,
      type: "object",
      key: generateFinanceKey(id, "object"),
      depth: 1,
      shouldBeLoaded: false, //!!oldObject?.children?.length,
      isOpen: ctx.section.subsections.length < 2,
      data: summaryUtils.getProjectData(section),
      children: [work, material, service].filter((el) => !!el) as IFinanceTreeItem[],
    };
  },

  mapExpenditure: (
    exp: IV2SummaryObject["sections"][number]["subsections"][number]["works"],
    type: FinanceExpendituresItemsTypes,
    ctx: { section: IV2SummaryObjectResponse["sections"][number]["subsections"][number] }
  ): IFinanceTreeItem | null => {
    if (!exp.expenditures.length && !exp.groups.length) {
      return null;
    }

    const isOpen =
      ctx.section.materials.expenditures.length +
        ctx.section.materials.groups.length +
        ctx.section.works.expenditures.length +
        ctx.section.works.groups.length +
        ctx.section.services.expenditures.length +
        ctx.section.services.groups.length <
      2;

    const childrenExpenditures = exp.expenditures.map<IFinanceTreeItem>((el) => ({
      id: el.id,
      name: el.name,
      type: "expenditure",
      key: generateFinanceKey(el.id, type),
      shouldBeLoaded: false,
      isPositive: true,
      isLoading: false,
      depth: 3,
      data: summaryUtils.getProjectData(el),
      number: el.number,
    }));

    const childrenGroups = exp.groups.map<IFinanceTreeItem>((group) => ({
      id: group.id,
      name: group.name,
      type: "group",
      key: generateFinanceKey(group.id, type),
      isPositive: true,
      isLoading: false,
      depth: 3,
      data: summaryUtils.getProjectData(exp),
      children: group.expenditures.map((ex) => ({
        id: ex.id,
        name: ex.name,
        type: "expenditure",
        key: generateFinanceKey(ex.id, type),
        shouldBeLoaded: false,
        isPositive: true,
        isLoading: false,
        depth: 4,
        data: summaryUtils.getProjectData(group),
        number: ex.number,
      })),
    }));

    return {
      id: ctx.section.id,
      name: financeUtils.getExpenditureName(type),
      type,
      key: generateFinanceKey(ctx.section.id, type),
      depth: 2,
      shouldBeLoaded: false,
      isOpen,
      data: summaryUtils.getProjectData(exp),
      children: (childrenExpenditures ?? []).concat(childrenGroups ?? []),
    };
  },

  extractTableData: (tree?: IFinanceTreeItem[]): IFinanceTableRowData[] => {
    if (!tree) return [];

    let acc: IFinanceTableRowData[] = [];

    tree.forEach((el) => {
      acc.push({ ...el.data, key: el.key });
      if (el.children && el.isOpen) {
        acc = acc.concat(summaryUtils.extractTableData(el.children));
      }
    });

    return acc;
  },

  extractTreeByProject: (tree: IFinanceTreeItem[] | undefined, projectId: number): IFinanceTreeItem[] => {
    if (!tree) return [];
    return tree.find((project) => project.id === projectId)?.children ?? [];
  },

  extractTreeByObject: (tree: IFinanceTreeItem[] | undefined, projectId: number, objectId: number) => {
    return tree?.find((project) => project.id === projectId)?.children?.find((el) => el.id === objectId)?.children;
  },

  findNode: (
    tree: IFinanceTreeItem[],
    subtree: Pick<IFinanceTreeItem, "id" | "type" | "key">
  ): IFinanceTreeItem | null => {
    if (!tree) return null;
    for (const node of tree) {
      if (node.id === subtree.id && node.key === subtree.key) {
        return node;
      }

      if (node.children && node.children.length > 0) {
        const foundInChildren = summaryUtils.findNode(node.children, subtree);

        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }

    return null;
  },

  closeTree: (tree: IFinanceTreeItem[], subTree: IFinanceTreeItem) => {
    const item = summaryUtils.findNode(tree, subTree);

    if (!item) return tree;

    item.isOpen = false;

    return tree;
  },

  openTree: (tree: IFinanceTreeItem[], subTree: IFinanceTreeItem) => {
    const item = summaryUtils.findNode(tree, subTree);

    if (!item) return tree;

    item.isOpen = true;

    return tree;
  },

  changeSubtree: (tree: IFinanceTreeItem[], subTree: IFinanceTreeItem, partialData: Partial<IFinanceTreeItem>) => {
    const item = summaryUtils.findNode(tree, subTree);

    if (!item) return tree;

    for (let key in partialData) {
      const typedKey = key as keyof IFinanceTreeItem;
      //@ts-ignore
      item[typedKey] = partialData[typedKey];
    }

    return tree;
  },

  setObject: (
    tree: IFinanceTreeItem[] = [],
    data: IV2SummaryObjectResponse,
    projectId: number,
    objectId: number
  ): IFinanceTreeItem[] => {
    const parentProjectIndex = tree.findIndex((el) => el.id === +projectId);
    if (parentProjectIndex === -1) {
      return [
        ...tree,
        {
          id: projectId,
          name: data.name,
          type: "project",
          key: generateFinanceKey(projectId, "project"),
          depth: -2,
          shouldBeLoaded: true,
          isOpen: true, //oldProject?.isOpen || isOnlyOneProject,
          data: {}, //summaryUtils.getProjectData(project),
          children: [
            {
              id: objectId,
              name: data.name,
              type: "object",
              key: generateFinanceKey(objectId, "object"),
              depth: -1,
              shouldBeLoaded: false, //!!oldObject?.children?.length,
              isOpen: true, //oldObject?.isOpen || isOnlyOneObject,
              data: summaryUtils.getProjectData(data),
              children: data.sections.map((section) => summaryUtils.mapSections(section, { object: data })),
            },
          ],
        },
      ];
    } else {
      return tree.map((proj, i) => {
        if (i === parentProjectIndex) {
          return {
            ...proj,
            children: [
              ...(proj.children ?? []).map((obj) => {
                if (obj.id === objectId) {
                  return {
                    ...obj,
                    type: "object" as const,
                    depth: -1,
                    children: data.sections.map((section) => summaryUtils.mapSections(section, { object: data })),
                  };
                } else {
                  return obj;
                }
              }),
            ],
          };
        } else {
          return proj;
        }
      });
    }
  },

  setProject: (
    tree: IFinanceTreeItem[] = [],
    data: IV2SummaryProjectsListResponse,
    projectId: number
  ): IFinanceTreeItem[] => {
    const isProjectAlreadyExists = tree.some((el) => el.id === projectId);
    if (isProjectAlreadyExists) {
      return tree.map((project) => {
        if (project.id !== projectId) {
          return project;
        } else {
          return summaryUtils.mapProjectData(data[0], { oldTree: tree });
        }
      });
    } else {
      return [...tree, summaryUtils.mapProjectData(data[0], { oldTree: tree })];
    }
  },

  getTotalData: (input: Pick<IV2SummaryObjectResponse, "m_data" | "q_data">, year: number) => {
    const acc: IPreparedMonthData = {};

    Object.entries(input.m_data).forEach(([key, data]) => {
      acc[`${key}_diff_${year}` as keyof IPreparedMonthData] = data.diff;
      acc[`${key}_fact_${year}` as keyof IPreparedMonthData] = data.fact;
      acc[`${key}_plan_${year}` as keyof IPreparedMonthData] = data.plan;
      acc[`${key}_diff_perc_${year}` as keyof IPreparedMonthData] = data.diff_perc;
    });

    Object.entries(input.q_data).forEach(([key, data]) => {
      acc[`${key}_diff` as keyof IPreparedMonthData] = data.diff;
      acc[`${key}_fact` as keyof IPreparedMonthData] = data.fact;
      acc[`${key}_plan` as keyof IPreparedMonthData] = data.plan;
      acc[`${key}_diff_perc` as keyof IPreparedMonthData] = data.diff_perc;
    });

    return acc;
  },

  extractProjectTotal: (data: IV2SummaryProjectsListResponse, year: number): IFinanceTreeItem[] => {
    const project = data[0];
    return [
      {
        id: project.id,
        name: "Итого по проекту",
        type: "total",
        key: generateFinanceKey(project.id, "total"),
        data: summaryUtils.getTotalData(project, year),
        depth: -2,
      },
    ];
  },

  extractObjectTotal: (
    data: Pick<IV2SummaryObjectResponse, "id" | "data" | "q_data" | "m_data">,
    year: number
  ): IFinanceTreeItem[] => {
    return [
      {
        id: data.id,
        name: "Итого по объекту",
        type: "total",
        key: generateFinanceKey(data.id, "total"),
        data: summaryUtils.getTotalData(data, year),
        depth: -2,
        isOpen: false,
      },
    ];
  },
};
