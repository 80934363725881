import cn from "classnames";
import React from "react";
import { useParams } from "react-router-dom";

import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock";
import MoreButton from "./components/MoreButton/MoreButton";
import WorkersTableHeader from "./components/TableHeader/WorkersTableHeader";
import WorkersTableRow from "./components/TableRow/WorkerTableRow";

import { Spinner } from "../../../../shared/ui/atoms/Spinner/Spinner";
import AddButton from "../../../../shared/ui/controls/AddButton/AddButton";
import EmptyPlaceholder from "../../../../shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import Expandable from "../../atoms/_TODO/Expandable/Expandable";

import { useWorkersList } from "../../../../hooks/useWorkersList";

import workersIcon from "images/icons/navigation/timesheetIcon.svg";

import styles from "./AddingListInModal.module.scss";

function AddWorkersInModal({
  addedUsers,
  selectedUsers,
  onAccept,
  onDecline,
  onChangeCount,
  onDelete,
  onSelect,
  selectedIds,
  addedIds,
}) {
  const { objectId } = useParams();
  const { workers, isLoading, filtersHandler, loadMoreHandler } = useWorkersList(objectId);

  const [isAdding, setIsAdding] = React.useState(false);

  const showAddBtn = !isLoading && addedUsers?.length !== workers?.results?.length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.expandableHeadline}>
        <span className={styles.title}>
          Сотрудники:{" "}
          {addedUsers?.length ? addedUsers?.length : showAddBtn ? <AddButton onClick={() => setIsAdding(true)} /> : "-"}
        </span>
      </div>
      {!!addedUsers?.length && (
        <div className={styles.table}>
          <WorkersTableHeader />
          {addedUsers?.map((el, i) => (
            <WorkersTableRow
              key={el.id}
              number={i + 1}
              worker={el}
              canDelete
              onChangeCount={(e) => onChangeCount(el.id, e.target.value, "added")}
              onDelete={() => onDelete(el.id)}
              canBeChecked={false}
            />
          ))}
        </div>
      )}
      {isAdding && (
        <ConfirmBlock
          count={selectedUsers?.length}
          onAccept={onAccept}
          onDecline={onDecline}
          onCommonClick={() => setIsAdding(false)}
        />
      )}
      {isAdding && (
        <div className={cn(styles.table, styles.bordered)}>
          <WorkersTableHeader onFiltersChange={filtersHandler} className={styles.header} />
          {isLoading && <Spinner />}
          {!!(!isLoading && workers?.results?.length) &&
            workers?.results
              ?.filter((el) => !addedIds.includes(el.id))
              .map((el, i) => (
                <WorkersTableRow
                  key={el.id}
                  number={i + 1}
                  worker={el}
                  onChangeCount={(e) => onChangeCount(el.id, e.target.value, "selected")}
                  onCheck={onSelect}
                  isSelected={selectedIds.includes(el.id)}
                />
              ))}
          {!!(!isLoading && !workers?.results?.length) && <EmptyPlaceholder img={workersIcon} />}
          <MoreButton
            isLoading={isLoading}
            allCount={workers?.count}
            existingCount={workers?.results?.length}
            onLoadMore={loadMoreHandler}
          />
        </div>
      )}
    </div>
  );
}

export default AddWorkersInModal;
