import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import HelpModal from "../ui/HelpModal/HelpModal";
import { learningActions } from "./model/learningSlice";
import { getLearning, getLearningSublists } from "./model/thunks";
import LearningContent from "./ui/LearningContent/LearningContent";
import LearningSidebar from "./ui/LearningSidebar/LearningSidebar";
import LearningVideoModal from "./ui/LearningVIdeoModal/LearningVideoModal";
import { useTypedSelector } from "app/store/typedUseSelector";

import { ILearningItem } from "./model/types";

import { SyncVideoPlayersContextProvider } from "./hooks/useSyncVideoPlayers";

import styles from "./Learning.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  openedKey: number;
  onOpen: () => void;
}

const Learning: React.FC<IProps> = ({ isOpen, onClose, openedKey, onOpen }) => {
  const dispatch = useDispatch();
  const data = useTypedSelector((state) => state.learning.data);
  const isLoading = useTypedSelector((state) => state.learning.isLoading);

  const confirmedPercents = useMemo(() => {
    if (!data?.count) return 0;
    const percent = data.count / 100;

    const completedCount = data?.results?.filter((el) => el.is_confirmed)?.length ?? 0;
    const res = Math.round(completedCount / percent);

    if (res > 100) return 100;
    return res;
  }, [data]);

  const [activeLesson, setActiveLesson] = useState<null | ILearningItem>(null);

  useEffect(() => {
    if (!data) return;
    setActiveLesson((prev) => {
      if (!!prev) {
        return prev;
      } else {
        return data.results?.filter((el) => !el.confirmed_at)?.[0] ?? data.results?.[0];
      }
    });
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getLearning());
      dispatch(getLearningSublists());
    }
  }, [isOpen]);

  const onExpand = () => {
    dispatch(getLearning());
    dispatch(getLearningSublists());
  };

  const isVideo = useTypedSelector((state) => state.learning.isVideoModal);

  const setIsVideo = (status: boolean) => {
    dispatch(learningActions.setIsVideoModal(status));
  };

  const lists = useTypedSelector((state) => state.learning.lists);

  return (
    <>
      <SyncVideoPlayersContextProvider>
        <HelpModal openedKey={openedKey} onExpand={onExpand} isOpen={isOpen} onClose={onClose} className={styles.modal}>
          <div className={styles.grid}>
            <LearningSidebar
              setActiveLesson={setActiveLesson}
              activeLesson={activeLesson!}
              confirmedPercents={confirmedPercents}
              items={data?.results}
              lists={lists}
            />
            <LearningContent
              item={activeLesson!}
              onOpenVideo={() => {
                onClose();
                setIsVideo(true);
              }}
              isOpen={isOpen}
            />
          </div>
        </HelpModal>
        <LearningVideoModal
          onOpenLearning={() => {
            onOpen();
            setIsVideo(false);
          }}
          isOpen={isVideo}
          onClose={() => setIsVideo(false)}
          item={activeLesson!}
        />
      </SyncVideoPlayersContextProvider>
    </>
  );
};

export default Learning;
