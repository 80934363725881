import React from "react";

import { useFinanceMeasure } from "../../../../../features/financeMeasure";
import MeasureBtn from "../../../../../features/financeMeasure/MeasureBtn/MeasureBtn";
import TabBar from "../../../../../shared/ui/controls/TabBar/TabBarNotLinks";
import MonthsYearsSlider from "../../../../../shared/ui/inputs/MonthsYearsSlider/MonthsYearsSlider";
import ProjectSettingsMenu from "./ProjectSettingsMenu/ProjectSettingsMenu";

import { DOCUMENTATION, PLAN, PROGRESS } from "../../constants";

import styles from "./ProjectNavbar.module.scss";

const ProjectNavbar = ({
  isTabs,
  onTabChange,
  activeTab,
  timeSliderDate,
  onChangeTimeSlider,
  filters,
  setFilter,
  selectingMonthsCount,
  setSelectingMonthsCount,
  permissions,
  _TEMP_hidePlan,
}) => {
  const tabs = React.useMemo(() => {
    const res = [];
    if (permissions.canViewProgress) {
      res.push({
        text: "Прогресс",
        id: PROGRESS,
        className: styles.tab,
      });
    }
    if (permissions.canViewPlan && !_TEMP_hidePlan) {
      res.push({
        text: "Планирование",
        id: PLAN,
        className: styles.tab,
      });
    }
    if (permissions.canViewDrawings) {
      res.push({
        text: "Документация",
        id: DOCUMENTATION,
        className: styles.tab,
      });
    }
    return res;
  }, [permissions.canViewPlan, permissions.canViewProgress, permissions.canViewDrawings, _TEMP_hidePlan]);

  const { measure, measureChangeHandler } = useFinanceMeasure();

  return (
    <div className={styles.container}>
      {isTabs && (permissions.canViewPlan || permissions.canViewProgress) && (
        <TabBar tabs={tabs} activeId={activeTab} onClick={onTabChange} containerClassName={styles.tabs} />
      )}
      <MonthsYearsSlider
        date={timeSliderDate}
        onChange={onChangeTimeSlider}
        className={styles.timeSlider}
        selectingMonthsCount={selectingMonthsCount}
      />
      <ProjectSettingsMenu
        filters={filters}
        setFilter={setFilter}
        selectingMonthsCount={selectingMonthsCount}
        setSelectingMonthsCount={setSelectingMonthsCount}
      />

      <div className={styles.parameter}>
        {/*<div className={styles.approv2e}>*/}
        {/*  <SendMultiplePlansForApprove />*/}
        {/*</div>*/}
        <MeasureBtn />
      </div>
    </div>
  );
};

export default React.memo(ProjectNavbar);
