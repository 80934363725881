import React, { useEffect, useState } from "react";

import { IEstimateTargetWithType } from "../SpecificationAddRelationsModal";

interface IProps {
  tab: string;
}

export const useSpecificationRelationsSelectTarget = ({ tab }: IProps) => {
  const [selectedEstimateTarget, setSelectedEstimateTarget] = useState<IEstimateTargetWithType | null>(null);
  const [selectedRdTarget, setSelectedRdTarget] = useState<IEstimateTargetWithType[]>([]);

  useEffect(() => {
    setSelectedEstimateTarget(null);
    setSelectedRdTarget([]);
  }, [tab]);

  const onSelectExpenditure = (target: IEstimateTargetWithType) => {
    if (tab === "rd") {
      setSelectedRdTarget((prev) => {
        if (prev.some((el) => el.expenditureId === target.expenditureId)) {
          return prev.filter((el) => el.expenditureId !== target.expenditureId);
        } else {
          return [...prev, target];
        }
      });
    } else {
      setSelectedEstimateTarget(target);
    }
  };

  return {
    onSelectExpenditure,
    selectedEstimateTarget,
    selectedRdTarget,
  };
};
