import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";

import { CoefficientInList } from "../HandlerCoefficientsModal/HandlerCoefficientsModal";
import Actions from "shared/ui/controls/Actions/Actions";
import ButtonBase from "shared/ui/controls/ButtonBase";
import InputAutocomplete from "shared/ui/inputs/InputAutocomplete/InputAutocomplete";
import InputBase from "shared/ui/inputs/InputBase";

import useDebounce from "utils/hooks/useDebouncedValue";

import { dropNonSignificantZeros } from "utils/formatters/dropNonSignificantZeros";

import styles from "./HandlerCoefficientsOperation.module.scss";

export const COEFFICIENTS_OPERATIONS = {
  ADD: "add",
  SUB: "sub",
} as const;

interface HandlerCoefficientsOperationProps {
  coefficientsForSteps: CoefficientInList[];
  onSubmit: (vals: any) => void;
  onCancel: () => void;
  isPending?: boolean;
}

const HandlerCoefficientsOperation = ({
  coefficientsForSteps,
  onSubmit,
  onCancel,
  isPending,
}: HandlerCoefficientsOperationProps) => {
  const [searchIncludingCosts, setSearchIncludingCosts] = useState("");
  const debouncedSearchIncludingCosts = useDebounce(searchIncludingCosts, 400);
  const [searchWithoutCosts, setSearchWithoutCosts] = useState("");
  const debouncedSearchWithoutCosts = useDebounce(searchWithoutCosts, 400);

  const coefficientsIncludingCosts = useMemo(
    () =>
      coefficientsForSteps
        .filter(
          (cf) =>
            cf.including_items_costs &&
            cf.originalName.toLowerCase().includes(debouncedSearchIncludingCosts.toLowerCase())
        )
        .map((cf) => ({ ...cf, name: cf.originalName, id: cf.originalId })),
    [coefficientsForSteps, debouncedSearchIncludingCosts]
  );

  const coefficientsWithoutCosts = useMemo(
    () =>
      coefficientsForSteps
        .filter(
          (cf) =>
            !cf.including_items_costs &&
            cf.originalName.toLowerCase().includes(debouncedSearchWithoutCosts.toLowerCase())
        )
        .map((cf) => ({ ...cf, name: cf.originalName, id: cf.originalId })),
    [coefficientsForSteps, debouncedSearchWithoutCosts]
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        operation: COEFFICIENTS_OPERATIONS.ADD,
      }}
      render={({ handleSubmit, form }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.form}>
            <Field
              name="coefficient_with_costs"
              required
              render={({ input, meta }) => (
                <InputAutocomplete
                  openOnFocus
                  onChangeSearchBar={(v: string) => setSearchIncludingCosts(v)}
                  isLoading={searchIncludingCosts !== debouncedSearchIncludingCosts}
                  onSelectOption={(name, id) => {
                    input.onChange(id);
                    setSearchIncludingCosts(name);
                  }}
                  options={coefficientsIncludingCosts}
                  displayingValue={searchIncludingCosts}
                  label={
                    form.getState().values.operation === COEFFICIENTS_OPERATIONS.ADD ? "Слагаемое 1" : "Уменьшаемое"
                  }
                />
              )}
            />
            <Field
              name="operation"
              required
              render={({ input, meta }) => (
                <div className={styles.operations}>
                  {/* @ts-ignore */}
                  <ButtonBase
                    {...{ [input.value === COEFFICIENTS_OPERATIONS.ADD ? "primary" : "secondary"]: true }}
                    onClick={() => input.onChange(COEFFICIENTS_OPERATIONS.ADD)}
                    className={styles.operationButton}
                  >
                    + (Сложение)
                  </ButtonBase>
                  {/* @ts-ignore */}
                  <ButtonBase
                    {...{ [input.value === COEFFICIENTS_OPERATIONS.SUB ? "primary" : "secondary"]: true }}
                    onClick={() => input.onChange(COEFFICIENTS_OPERATIONS.SUB)}
                    className={styles.operationButton}
                  >
                    - (Вычитание)
                  </ButtonBase>
                </div>
              )}
            />
            <Field
              name="coefficient_without_costs"
              required
              render={({ input, meta }) => (
                <InputAutocomplete
                  openOnFocus
                  onChangeSearchBar={(v: string) => setSearchWithoutCosts(v)}
                  isLoading={searchWithoutCosts !== debouncedSearchWithoutCosts}
                  onSelectOption={(name, id) => {
                    input.onChange(id);
                    setSearchWithoutCosts(name);
                  }}
                  options={coefficientsWithoutCosts}
                  displayingValue={searchWithoutCosts}
                  label={
                    form.getState().values.operation === COEFFICIENTS_OPERATIONS.ADD ? "Слагаемое 2" : "Вычитаемое"
                  }
                />
              )}
            />
            {form.getState().values.coefficient_with_costs && form.getState().values.coefficient_without_costs ? (
              <span className={styles.result}>
                <>
                  {(() => {
                    const cf1 = coefficientsIncludingCosts.find(
                      (cf) => cf.id === form.getState().values.coefficient_with_costs
                    );
                    const cf2 = coefficientsWithoutCosts.find(
                      (cf) => cf.id === form.getState().values.coefficient_without_costs
                    );
                    if (!cf1 || !cf2) return null;
                    const sign = form.getState().values.operation === COEFFICIENTS_OPERATIONS.ADD ? 1 : -1;
                    return `${sign > 0 ? "Cложение" : "Вычтиание"}: ${cf1.name} (${cf1.value}) ${
                      sign > 0 ? "+" : "-"
                    } ${cf2.name} (${cf2.value}) = ${dropNonSignificantZeros(
                      (parseFloat(cf1.value) + sign * parseFloat(cf2.value)).toFixed(15)
                    )}`;
                  })()}
                </>
              </span>
            ) : null}
            <Field name="reason" component={InputBase as any} placeholder="Основание" className={styles.reason} />
            <Actions
              canSave
              onSave={handleSubmit}
              color="blue"
              className={styles.actions}
              canRemove
              onRemoveDirectly={onCancel}
              deleteLabel="Отменить"
              disabled={isPending}
            />
          </div>
        </form>
      )}
    />
  );
};

export default HandlerCoefficientsOperation;
