import React, { PropsWithChildren, createContext, useContext, useState } from "react";

import { PricingModes } from "../utils/constants";

const PriceSelectContext = createContext<PriceSelectContextType>({
  activePricingMode: PricingModes.view,
  setActivePricingMode: () => {},
});

export const PriceSelectContextProvider = ({ children }: PropsWithChildren) => {
  const [activePricingMode, setActivePricingMode] = useState(PricingModes.view);

  const isSomeEstimatedPrice = activePricingMode !== PricingModes.view && activePricingMode !== PricingModes.budget;
  //=== PricingModes.estimatedPrice
  return (
    <PriceSelectContext.Provider value={{ activePricingMode, setActivePricingMode, isSomeEstimatedPrice }}>
      {children}
    </PriceSelectContext.Provider>
  );
};

export const usePriceSelectContext = () => useContext(PriceSelectContext);

type PriceSelectContextType = {
  activePricingMode: PricingModes;
  setActivePricingMode: React.Dispatch<React.SetStateAction<PricingModes>>;
  isSomeEstimatedPrice?: boolean;
};
