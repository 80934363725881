import { IHandlerCoefficientsActions } from "./actions";

import { IHandlerCoefficientsState } from "./types";

const initialState: IHandlerCoefficientsState = {
  coefficients: {},
  loading: {},
  invalidateKey: 1,
};

export const handlerCoefficientsReducer = (state = initialState, action: IHandlerCoefficientsActions): IHandlerCoefficientsState => {
  const { type, payload } = action;
  switch (type) {
    case "handlerCoefficients/SET_COEFFICIENTS":
      return {
        ...state,
        coefficients: {
          ...state.coefficients,
          [payload.sectionId]: payload.coefficients,
        },
      };
    case "handlerCoefficients/SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.status,
        },
      };
    case "handlerCoefficients/INVALIDATE":
      return {
        ...state,
        invalidateKey: state.invalidateKey + 1,
      };
    default:
      return state;
  }
};
