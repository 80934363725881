import { COEFFICIENT_MTR_CLARIFICATION_OPTIONS, COEFFICIENT_SMR_CLARIFICATION_OPTIONS } from "../HandlerCoefficients";

import { ICreateCoefficient } from "../model/types";

const VAL_MAX_DECIMALS = 15;

export const preProcessCoefficientCreation = (
  vals: Partial<ICreateCoefficient & { _allMTRChecked?: boolean; _allSMRChecked?: boolean }>,
  params?: { sectionId: number }
): Partial<ICreateCoefficient> => {
  const validatedVals = Object.assign({}, vals);
  validatedVals.including_items_costs = Boolean(validatedVals.including_items_costs);
  if (params?.sectionId) {
    validatedVals.section = String(params.sectionId);
  }
  if (!validatedVals.is_smr) {
    COEFFICIENT_SMR_CLARIFICATION_OPTIONS.forEach((option) => {
      // @ts-ignore
      delete validatedVals[option.id];
    });
  }
  if (!validatedVals.is_mtr) {
    COEFFICIENT_MTR_CLARIFICATION_OPTIONS.forEach((option) => {
      // @ts-ignore
      delete validatedVals[option.id];
    });
  }
  delete validatedVals._allMTRChecked;
  delete validatedVals._allSMRChecked;
  if (validatedVals.coefficient_step?.startsWith("coefficient_")) {
    validatedVals.applying_coefficient = Number(validatedVals.coefficient_step.split("_")[1]);
    // @ts-ignore
    delete validatedVals.coefficient_step;
  }
  if (validatedVals.value?.includes("%")) {
    const val = parseFloat(validatedVals.value.split("%")[0]) / 100;
    validatedVals.value = val.toFixed(VAL_MAX_DECIMALS);
    validatedVals.showing_in_percent = true;
  }
  return validatedVals;
};
