import ActionsButtons from "_LEGACY/UI/ActionsButtons/ActionsButtons";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IAttribute } from "./types";

interface AttributesState {
  invalidationKey: number;
  loadings: Record<string, boolean>;
  segments: Record<string, IAttribute[]>;
}

export const initialState: AttributesState = {
  invalidationKey: 0,
  loadings: {},
  segments: {},
};

const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setSegments: (state, action: PayloadAction<{ data: IAttribute[]; key: string }>) => {
      const { data, key } = action.payload;
      state.segments[key] = data;
    },
  },
});

export const attributesActions = attributesSlice.actions;

export const attributesReducer = attributesSlice.reducer;
