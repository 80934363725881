import classNames from "classnames";
import React, { FC, ReactNode } from "react";

import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./ObjectExtendedSubRow.module.scss";

interface IProps {
  className?: string;
  children: ReactNode;
  indicator?: ReactNode;
  indicatorClassName?: string;
}

const ObjectExtendedSubRow: FC<IProps> = ({ className, children, indicator, indicatorClassName }) => {
  return (
    <div className={classNames(styles.root, className)} onClick={stopEventPropagation}>
      {!!indicator && <div className={classNames(styles.indicator, indicatorClassName)}>{indicator}</div>}
      {children}
    </div>
  );
};

export default ObjectExtendedSubRow;
