import { useRef } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

import { updateExpenditure } from "redux/modules/common/building/materials/specification";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import MaterialsExpenditureForm from "../../features/MaterialsExpenditureForm/MaterialsExpenditureForm";
import { useTypedSelector } from "app/store/typedUseSelector";

interface MaterialsInlineEditExpenditureProps {
  initialValues: Record<string, unknown>;
  exitEditMode: () => void;
  typeUrl: string;
}

const MaterialsInlineEditExpenditure = ({
  initialValues,
  exitEditMode,
  typeUrl,
}: MaterialsInlineEditExpenditureProps) => {
  const dispatch = useDispatch();
  const objectId = useObjectId();
  const isPending = useTypedSelector((state: any) => state.specification.loadings)["updateExps"];

  const submitEditPositionForm = async (values: any) => {
    dispatch(updateExpenditure(objectId, typeUrl, initialValues.id, values));
    exitEditMode();
  };

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={submitEditPositionForm}
      initialValues={initialValues}
      render={({ values, handleSubmit, form }) => {
        return (
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            onReset={() => form.restart()}
          >
            <MaterialsExpenditureForm
              canRemove
              onRemoveDirectly={exitEditMode}
              canSave
              onSave={() => form.submit()}
              isPending={isPending}
              isFromEstimate={values.from_estimate}
            />
          </form>
        );
      }}
    />
  );
};

export default MaterialsInlineEditExpenditure;
