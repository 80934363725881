import { initChartActions, initChartTreeFilters } from "redux/modules/common/chart/reducer";
import { ChartActions, IChartStatePatch } from "redux/modules/common/chart/types";

export const CHART_CONFIG_PURCHASES: IChartStatePatch = {
  filters: initChartTreeFilters(),
  actions: {
    ...initChartActions(),
    [ChartActions.show_expenditures_in_tree]: true,
  },
  module: "purchases",
};
