import axios from "axios";
import getLongList from "utils/axios/loadLongList";

import { paramsType, queryParamsFormatter } from "utils/formatters/queryParamsFormatter";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { ESTIMATE_ITEMS_LIMIT } from "../sections/sectionsApi";

export const projectApi = {
  getExpenditures: (buildingId: number | string, params: paramsType) => {
    return (
      getLongList(axios
        .get(`/building/${buildingId}/estimate/expenditures/`, {
          params: queryParamsFormatter(params, { limit: ESTIMATE_ITEMS_LIMIT }),
        }))
        .then(({ data }) => data)
        .catch(errorCatcher)
    );
  },
  getGroups: (buildingId: number | string, params: paramsType) => {
    return axios
      .get(`/building/${buildingId}/groups/`, {
        params,
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },
  getSegmentsProgress: (buildingId: number | string, params?: paramsType) => {
    return axios
      .get(`/building/${buildingId}/segments/`, {
        params,
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },
  getSegmentsPlan: (buildingId: number | string, params?: paramsType) => {
    return axios
      .get(`/building/${buildingId}/estimate/plan/segments/`, {
        params,
      })
      .then(({ data }) => data)
      .catch(errorCatcher);
  },
};
