import { message } from "antd";
import { createSelector } from "reselect";

import { apiDeleteImportList, apiGetImportList, apiPostImportList } from "./importMaterialsApi";

import { errorCatcher } from "utils/helpers/errorCatcher";
import { messageErrorHandler } from "utils/helpers/errorHandler";

const moduleName = "importMaterials";

const SET_IMPORT_LIST = `${moduleName}/SET_IMPORT_LIST`;
const POST_IMPORT_LIST = `${moduleName}/SET_IMPORT_LISTS`;
const REMOVE_IMPORT_LIST = `${moduleName}/REMOVE_IMPORT_LIST`;

const initialState = {
  importList: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IMPORT_LIST:
      return {
        ...state,
        importList: payload,
      };
    case REMOVE_IMPORT_LIST:
      return {
        ...state,
        importList: {
          ...state.importList,
          results: [...state.importList.results.filter((el) => el.id !== payload)],
        },
      };
    case POST_IMPORT_LIST:
      return {
        ...state,
        importList: {
          ...state.importList,
          results: [payload, ...state.importList.results],
        },
      };
    default:
      return state;
  }
};
export const stateSelector = (state) => state[moduleName];
export const materialsImportListSelector = createSelector(stateSelector, (state) => state.importList);
const getImportListAC = (data) => ({ type: SET_IMPORT_LIST, payload: data });
const addImportListAC = (data) => ({ type: POST_IMPORT_LIST, payload: data });
const deleteImportListAC = (id) => ({ type: REMOVE_IMPORT_LIST, payload: id });

export const getImportList = (objectId, cb) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGetImportList(objectId);
      await dispatch(getImportListAC(data));
      cb?.();
    } catch (error) {
      errorCatcher(error);
    }
  };
};
export const addImportList = (objectId, config, successCallback) => {
  return async (dispatch) => {
    try {
      const { data } = await apiPostImportList(objectId, config);
      successCallback?.(data.id);
      dispatch(addImportListAC(data));
      message.success("Сохранено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};
export const deleteImportList = (objectId, id) => {
  return async (dispatch) => {
    try {
      await apiDeleteImportList(objectId, id);
      await dispatch(deleteImportListAC(id));
      await message.success(`Раздел удален из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};
