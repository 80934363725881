import cn from "classnames";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";

import { IOption } from "components/UI/atoms/Select";

import { addPrice, deletePrice } from "../model/thunks";
import { ReactComponent as CopyIcon } from "./../assets/copy.svg";
import { ReactComponent as DeleteIcon } from "./../assets/delete.svg";
import { ReactComponent as EditIcon } from "./../assets/edit.svg";
import { ReactComponent as PlusIcon } from "./../assets/plus.svg";
import { ReactComponent as SaveIcon } from "./../assets/save.svg";
import { useConfirmModal } from "entities/ConfirmationModal/ConfirmationContext";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import Actions from "shared/ui/controls/Actions/Actions";
import InputBase from "shared/ui/inputs/InputBase";

import { PricingModes } from "../utils/constants";

import { multiplePriceUtils } from "../utils/utils";
import { stopEventPropagation } from "utils/helpers/stopEventPropagation";

import styles from "./MultiplePricesSelect.module.scss";

interface IProps {
  option: IOption;
  onClick: any;
  multiplePricesLoadings: Record<string, any>;
  editingOption: IOption;
  onEdit: any;
  setEditingOption: Dispatch<SetStateAction<IOption | null>>;
  setIsAdding: any;
  isAdding: any;
  activePricingMode: any;
  objectId: string;
  multiplePricesOptions: any;
}

const RenderMultiplePriceOption = ({
  option,
  multiplePricesLoadings,
  editingOption,
  onEdit,
  setEditingOption,
  onClick,
  setIsAdding,
  isAdding,
  activePricingMode,
  objectId,
  multiplePricesOptions,
}: IProps) => {
  const dispatch = useDispatch();
  const isLoading = multiplePricesLoadings[option.id];
  const isEditing = +option.id === editingOption?.id;

  const { openConfirmModal, closeConfirmModal } = useConfirmModal();

  const onClickCC = (id: number) => {
    openConfirmModal({
      isOpen: true,
      onClose: closeConfirmModal,
      action: () => {
        dispatch(deletePrice(objectId, id));
        closeConfirmModal();
      },
      title: "Удалить цену?",
      acceptButtonText: "Удалить",
      className: styles.confirmModal,
      variant: "secondary",
    });
  };

  const onCopy = (name: string) => {
    dispatch(addPrice(objectId, multiplePriceUtils.getAvailableCopyName(multiplePricesOptions, name)));
  };

  const isDefault = [PricingModes.budget, PricingModes.view].includes(option.id as PricingModes);
  //@ts-expect-error
  const isDefaultEstimate = option.is_default; //=== PricingModes.estimatedPrice;

  const [showAction, setShowAction] = useState(false);

  if (isEditing) {
    return (
      <div className={cn(styles.inputWrapper)} onClick={stopEventPropagation}>
        <InputBase
          value={editingOption?.name as string}
          onChange={({ target }) => setEditingOption((prev) => ({ ...prev, name: target.value as string } as IOption))}
        />
        <div className={styles.activeActions} onClick={stopEventPropagation}>
          {!isDefault && (
            <CopyIcon
              onClick={() => {
                onCopy(option.name as string);
              }}
            />
          )}
          {!isDefault && <PlusIcon onClick={() => setIsAdding(true)} />}
          <SaveIcon onClick={() => onEdit()} />
          {!isDefault && !isDefaultEstimate && (
            <DeleteIcon className={styles.delete} onClick={() => onClickCC(+option.id)} />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={cn(styles.option, activePricingMode === option.id && styles.activeOption)}
        onClick={() => onClick?.(option.id, option.name)}
      >
        <div className={styles.name}>{option.name}</div>
        {isLoading && <Spinner isSmallGray isStatic />}
        {!isLoading && (
          <div className={styles.actions} onClick={stopEventPropagation}>
            {/* {!isDefault && (
              <CopyIcon
                onClick={() => {
                  onCopy(option.name as string);
                }}
              />
            )}
            {!isDefault && !isAdding && <PlusIcon onClick={() => setIsAdding(true)} />} */}
            {!isDefault && <EditIcon onClick={() => setEditingOption(option)} />}
            {/* {!isDefault && !isDefaultEstimate && <DeleteIcon onClick={() => onClickCC(+option.id)} />} */}
          </div>
        )}
      </div>
    </>
  );
};

export default RenderMultiplePriceOption;
