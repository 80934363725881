import moment from "moment";

import styles from "./SegmentWorkList.module.scss";

export interface SegmentWorkListProps {
  onClick: () => void;
  name: string;
  /** Дата в формате DD-MM-YYYY */
  start: string;
  /** Дата в формате DD-MM-YYYY */
  end: string;
}

export const SegmentWorkList: React.FC<SegmentWorkListProps> = ({ onClick, name, start, end }) => {
  return (
    <div onClick={onClick} className={styles.container}>
      <p className={styles.name}>{name}</p>
      <p>
        <span className={styles.date}>{moment(start).format("DD/MM/YYYY")}</span>&nbsp;-&nbsp;
        <span className={styles.date}>{moment(end).format("DD/MM/YYYY")}</span>
      </p>
    </div>
  );
};
