//@ts-nocheck
import { message } from "antd";
import axios from "axios";
import { Dispatch } from "redux";
import { createSelector } from "reselect";

import { clearSelecteds } from "./process";
import { setTicketStatus } from "./ticketsEstimate";

/*  */
import { errorCatcher } from "utils/helpers/errorCatcher";

const moduleName = "shedules";
const LOAD_SHEDULES = `${moduleName}/LOAD_SHEDULES`;
const SET_SHEDULES = `${moduleName}/SET_SHEDULES`;
const SET_EXPENDITORIES = `${moduleName}/SET_EXPENDITORIES`;
const SET_SHEDULE = `${moduleName}/SET_SHEDULE`;
const SET_SHEDULE_BUILDING = `${moduleName}/SET_SHEDULE_BUILDING`;
const SET_SHEDULE_SECTION = `${moduleName}/SET_SHEDULE_SECTION`;
const SET_SHEDULE_SUBSECTION = `${moduleName}/SET_SHEDULE_SUBSECTION`;
const CHANGE_OPEN_BUILDINGS = `${moduleName}/CHANGE_OPEN_BUILDINGS`;
const CHANGE_OPEN_SECTION = `${moduleName}/CHANGE_OPEN_SECTION`;
const CHANGE_OPEN_SUBSECTION = `${moduleName}/CHANGE_OPEN_SUBSECTION`;
const SET_IS_UPDATE_GROUP_SUCCESS = `${moduleName}/SET_IS_UPDATE_GROUP_SUCCESS`;
const ADD_EXPENDITURES_GROUPS_BY_SECTION = `${moduleName}/ADD_EXPENDITURES_GROUPS_BY_SECTION`;
const RESET_EXPENDITURES_INTERVALS_GROUPS_BY_SECTION = `${moduleName}/RESET_EXPENDITURES_INTERVALS_GROUPS_BY_SECTION`;

const INVALIDATE_EXPENDITURES_GROUPS_BY_SECTION_KEY = `${moduleName}/INVALIDATE_EXPENDITURES_GROUPS_BY_SECTION_KEY`;

const initialState = {
  shedules: null,
  isLoading: true,
  loadingSave: false,
  isUpdateGroupSuccess: false,
  expendituresGroupsBySections: {},
  groupingPending: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SHEDULES:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SHEDULES:
      return {
        ...state,
        shedules: payload,
        isLoading: false,
      };
    case SET_SHEDULE:
      return {
        ...state,
        shedule: {
          /* @ts-ignore */
          buildings: (state?.shedule?.buildings ? state?.shedule?.buildings : payload?.buildings).map((item) => {
            return {
              ...item,
              plan_intervals: payload?.plan_intervals?.filter((plan) => item.id === plan.current_building_id),
              fact_intervals: [
                ...(item?.fact_intervals || []),
                ...payload?.fact_intervals?.filter((fact) => item.id === fact.current_building_id),
              ]?.sort((a, b) => (a.start_at < b.start_at ? -1 : 1)),
            };
          }),
        },
        isLoading: false,
      };
    case SET_SHEDULE_BUILDING:
      return {
        ...state,
        shedule: {
          /* @ts-ignore */
          buildings: state?.shedule?.buildings.map((item) => {
            return {
              ...item,
              sections: (item?.sections ? item.sections : payload?.sections)
                ?.filter((section) => item.id === section.building_id && !item.parent_id)
                .map((item) => {
                  return {
                    ...item,
                    fact_intervals: payload?.fact_intervals
                      ?.filter((plan) => item.id === plan.current_parentsection_id)
                      ?.sort((a, b) => (a.start_at < b.start_at ? -1 : 1)),
                  };
                }),
            };
          }),
        },
      };
    case SET_SHEDULE_SECTION:
      return {
        ...state,
        shedule: {
          /* @ts-ignore */
          buildings: state?.shedule?.buildings.map((item) => {
            return {
              ...item,
              sections: item?.sections.map((item) => {
                return {
                  ...item,
                  subsections: payload?.sections
                    .filter((subsection) => item.id === subsection.parent_id)
                    .map((item) => {
                      return {
                        ...item,
                        fact_intervals: payload?.fact_intervals
                          ?.filter((plan) => item.id === plan.current_section_id)
                          ?.sort((a, b) => (a.start_at < b.start_at ? -1 : 1)),
                      };
                    }),
                };
              }),
            };
          }),
        },
      };
    case SET_SHEDULE_SUBSECTION:
      return {
        ...state,
        shedule: {
          /* @ts-ignore */
          buildings: state?.shedule?.buildings.map((item) => {
            return {
              ...item,
              sections: item?.sections.map((item) => {
                return {
                  ...item,
                  subsections: item?.subsections.map((item) => {
                    return {
                      ...item,
                      expenditures_work: payload?.expenditures
                        .filter(
                          (expenditure) =>
                            expenditure.current_section_id === item.id && expenditure.expenditure_type === "work"
                        )
                        .map((item) => {
                          return {
                            ...item,
                            fact_intervals: payload?.fact_intervals
                              ?.filter((interval) => interval.current_expenditure_id === item.id)
                              ?.sort((a, b) => (a.start_at < b.start_at ? -1 : 1)),
                          };
                        }),
                      expenditures_material: payload?.expenditures.filter(
                        (expenditure) =>
                          expenditure.current_section_id === item.id && expenditure.expenditure_type === "material"
                      ),
                    };
                  }),
                };
              }),
            };
          }),
        },
      };
    case SET_EXPENDITORIES:
      return {
        ...state,
        expenditure: payload,
      };
    case CHANGE_OPEN_BUILDINGS:
      return {
        ...state,
        shedule: {
          /* @ts-ignore */
          buildings: state?.shedule?.buildings.map((item) => {
            return {
              ...item,
              isOpen: item.id === payload.id ? payload.val : item.isOpen,
            };
          }),
        },
      };
    case CHANGE_OPEN_SECTION:
      return {
        ...state,
        shedule: {
          buildings: state?.shedule?.buildings.map((item) => {
            return {
              ...item,
              sections: item?.sections.map((item) => {
                return {
                  ...item,
                  isOpen: item.id === payload.id ? payload.val : item.isOpen,
                };
              }),
            };
          }),
        },
      };
    case CHANGE_OPEN_SUBSECTION:
      return {
        ...state,
        shedule: {
          buildings: state?.shedule?.buildings.map((item) => {
            return {
              ...item,
              sections: item?.sections.map((item) => {
                return {
                  ...item,
                  subsections: item?.subsections?.map((item) => {
                    return {
                      ...item,
                      isOpen: item.id === payload.id ? payload.val : item.isOpen,
                    };
                  }),
                };
              }),
            };
          }),
        },
      };
    case SET_IS_UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        isUpdateGroupSuccess: payload,
      };
    case ADD_EXPENDITURES_GROUPS_BY_SECTION:
      return {
        ...state,
        expendituresGroupsBySections: {
          ...state.expendituresGroupsBySections,
          [payload.sectionId]: {
            ...(state.expendituresGroupsBySections[payload.sectionId] || {}),
            groups: payload.data,
          },
        },
      };
    case INVALIDATE_EXPENDITURES_GROUPS_BY_SECTION_KEY: {
      return {
        ...state,
        expendituresGroupsBySections: {
          ...state.expendituresGroupsBySections,
          [payload.sectionId]: {
            ...(state.expendituresGroupsBySections[payload.sectionId] || {}),
            invalidateKey: Math.random(),
          },
        },
      };
    }
    case RESET_EXPENDITURES_INTERVALS_GROUPS_BY_SECTION:
      return {
        ...state,
        expendituresGroupsBySections: {
          ...state.expendituresGroupsBySections,
          [payload.sectionId]: {
            ...state.expendituresGroupsBySections[payload.sectionId],
            groups: state.expendituresGroupsBySections[payload.sectionId]?.groups?.map((item) => ({
              ...item,
              fact_intervals: [],
              plan_intervals: [],
            })),
          },
        },
      };
    case "_shedules/PENDING_GROUPING":
      return {
        ...state,
        groupingPending: payload,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const shedulesSelector = createSelector(stateSelector, (state) => state.shedules);
export const sheduleSelector = createSelector(stateSelector, (state) => state.shedule);
export const shedulesLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const expenditureSelector = createSelector(stateSelector, (state) => state.expenditure);
export const isUpdateGroupSuccessSelector = createSelector(stateSelector, (state) => state.isUpdateGroupSuccess);
export const expendituresGroupsBySectionsSelector = createSelector(
  stateSelector,
  (state) => state.expendituresGroupsBySections
);

export const loadShedule = (params) => {
  const config = {
    params: {
      ...params,
    },
  };

  return (dispatch) => {
    dispatch({
      type: LOAD_SHEDULES,
    });
    axios
      .get(`/shedule/`, config)
      .then((response) => {
        if (params.subsection_id) {
          dispatch({
            type: SET_SHEDULE_SUBSECTION,
            payload: response.data,
          });
        } else if (params.section_id) {
          dispatch({
            type: SET_SHEDULE_SECTION,
            payload: response.data,
          });
        } else if (params.building_id && !params.getOnlyOne) {
          dispatch({
            type: SET_SHEDULE_BUILDING,
            payload: response.data,
          });
        } else {
          dispatch({
            type: SET_SHEDULE,
            payload: response.data,
          });
        }
      })
      .catch(errorCatcher);
  };
};

export const changeOpenBuildings = (val, id) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_OPEN_BUILDINGS,
      payload: { val, id },
    });
  };
};

export const changeOpenSection = (val, id) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_OPEN_SECTION,
      payload: { val, id },
    });
  };
};

export const invalidateExpenditureGroupsKey = (sectionId) => {
  return (dispatch) => {
    dispatch({
      type: INVALIDATE_EXPENDITURES_GROUPS_BY_SECTION_KEY,
      payload: { sectionId },
    });
  };
};

export const changeOpenSubSection = (val, id) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_OPEN_SUBSECTION,
      payload: { val, id },
    });
  };
};

export const setIsUpdateGroupSuccessAction = (payload) => {
  return {
    type: SET_IS_UPDATE_GROUP_SUCCESS,
    payload,
  };
};

export const addExpendituresGroupsBySectionAction = (data, sectionId) => ({
  type: ADD_EXPENDITURES_GROUPS_BY_SECTION,
  payload: { data, sectionId },
});

export const resetExpendituresIntervalsGroupsBySection = (sectionId) => ({
  type: RESET_EXPENDITURES_INTERVALS_GROUPS_BY_SECTION,
  payload: { sectionId },
});

export const loadShedules = (buildingId, params) => {
  const config = {
    params,
  };

  return (dispatch) => {
    dispatch({
      type: LOAD_SHEDULES,
    });
    axios
      .get(`/tree/buildings/${buildingId}/lite/`, config)
      .then((response) =>
        dispatch({
          type: SET_SHEDULES,
          payload: response.data,
        })
      )
      .catch(errorCatcher);
  };
};

export const loadExpenditure = (id, expenditureId) => {
  const config = {
    params: {},
  };

  return (dispatch) => {
    dispatch({
      type: LOAD_SHEDULES,
    });

    axios
      .get(`/building/${id}/estimate/expenditure/${expenditureId}/`, config)
      .then((response) => {
        dispatch({
          type: SET_EXPENDITORIES,
          payload: response.data,
        });
        dispatch(setTicketStatus(response.data));
      })
      .catch(errorCatcher);
  };
};

export const updateExpendituresGroupProducts = (buildingId, groupId, fieldValues) => {
  const data = {
    ...fieldValues,
    expenditures: fieldValues.expenditures?.map((exp) => ({ expenditure: exp.id, estimated_cost: exp.estimated_cost })),
  };

  const sectionId = fieldValues.expenditures?.[0]?.section_id;

  return (dispatch) => {
    dispatch(setIsUpdateGroupSuccessAction(false));

    axios
      .patch(`/building/${buildingId}/groups/${groupId}/`, data)
      .then(() => {
        dispatch(setIsUpdateGroupSuccessAction(true));
        dispatch(clearSelecteds());
        dispatch(getExpendituresGroups({ buildingId, sectionId }));
      })
      .catch(errorCatcher);
  };
};

export const createExpendituresGroup = (buildingId, fieldValues) => {
  const data = {
    ...fieldValues,
    payment_terms_write: fieldValues.payment_terms_write?.filter((el) => !!el.payment_type) ?? [],
    section_id: fieldValues?.expenditures?.[0]?.section_id,
  };

  return (dispatch) => {
    dispatch({ type: "_shedules/PENDING_GROUPING", payload: true });
    axios
      .post(`/building/${buildingId}/groups/`, data)
      .then((response) => {
        const expandedWithExpenditures = {
          ...response.data,
          expenditures: fieldValues.expenditures,
        };

        dispatch(updateExpendituresGroupProducts(buildingId, response.data?.id, expandedWithExpenditures));

        let revalidatedSections = new Set();

        fieldValues?.expenditures?.map((x) => {
          if (revalidatedSections.has(x?.sectionId)) return;

          dispatch(invalidateExpenditureGroupsKey(x?.sectionId));
          revalidatedSections.add(x?.sectionId);
        });

        message.success("Расценки успешно сгруппированы");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch({ type: "_shedules/PENDING_GROUPING", payload: false });
      });
  };
};

export interface IGetExpendituresGroupsProps {
  buildingId: string;
  sectionId: number;
  year?: number;
  month?: number;
  dateStart?: string;
  dateEnd?: string;
}

export const getExpendituresGroups = ({
  buildingId,
  sectionId,
  year,
  month,
  dateEnd,
  dateStart,
}: IGetExpendituresGroupsProps) => {
  const config = {
    params: {
      section_id: sectionId,
      planfact_year: year,
      planfact_month: month,
      planfact_start: dateStart,
      planfact_end: dateEnd,
    },
  };

  return (dispatch: Dispatch) => {
    dispatch(resetExpendituresIntervalsGroupsBySection(sectionId));

    axios
      .get(`/building/${buildingId}/groups/`, config)
      .then((response) => {
        dispatch(addExpendituresGroupsBySectionAction(response.data.results, sectionId));
      })
      .catch(errorCatcher);
  };
};

export const deleteExpendituresGroups = (buildingId, groupId, callback) => {
  return () => {
    axios
      .delete(`/building/${buildingId}/groups/${groupId}/`)
      .then(() => callback?.())
      .catch(errorCatcher);
  };
};

export const updateExpendituresGroup =
  ({ buildingId, groupId, data, callback, errorCallback }) =>
  () => {
    const formattedData = {
      ...data,
      expenditures: data.expenditures?.map((exp) => ({ expenditure: exp.id, estimated_cost: exp.estimated_cost })),
    };

    axios
      .patch(`/building/${buildingId}/groups/${groupId}/`, formattedData)
      .then(({ data }) => {
        callback?.();
      })
      .catch((e) => {
        errorCatcher(e);
        errorCallback?.();
      });
  };
