import cn from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { compose } from "redux";

import { HTMLInputProps } from "@blueprintjs/core";

import { beautifyNumberValue } from "../../../../utils/formatters/beautifyNumberValue";
import { Options, transformDigitToFinancial } from "../../../../utils/formatters/transformDigitToFinancial";

import styles from "./index.module.scss";

export const enum VALUE_TYPES {
  PRICE = "price",
  NUMBER = "number",
  TEXT = "text",
  NUMBER_AND_PLUS = "numberAndPlus",
}

export const enum INPUT_BASE_VARIANTS {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

const NUMBERS_FLOAT_CONSTRAINT = 4;

const transformPriceValue = (value: string, options: Options = { withFloat: false }) => {
  if (!value) return value;
  return transformDigitToFinancial(beautifyNumberValue(value), options);
};

interface IProps extends HTMLInputProps {
  input?: FieldInputProps<string>;
  meta?: FieldMetaState<string>;
  variant?: INPUT_BASE_VARIANTS;
  className?: string;
  classNameInput?: string;
  classNameLabel?: string;
  label?: string;
  value?: string;
  valueType?: VALUE_TYPES;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  numberTransformOptions?: Options;
  icon?: React.ReactNode;
  isWithButton?: boolean;
  onButtonClick?: () => void;
  isButtonActive?: boolean;
  numbersConstraint?: number;
  floatConstraint?: number;
  onEnter?: () => void;
}

const InputBase: React.FC<IProps> = ({
  input, // for react-final-form Field
  variant = INPUT_BASE_VARIANTS.PRIMARY,
  className,
  classNameInput,
  classNameLabel,
  label,
  meta: { error, touched } = {},
  value,
  valueType = VALUE_TYPES.TEXT,
  onChange,
  onBlur,
  numberTransformOptions,
  icon,
  isWithButton,
  onButtonClick,
  isButtonActive,
  numbersConstraint,
  floatConstraint,
  onEnter,
  ...anotherProps
}) => {
  const transformEventTargetValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) return e;

      switch (valueType) {
        case VALUE_TYPES.NUMBER:
          if (!e.target.value.length) {
            return e;
          }

          e.target.value = e.target.value.replace(",", ".");

          return {
            ...e,
            target: {
              ...e.target,
              value: beautifyNumberValue(
                e.target.value,
                floatConstraint ?? numbersConstraint ?? NUMBERS_FLOAT_CONSTRAINT
              ),
            },
          };

        case VALUE_TYPES.PRICE:
          const value = e.target.value.replaceAll(" ", "").replace(",", ".");

          return {
            ...e,
            target: {
              ...e.target,
              value: beautifyNumberValue(value, floatConstraint),
            },
          };

        case VALUE_TYPES.NUMBER_AND_PLUS:
          if (!e.target.value.length) {
            return e;
          }

          e.target.value = e.target.value.replace(",", ".");

          return {
            ...e,
            target: {
              ...e.target,
              value: beautifyNumberValue(
                e.target.value,
                floatConstraint ?? numbersConstraint ?? NUMBERS_FLOAT_CONSTRAINT,
                [{ symbol: "+", allowedIndex: 0 }]
              ),
            },
          };

        default:
          return e;
      }
    },
    [valueType]
  );

  const handleBlur = useCallback(
    (e: any) => {
      onBlur?.(e);

      if (!onChange) return;

      const changedValue = e.target.value.replace(/[.,]$/g, "");
      if (changedValue === e.target.value) return;

      if (valueType === VALUE_TYPES.PRICE || valueType === VALUE_TYPES.NUMBER) {
        onChange({
          ...e,
          target: { ...e.target, value: beautifyNumberValue(changedValue, floatConstraint) },
        });
      }
      if (valueType === VALUE_TYPES.NUMBER_AND_PLUS) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: beautifyNumberValue(changedValue, floatConstraint, [{ symbol: "+", allowedIndex: 0 }]),
          },
        });
      }
    },
    [valueType, onChange]
  );

  const callOnChangeWithNotEqualValues = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === value && valueType !== VALUE_TYPES.TEXT) return;
      if (onChange) onChange(e);
      if (input?.onChange) input?.onChange(e);
    },
    [value, onChange]
  );

  const definedValue = value === undefined ? "" : value;
  let finalValue;

  const needTransform = valueType === VALUE_TYPES.PRICE || (valueType === VALUE_TYPES.NUMBER && numberTransformOptions);

  if (needTransform) {
    finalValue = transformPriceValue(definedValue, numberTransformOptions);
  } else {
    finalValue = definedValue;
  }

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!onEnter) return;
    const enterListener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onEnter();
      }
    };
    inputRef.current?.addEventListener("keydown", enterListener);
    return () => {
      inputRef.current?.removeEventListener("keydown", enterListener);
    };
  }, [onEnter]);

  return (
    <div className={cn(styles.container, className)}>
      {label && <label className={cn(styles.label, classNameLabel)}>{label}</label>}
      <div className={styles.inputWrapper}>
        <input
          ref={inputRef}
          {...anotherProps}
          className={cn(styles.input, styles[variant], classNameInput, {
            [styles.inputError]: touched && error,
            [styles.price]: valueType === VALUE_TYPES.PRICE,
            [styles.inputWithRightBtn]: isWithButton,
          })}
          value={finalValue}
          onChange={compose(callOnChangeWithNotEqualValues, transformEventTargetValue)}
          onBlur={handleBlur}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          {...input}
        />
        {icon && <div className={cn(styles.icon, "inputBaseIcon")}>{icon}</div>}
        {isWithButton && (
          <button className={styles.btn} onClick={onButtonClick} disabled={!isButtonActive}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1812 16.2554L3.28667 10.4702L2 12.0036L10.4265 19.0743L21.9967 5.28545L20.4647 4L10.1812 16.2554Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
      {touched && error && <div className={cn(styles.errorMessage)}>{error}</div>}
    </div>
  );
};

export default React.memo(InputBase);
