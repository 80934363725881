import { addIntervalChecker } from "components/pages/Building/utils";

import { EXPENDITURE_TYPES } from "constants/constant";

import { useUrlModule } from "utils/hooks/useUrlModule";

import {
  isExpenditureSharedToMeButCantAddInterval,
  isExpenditureSharedToMyContractor,
} from "utils/checkers/checkExpenditureShared";

interface IProps {
  expenditureOrGroup: any;
  tab: any;
  permissions: any;
}

export const useAddIntervals = ({ expenditureOrGroup, tab, permissions }: IProps) => {
  const moduleRoute = useUrlModule();

  const isSharedToMyContractor = isExpenditureSharedToMyContractor(expenditureOrGroup);

  const isSharedToMeButCantAddIntervals = isExpenditureSharedToMeButCantAddInterval(expenditureOrGroup);

  const canAddIntervals =
    expenditureOrGroup.expenditure_type === EXPENDITURE_TYPES.WORK &&
    addIntervalChecker({
      activeTab: tab,
      haveAddingPlanPermission: permissions?.canAddPlan && !isSharedToMyContractor && !isSharedToMeButCantAddIntervals,
      haveAddingProgressPermission:
        permissions?.canAddProgress && !isSharedToMyContractor && !isSharedToMeButCantAddIntervals,
      moduleRoute,
    });

  return { canAddIntervals };
};
