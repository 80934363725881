import { message } from "antd";
import cn from "classnames";
import { groupBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { approvedIntervalsSelector } from "redux/modules/common/building/process";
import { deleteExpendituresGroups, updateExpendituresGroup } from "redux/modules/common/building/shedules";

import MatchingIcon, { MatchingStatusesEnum } from "../../../../_LEGACY/UI/MatchingIcon/MatchingIcon";
import Checkbox from "../../../../_LEGACY/UI/__TODO/Checkbox/Checkbox";
import ChartPopup from "../../../pages/Building/components/ChartPopup/ChartPopup";
import { addIntervalChecker } from "../../../pages/Building/utils";
import AddRelationsModal from "./components/AddRelationsModal/AddRelationsModal";
import RelationsInfoModal from "./components/AddRelationsModal/RelationsInfoModal/RelationsInfoModal";
import BudgetColumns from "./components/BudgetColumns/BudgetColumns";
import DefaultColumns from "./components/DefaultColumns/DefaultColumns";
import EstimatedPriceColumns from "./components/EstimatedPriceColumns/EstimatedPriceColumns";
import Info from "./components/Info/Info";
import ExpenditureGroupModal from "components/UI/_TODO/ExpenditureGroupModal/ui/ExpenditureGroupModal";
import { TabsEnum } from "components/pages/Building/enums";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { ESTIMATE_STATES_IDS } from "pages/Handler/ui/ProHandler/constants";

import PopoverOverlay from "../PopoverOverlay/PopoverOverlay";
import { usePriceSelectContext } from "features/MultiplePrices/contexts/PriceSelectContext";

import { EXPENDITURE_TYPES } from "../../../../constants/constant";
import { VARIANTS } from "./constants";

import { useUrlModule } from "utils/hooks/useUrlModule";

import { dropNonSignificantZeros } from "../../../../utils/formatters/dropNonSignificantZeros";
import {
  isExpenditureSharedToMeButCantAddInterval,
  isExpenditureSharedToMyContractor,
} from "utils/checkers/checkExpenditureShared";

import CheckDouble from "../../../../images/icons/CheckDouble";

import styles from "./Expenditure.module.scss";

const Expenditure = ({
  className,
  buildingId,
  expenditure,
  intervals,
  loadProduct,
  variant,
  isShared,
  sectionName,
  isChecked,
  check,
  canCheck,
  actions,
  directlyAction,
  loadSectionExpenditures,
  loadSectionGroups,
  permissions = {},
  parentId,
  isKSHidden,
  lsrPlanData,
  onReplacementIconClick,
  canViewFiles = true,
  isExpendituresGroup = false,
  activeTab,
  factIntervals,
  planIntervals,
  changeExpenditureCost,
  changeExpenditureDiscount,
  isWithIndicator,
  sectionId,
  isNumberHidden,
  isPopupsHidden,
  isFileHidden,
  onFileDirectlyClick,
  activeEstimateStateId,
  visibilityChecked,
  handleVisibilityCheck,
}) => {
  const dispatch = useDispatch();
  const objectId = useObjectId(3);
  const moduleRoute = useUrlModule();

  const approvedIntervals = useSelector(approvedIntervalsSelector)[parentId];

  const [overviewState, setOverviewState] = useState({});
  const [isShowGroupModal, setIsShowGroupModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [relationOriginInfo, setRelationOriginInfo] = useState(null);
  const [isProductInfoOpened, setIsProductInfoOpened] = useState(false);

  const isHandlerProductionVariant = variant === VARIANTS.HANDLER_PRODUCTION;
  const isHandlerDraftVariant = variant === VARIANTS.HANDLER_DRAFT;
  const isProgressVariant = variant === VARIANTS.PROGRESS;

  const isOutOfEstimate = !!expenditure.is_default;
  const outOfEstimateRelations = expenditure.estimate_expenditures ?? [];

  const expenditureDisplayedCount = dropNonSignificantZeros(
    isHandlerProductionVariant ? expenditure.count : expenditure.count
  );

  const isSomeOfHandlersVariant = useMemo(
    () => [VARIANTS.HANDLER_PRODUCTION, VARIANTS.HANDLER_DRAFT, VARIANTS.HANDLER].indexOf(variant) !== -1,
    [variant]
  );

  const slicedIntervals = useMemo(() => {
    return {
      plans: intervals?.plans ? intervals?.plans?.slice(0, isExpanded ? intervals?.plans?.length : 3) : [],
      facts: intervals?.facts ? intervals?.facts?.slice(0, isExpanded ? intervals?.facts?.length : 3) : [],
      donned: intervals?.donned ? intervals?.donned?.slice(0, isExpanded ? intervals?.donned?.length : 3) : [],
      received: intervals?.received ? intervals?.received?.slice(0, isExpanded ? intervals?.received?.length : 3) : [],
      approved: approvedIntervals ? approvedIntervals?.slice(0, isExpanded ? approvedIntervals?.length : 3) : [],
    };
  }, [intervals, isExpanded, approvedIntervals]);

  const planIntervalItems = useMemo(() => {
    if (!intervals && !approvedIntervals) return [];
    return isProgressVariant || isSomeOfHandlersVariant ? intervals?.donned || [] : approvedIntervals || [];
  }, [intervals, isProgressVariant, isSomeOfHandlersVariant, approvedIntervals]);

  const factIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant || isSomeOfHandlersVariant ? intervals?.received || [] : intervals.facts || [];
  }, [intervals, isProgressVariant, isSomeOfHandlersVariant]);

  const inProductionIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant ? intervals?.facts || [] : intervals?.plans || [];
  }, [intervals, isProgressVariant]);

  const handleSetOverviewState = useCallback(() => {
    if (!expenditure.indicators && expenditure.estimate_amount) {
      setOverviewState({
        all:
          (variant === VARIANTS.HANDLER && expenditure.estimate_amount_source_before_coefficient) || isExpendituresGroup
            ? expenditure.amount
            : expenditure.estimate_amount,
        completed: 0,
        accepted: 0,
        count_completed: 0,
        count_accepted: 0,
      });
    }
    if (!expenditure.indicators) return;
    setOverviewState({
      all:
        (variant === VARIANTS.HANDLER && expenditure.estimate_amount_source_before_coefficient) ||
        expenditure.indicators.estimate_amount,
      completed: expenditure.indicators.work_completed,
      accepted: expenditure.indicators.work_accepted,
      count_completed: expenditure.indicators.count_completed,
      count_accepted: expenditure.indicators.count_accepted,
    });
  }, [expenditure.indicators, isExpendituresGroup, variant]);

  const handleCheck = useCallback(() => check(!isChecked), [check, isChecked]);

  const isSharedToMyContractor = isExpenditureSharedToMyContractor(expenditure);

  const isSharedToMeButCantAddIntervals = isExpenditureSharedToMeButCantAddInterval(expenditure);

  const isHideCheckbox = activeTab === TabsEnum.plan && expenditure.expenditure_type !== "work";

  const canAddIntervals =
    expenditure.expenditure_type === EXPENDITURE_TYPES.WORK &&
    addIntervalChecker({
      activeTab: variant,
      haveAddingPlanPermission: permissions?.canAddPlan && !isSharedToMyContractor && !isSharedToMeButCantAddIntervals,
      haveAddingProgressPermission:
        permissions?.canAddProgress && !isSharedToMyContractor && !isSharedToMeButCantAddIntervals,
      moduleRoute,
    });

  const onExpand = useCallback(() => setIsExpanded((prevState) => !prevState), []);

  useEffect(() => {
    setIsExpanded(false);
  }, [variant]);

  const handleShowGroupModal = useCallback(() => {
    setIsShowGroupModal(true);
  }, []);

  const handleCloseGroupModal = useCallback(() => {
    setIsShowGroupModal(false);
  }, []);

  const groupedPlanGroupIntervals = useMemo(() => groupBy(planIntervals, (item) => item.week), [planIntervals]);
  const groupedFactGroupIntervals = useMemo(() => groupBy(factIntervals, (item) => item.week), [factIntervals]);

  const reducedPlanGroupIntervals = useMemo(
    () =>
      Object.values(groupedPlanGroupIntervals).flatMap((item) =>
        item.reduce(
          (prevValue, currentValue, index) => ({
            ...item[index],
            count: prevValue.count + +currentValue?.count,
            start_at:
              moment(currentValue?.start_at).isBefore(prevValue.start_at) || !prevValue.start_at
                ? currentValue?.start_at
                : prevValue.start_at,
            end_at:
              moment(currentValue?.end_at).isAfter(prevValue.end_at) || !prevValue.end_at
                ? currentValue?.end_at
                : prevValue.end_at,
          }),
          {
            count: 0,
            start_at: "",
            end_at: "",
          }
        )
      ) || [],
    [groupedPlanGroupIntervals]
  );

  const reducedFactGroupIntervals = useMemo(
    () =>
      Object.values(groupedFactGroupIntervals).flatMap((item) =>
        item.reduce(
          (prevValue, currentValue, index) => ({
            ...item[index],
            count: +currentValue?.count + prevValue.count,
            start_at:
              moment(currentValue?.start_at).isBefore(prevValue.start_at) || !prevValue.start_at
                ? currentValue?.start_at
                : prevValue.start_at,
            end_at:
              moment(currentValue?.end_at).isAfter(prevValue.end_at) || !prevValue.end_at
                ? currentValue?.end_at
                : prevValue.end_at,
          }),
          {
            count: 0,
            start_at: "",
            end_at: "",
          }
        )
      ) || [],
    [groupedFactGroupIntervals]
  );

  const currentGroupIntervals = useMemo(
    () => (variant === VARIANTS.PROGRESS ? reducedFactGroupIntervals : reducedPlanGroupIntervals),
    [variant, reducedFactGroupIntervals, reducedPlanGroupIntervals]
  );

  const currentInProductionIntervals = useMemo(() => {
    if (variant === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => !item.is_confirmed);
    } else if (variant === VARIANTS.PLAN && reducedPlanGroupIntervals?.length > 0) {
      return reducedPlanGroupIntervals;
    }
    return inProductionIntervalItems;
  }, [variant, reducedFactGroupIntervals, reducedPlanGroupIntervals, inProductionIntervalItems]);

  const currentPlanIntervals = useMemo(() => {
    if (variant === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => item.is_confirmed);
    } else {
      return planIntervalItems;
    }
  }, [variant, planIntervalItems, reducedFactGroupIntervals]);

  const currentFactIntervals = useMemo(() => {
    if (variant === VARIANTS.PROGRESS && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals.filter((item) => item.is_confirmed);
    } else if (variant === VARIANTS.PLAN && reducedFactGroupIntervals?.length > 0) {
      return reducedFactGroupIntervals;
    }
    return factIntervalItems;
  }, [variant, reducedFactGroupIntervals, factIntervalItems]);

  const handleUngroupExpenditure = useCallback(() => {
    dispatch(
      deleteExpendituresGroups(
        objectId,
        expenditure?.id,
        compose(loadSectionExpenditures, loadSectionGroups, handleCloseGroupModal)
      )
    );
  }, [objectId, expenditure, loadSectionExpenditures, loadSectionGroups, handleCloseGroupModal]);

  const handleEditExpenditure = useCallback(
    (data, errorCallback) => {
      dispatch(
        updateExpendituresGroup({
          buildingId: objectId,
          groupId: expenditure.id,
          data,
          callback: compose(loadSectionExpenditures, loadSectionGroups, () => message.success("Успешно обновлено")),
          errorCallback,
        })
      );
    },
    [objectId, expenditure, loadSectionExpenditures, loadSectionGroups]
  );

  const isInProductionIntervalCompleted = useMemo(() => {
    if (factIntervals?.length > 0 || planIntervals?.length > 0) {
      return !currentGroupIntervals?.length && (currentFactIntervals?.length || planIntervalItems?.length);
    }

    if (variant === VARIANTS.PROGRESS) {
      return !slicedIntervals.facts?.length && (slicedIntervals.donned?.length || slicedIntervals.received?.length);
    } else {
      return !slicedIntervals.plans?.length && !slicedIntervals.approveds?.length && slicedIntervals.facts?.length;
    }
  }, [variant, slicedIntervals, factIntervals, planIntervals, planIntervalItems]);

  const isPlanIntervalsCompleted = useMemo(() => {
    if (factIntervals?.length > 0 || planIntervals?.length > 0) {
      return !planIntervalItems?.length && currentFactIntervals?.length;
    }

    if ([VARIANTS.PROGRESS, VARIANTS.HANDLER, VARIANTS.HANDLER_DRAFT].indexOf(variant) !== -1) {
      return !slicedIntervals.donned?.length && slicedIntervals.received?.length && !slicedIntervals.facts?.length;
    } else {
      return !slicedIntervals.approveds?.length && slicedIntervals.facts?.length && !slicedIntervals.plans?.length;
    }
  }, [variant, slicedIntervals, factIntervals, planIntervals]);

  const handleChangeExpenditureCost = useCallback(
    (cost) => {
      changeExpenditureCost(expenditure.id, cost);
    },
    [changeExpenditureCost]
  );

  const handleChangeDiscountCost = useCallback(
    (discountCost) => {
      changeExpenditureDiscount(expenditure.id, discountCost);
    },
    [changeExpenditureDiscount]
  );

  const matchingClickHandler = () => {
    if (!outOfEstimateRelations.length) {
      setRelationOriginInfo({
        id: expenditure.id,
        expenditure_type: expenditure.expenditure_type,
      });
    } else {
      setIsProductInfoOpened(true);
    }
  };

  const onProductInfoClose = useCallback(() => {
    setIsProductInfoOpened(false);
  }, []);

  const productInfoMatchingClickHandler = useCallback(() => {
    setRelationOriginInfo({
      id: expenditure.id,
      estimate_expenditures: outOfEstimateRelations,
      expenditure_type: expenditure.expenditure_type,
    });
    setIsProductInfoOpened(false);
  }, [expenditure]);

  const providers = useMemo(() => expenditure?.providers ?? [], [expenditure]);
  const isHandler = window.location.pathname.includes("handler");
  const isProductionInHandler = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const { isSomeEstimatedPrice, activePricingMode } = usePriceSelectContext();

  const estimatedCost = useMemo(() => {
    /* if (activePricingMode === PricingModes.estimatedPrice) {
      return expenditure.estimated_cost;
    } */
    const target = expenditure.markup_costs?.find((el) => el.markup_name_id === activePricingMode)?.markup_cost;
    return target;
  }, [expenditure.estimated_cost, activePricingMode]);

  return (
    <div
      className={cn(
        styles.container,
        styles[variant],
        { [styles.expanded]: isExpanded, [styles.withIndicator]: isWithIndicator },
        className
      )}
    >
      <div className={styles.content}>
        <div className={styles.numberCol}>
          <div className={styles.number}>
            {!isExpendituresGroup && !isNumberHidden ? expenditure.number || "-" : ""}
            {isProductionInHandler && (
              <Checkbox
                checked={visibilityChecked(expenditure.id)}
                onChange={() => handleVisibilityCheck(expenditure)}
              />
            )}
          </div>
          {canCheck && !isHideCheckbox && <Checkbox checked={isChecked} onChange={handleCheck} />}
          {!isHandler && !canCheck && !!providers.length && (
            <PopoverOverlay
              openType="hover"
              placement="top"
              popoverBorderColor="primary"
              content={
                <div className={styles.providersList}>
                  {providers.map((p) => (
                    <div className={styles.providerItem} key={p.entity_id}>
                      <span className={styles.status}>{p.accepted ? "Передано" : "Ожидает подтверждения"}</span>
                      <span className={styles.providerName}>{p.entity_name}</span>
                    </div>
                  ))}
                </div>
              }
            >
              <CheckDouble variant={providers.some((p) => p.accepted) ? "primary" : "default"} />
            </PopoverOverlay>
          )}
        </div>
        <div className={styles.infoCol}>
          <Info
            expenditure={expenditure}
            buildingId={buildingId}
            variant={variant}
            isShared={isShared}
            onOverview={handleSetOverviewState}
            overviewState={overviewState}
            canViewFiles={canViewFiles}
            canAddFiles={permissions.canAddFiles}
            canDeleteFiles={permissions.canDeleteFiles}
            loadSectionExpenditures={loadSectionExpenditures}
            isKSHidden={isKSHidden}
            onReplacementIconClick={onReplacementIconClick}
            isExpendituresGroup={isExpendituresGroup}
            handleShowGroupModal={handleShowGroupModal}
            isPopupsHidden={isPopupsHidden}
            isFileHidden={isFileHidden}
            onFileDirectlyClick={onFileDirectlyClick}
            isProductionInHendler={isProductionInHandler}
          />
          {isHandlerProductionVariant && isOutOfEstimate && (
            <>
              <MatchingIcon
                className={styles.matching}
                onClick={matchingClickHandler}
                status={
                  outOfEstimateRelations.length ? MatchingStatusesEnum.manual : MatchingStatusesEnum.outOfEstimate
                }
              />
              <RelationsInfoModal
                isOpen={isProductInfoOpened}
                onClose={onProductInfoClose}
                matchingClickHandler={productInfoMatchingClickHandler}
                expendituresIds={outOfEstimateRelations}
                objectId={objectId}
              />
            </>
          )}
        </div>
        <div className={styles.countCol}>
          <div>
            {expenditureDisplayedCount}
            <br />
            <span title={expenditureDisplayedCount + " (" + expenditure.measure + ")"} className={styles.measure}>
              ({expenditure.measure})
            </span>
          </div>
          {!isPopupsHidden && !isHandlerDraftVariant && (
            <ChartPopup
              type="info"
              containerClassName={styles.infoContainer}
              onHover={handleSetOverviewState}
              accepted={overviewState.count_accepted}
              all={expenditureDisplayedCount}
              completed={overviewState.count_completed}
            />
          )}
        </div>
        {variant !== VARIANTS.PRICING_MODE_BUDGET && !isSomeEstimatedPrice && (
          <DefaultColumns
            variant={variant}
            expenditure={expenditure}
            loadProduct={loadProduct}
            expenditureCount={expenditureDisplayedCount}
            currentInProductionIntervals={currentInProductionIntervals}
            isExpendituresGroup={isExpendituresGroup}
            buildingId={buildingId}
            onExpand={onExpand}
            groupedPlanGroupIntervals={groupedPlanGroupIntervals}
            groupedFactGroupIntervals={groupedFactGroupIntervals}
            loadSectionGroups={loadSectionGroups}
            canAddIntervals={canAddIntervals}
            isInProductionIntervalCompleted={isInProductionIntervalCompleted}
            isShared={isShared}
            isExpanded={isExpanded}
            planIntervals={currentPlanIntervals}
            isPlanIntervalsCompleted={isPlanIntervalsCompleted}
            lsrPlanData={lsrPlanData}
            canDeleteFiles={permissions.canDeleteFiles}
            factIntervals={currentFactIntervals}
            directlyAction={directlyAction}
            sectionName={sectionName}
            sectionId={sectionId}
            actions={actions}
          />
        )}
        {variant === VARIANTS.PRICING_MODE_BUDGET && (
          <BudgetColumns
            amountSource={expenditure.indicators.estimate_amount_source}
            discountCost={expenditure.discount_cost}
            discountPercent={expenditure.discount_percent.toString()}
            changeDiscountCost={handleChangeDiscountCost}
            quantity={Number(expenditure.count)}
          />
        )}
        {isSomeEstimatedPrice && (
          <EstimatedPriceColumns
            budget={expenditure.price * expenditureDisplayedCount}
            count={expenditureDisplayedCount}
            estimatedCost={estimatedCost}
            amountSource={expenditure.indicators.estimate_amount_source}
            changeEstimatedCost={handleChangeExpenditureCost}
            quantity={Number(expenditure.count)}
          />
        )}
      </div>
      {isShowGroupModal && (
        <ExpenditureGroupModal
          isOpen={isShowGroupModal}
          onClose={handleCloseGroupModal}
          group={expenditure}
          isShowButtons={true}
          handleUngroup={handleUngroupExpenditure}
          handleEdit={handleEditExpenditure}
        />
      )}
      {relationOriginInfo && (
        <AddRelationsModal
          relationOriginInfo={relationOriginInfo}
          objectId={objectId}
          handleClose={() => {
            setRelationOriginInfo(null);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Expenditure);
