import { useMemo } from "react";
import { useSelector } from "react-redux";

import { journalTicketsInvalidateKeySelector } from "redux/modules/common/building/journal/selectors";
import { sectionsLoadingByKeySelector, sectionsSelector } from "redux/modules/common/building/sections/sections";

import ISection from "types/interfaces/Section";

import { useTypedParams } from "utils/hooks/useTypedParams";

export const _TEMP_useBuildingPageSections = () => {
  const { objectId } = useTypedParams();

  const sectionsFromSelector: ISection[] = useSelector(sectionsSelector);
  const rawSectionsAreLoading: boolean | undefined = (useSelector(sectionsLoadingByKeySelector) as any)?.[objectId];
  const sectionsAreLoading = rawSectionsAreLoading === undefined ? true : rawSectionsAreLoading;

  const sections = useMemo(() => {
    return (sectionsFromSelector || []).filter((el) => {
      if (el.name === "Вне сметы") return true;
      // @ts-ignore
      return !!el.subsection_count || !!el.subsegments?.length;
    });
  }, [sectionsFromSelector]);

  const isSectionsExist = useMemo(
    () => sections && sections.length > 0 && sections.filter((el) => el.hidden_status !== 1).length > 0,
    [sections]
  );

  return {
    sectionsAreLoading,
    sections,
    isSectionsExist,
  };
};
