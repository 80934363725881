import React from "react";
import { useSelector } from "react-redux";

import {
  chartActionsSelector,
  chartIsAttributesSelector,
  chartTabSelector,
} from "redux/modules/common/chart/selectors";
import { CHART_TABS, IChartTree } from "redux/modules/common/chart/types";

import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import CurrencyPopover from "components/pages/Manufacturing/components/ProjectsTree/components/Popovers/CurrencyPopover/CurrencyPopover";
import InfoPopover from "components/pages/Manufacturing/components/ProjectsTree/components/Popovers/InfoPopover/InfoPopover";

import ChartTreeBubbleWorkerInfo from "./ChartTreeBubbleWorkerInfo/ChartTreeBubbleWorkerInfo";

import { CHART_TREE_LVL } from "../../constants";

import { useChartCreatePlanModal } from "../../hooks/useChartCreatePlanModal";

import { mergeIndicators } from "./ChartTreeBubbles.utils/mergeIndicators";

import { ReactComponent as IconPlus } from "shared/assets/icons/IconPlus.svg";

import styles from "../ChartTree/ChartTree.module.scss";

export interface IChartTreeBubblesProps {
  tree: IChartTree;
}

const ChartTreeBubbles: React.FC<IChartTreeBubblesProps> = ({ tree }) => {
  const tab = useSelector(chartTabSelector);
  const diagramActinos = useSelector(chartActionsSelector);
  const isAttributes = useSelector(chartIsAttributesSelector);
  /** Нельзя создавать планы на проект из графика */
  const isPlanEditing =
    diagramActinos?.plans_editing_enabled &&
    tree.lvl !== CHART_TREE_LVL.PROJECT &&
    (isAttributes ? !tree.isSection : true);
  const { createPlanModal, openCretePlanModalOpen } = useChartCreatePlanModal({ tree });

  if (isPlanEditing) {
    const onPlusIconClick = (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      openCretePlanModalOpen();
    };
    return (
      <>
        <div className={styles.bubbleAddPlan}>
          <PopoverOverlay
            openType="hover"
            placement="top"
            popoverBorderColor="primary"
            content={<div className={styles.bubbleAddPlanPopover}>Добавить в план</div>}
          >
            <IconPlus onClick={onPlusIconClick} />
          </PopoverOverlay>
        </div>
        {createPlanModal}
      </>
    );
  }

  const counts: Record<CHART_TABS, number> | undefined = {
    workers: tree?.count ? Number(tree.count) : 0,
    work: tree?.count_expenditures ? tree.count_expenditures.work : 0,
    materials: tree?.count_expenditures ? tree.count_expenditures.material : 0,
    /** Мим = Машины + Транспорт */
    mim: tree?.count_expenditures ? tree.count_expenditures.machine + tree.count_expenditures.transport : 0,
    equipment: tree?.count_expenditures ? tree.count_expenditures.equipment : 0,
    /** Ресурсы = Материал + Оборудование + Машины + Транспорт */
    resources: tree?.count_expenditures
      ? tree.count_expenditures.transport +
        tree.count_expenditures.equipment +
        tree.count_expenditures.machine +
        tree.count_expenditures.transport
      : 0,
  };

  const indicators = {
    estimate_amount: Number(tree.estimate_amount),
    count: tree.count,
    ...mergeIndicators(tree, tab),
  };

  if (tab === CHART_TABS.WORKERS) {
    return (
      <>
        {tree.lvl > CHART_TREE_LVL.SECTION ? (
          <div className={styles.bubbleInfo}>
            <ChartTreeBubbleWorkerInfo buildingId={tree.projectId} workerId={tree.id} />
          </div>
        ) : null}
        {counts && tab && counts[tab] > 0 ? (
          <div className={styles.bubbleCount}>
            <div className={styles.count}>{counts[tab]}</div>
          </div>
        ) : null}
      </>
    );
  }

  return (
    <>
      <div className={styles.bubbleCurrency}>
        {/* @ts-ignore */}
        <CurrencyPopover indicators={indicators} measure={tree.measure} />
      </div>
      {tree.lvl > CHART_TREE_LVL.SECTION ? (
        <div className={styles.bubbleInfo}>
          {/* @ts-ignore */}
          <InfoPopover indicators={indicators} measure={tree.measure} />
        </div>
      ) : null}
      {counts && tab && counts[tab] > 0 ? (
        <div className={styles.bubbleCount}>
          <div className={styles.count}>{counts[tab]}</div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(ChartTreeBubbles);
