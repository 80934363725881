export default function CheckDouble({ fill = "#4FB1EB", className = "", variant = "primary" }) {
  if (variant === "primary") fill = "#4FB1EB";
  if (variant === "default") fill = "#DCDCDC";
  let secondFill = fill;
  if (variant === "semi") fill = "#4FB1EB";
  if (variant === "semi") secondFill = "#DCDCDC";
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.53452 25.6715L1.07129 20.2083C0.792766 19.9297 0.63629 19.552 0.63629 19.1581C0.63629 18.7642 0.792765 18.3864 1.07129 18.1079C1.34982 17.8294 1.72758 17.6729 2.12148 17.6729C2.51537 17.6729 2.89313 17.8294 3.17166 18.1079L7.79265 22.7289L18.8002 11.7214C19.0787 11.4428 19.4565 11.2864 19.8504 11.2864C20.2443 11.2864 20.622 11.4428 20.9006 11.7214C21.1791 11.9999 21.3356 12.3776 21.3356 12.7715C21.3356 13.1654 21.1791 13.5432 20.9006 13.8217L9.06821 25.6541C8.93122 25.8651 8.7439 26.0388 8.52312 26.1595C8.30233 26.2802 8.055 26.3441 7.80339 26.3455C7.55177 26.3469 7.30375 26.2857 7.08165 26.1674C6.85955 26.0492 6.67034 25.8776 6.53103 25.668L6.53452 25.6715Z"
        fill={fill}
      />
      <path
        d="M16.5603 25.6715L11.097 20.2083C10.8185 19.9297 10.662 19.552 10.662 19.1581C10.662 18.7642 10.8185 18.3864 11.097 18.1079C11.3756 17.8294 11.7533 17.6729 12.1472 17.6729C12.5411 17.6729 12.9189 17.8294 13.1974 18.1079L17.8184 22.7289L28.8259 11.7214C29.1045 11.4428 29.4822 11.2864 29.8761 11.2864C30.27 11.2864 30.6478 11.4428 30.9263 11.7214C31.2048 11.9999 31.3613 12.3776 31.3613 12.7715C31.3613 13.1654 31.2048 13.5432 30.9263 13.8217L19.094 25.6541C18.957 25.8651 18.7697 26.0388 18.5489 26.1595C18.3281 26.2802 18.0808 26.3441 17.8291 26.3455C17.5775 26.3469 17.3295 26.2857 17.1074 26.1674C16.8853 26.0492 16.6961 25.8776 16.5568 25.668L16.5603 25.6715Z"
        fill={secondFill}
      />
    </svg>
  );
}
