import cn from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import MonthScrollBar from "../../../../../components/UI/_TODO/MonthScrollBar/MonthScrollBar";
import PopoverOverlay from "components/UI/_TODO/PopoverOverlay/PopoverOverlay";
import Select from "components/UI/atoms/Select";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { downloadForecastReport } from "pages/Finance/Forecast/model/thunks";
import FinanceSettingsModal from "pages/Finance/Forecast/widgets/FinanceSettingsModal/index";
import { downloadSummaryReport } from "pages/Finance/Summary/model/thunks";

import MeasureBtn from "../../../../../features/financeMeasure/MeasureBtn/MeasureBtn";
import { useFinanceSummaryMonthScrollBar } from "../../../_TODO/useFinanceSummaryMonthScrollBar";
import BdrModal from "./BdrModal/BdrModal";
import DownloadFinanceReportButton from "./DownloadFinanceReportButton/DownloadFinanceReportButton";

import {
  VIEW_FINANCE_SECTION_DOWNLOAD_BDR,
  VIEW_FINANCE_SECTION_DOWNLOAD_FORECAST,
  VIEW_FINANCE_WARRANTIES_AND_ADVANCE_PAYMENTS,
} from "../../../../../constants/permissions/constructingPermissions";

import usePermission from "../../../../../hooks/usePermission";

import { ReactComponent as SettingsIcon } from "shared/assets/icons/Union.svg";

import styles from "./SummaryOrForecastHeadline.module.scss";

interface IProps {
  location: "summary" | "forecast";
  onSettingsClick?: () => void;
}
//TODO separate between forecast and summary
function SummaryOrForecastHeadline(props: IProps) {
  const objectId = +useObjectId();
  const dispatch = useDispatch();
  const { location = "summary" } = props;
  const { year, handleYearChange, setTableScrollPosition, tableScrollPosition, tableViewWidthPercent } =
    useFinanceSummaryMonthScrollBar();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);

  const hasDownloadBdrPermission = usePermission(VIEW_FINANCE_SECTION_DOWNLOAD_BDR);
  const hasDownloadForecastPermission = usePermission(VIEW_FINANCE_SECTION_DOWNLOAD_FORECAST);
  const hasFinanceSettingsPermission = usePermission(VIEW_FINANCE_WARRANTIES_AND_ADVANCE_PAYMENTS);

  const downloadReport = () => {
    if (location === "summary") {
      dispatch(downloadSummaryReport(objectId));
    }
  };

  const [isBdr, setIsBdr] = useState(false);
  const onDownloadForecastReport = (type: "forecast" | "bdr") => {
    if (type === "forecast") {
      dispatch(downloadForecastReport(objectId));
    }
    if (type === "bdr") {
      setIsBdr(true);
    }
  };

  const isSelectVisible =
    hasDownloadBdrPermission && hasDownloadForecastPermission && !!objectId && location === "forecast";

  const isDownloadBdrButtonVisibleForAllProjects = hasDownloadBdrPermission && !objectId && location === "forecast";
  const isDownloadBdrButtonVisibleForOneProject =
    hasDownloadBdrPermission && !hasDownloadForecastPermission && !!objectId && location === "forecast";

  const isDownloadBdrButtonVisible =
    isDownloadBdrButtonVisibleForAllProjects || isDownloadBdrButtonVisibleForOneProject;

  const isDownloadForecastButtonVisible =
    hasDownloadForecastPermission && !hasDownloadBdrPermission && !!objectId && location === "forecast";

  const isFinanceSettingsVisible =
    (!!objectId && hasFinanceSettingsPermission && location === "forecast") || (!!objectId && location === "summary");

  return (
    <div className={styles.headline}>
      <MonthScrollBar
        tableScrollPosition={tableScrollPosition as any}
        tableViewWidthPercent={tableViewWidthPercent as number}
        setTableScrollPosition={setTableScrollPosition as any}
        handleYearChange={handleYearChange as any}
        year={year as number}
      />
      {isFinanceSettingsVisible && (
        <PopoverOverlay
          popoverBorderColor="primary"
          //placement="top"
          placement="bottom"
          openType="hover"
          content={<div className={styles.tooltipSettings}>Настройки финансов</div>}
        >
          <SettingsIcon
            className={styles.settingsIcon}
            onClick={() => {
              if (props.onSettingsClick) {
                props.onSettingsClick();
                return;
              }
              setIsSettingsModalOpen(true);
            }}
          />
        </PopoverOverlay>
      )}
      {!!objectId && location === "summary" && <DownloadFinanceReportButton onClick={downloadReport} />}
      {isSelectVisible ? (
        <Select
          containerClassName={styles.downloadSelect}
          placeholder={<div className={styles.downloadPlaceholder}>Скачать</div>}
          options={[
            { id: "forecast", name: "Прогноз" },
            { id: "bdr", name: "БДР" },
          ]}
          onChange={onDownloadForecastReport as any}
        />
      ) : (
        <>
          {isDownloadBdrButtonVisible && (
            <DownloadFinanceReportButton onClick={() => onDownloadForecastReport("bdr")} text="БДР" />
          )}
          {isDownloadForecastButtonVisible && (
            <DownloadFinanceReportButton onClick={() => onDownloadForecastReport("forecast")} text="Прогноз" />
          )}
        </>
      )}
      <MeasureBtn className={cn(cn(styles.measureBtn, { [styles.mlAuto]: !objectId }))} />
      <BdrModal isOpen={isBdr} onClose={() => setIsBdr(false)} buildingId={objectId} />
      {isSettingsModalOpen && (
        <FinanceSettingsModal
          isModalOpen={isSettingsModalOpen}
          onClose={() => setIsSettingsModalOpen(false)}
          buildingId={objectId}
          year={year}
        />
      )}
    </div>
  );
}

export default SummaryOrForecastHeadline;
