import { IActions } from "app/store/store";

import { IHandlerCoefficient } from "./types";

export const handlerCoefficientsActions = {
  setcoefficients: (sectionId: number, coefficients: IHandlerCoefficient[]) =>
    ({
      type: "handlerCoefficients/SET_COEFFICIENTS",
      payload: { sectionId, coefficients },
    } as const),
  setLoading: (key: string, status: boolean) =>
    ({
      type: "handlerCoefficients/SET_LOADING",
      payload: { key, status },
    } as const),
  invalidateKey: () =>
    ({
      type: "handlerCoefficients/INVALIDATE",
      payload: true,
    } as const),
};

export type IHandlerCoefficientsActions = IActions<typeof handlerCoefficientsActions>;
