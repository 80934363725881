import { message } from "antd";
import { Dispatch } from "redux";

import { attributesApi } from "./api";
import { attributesActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IAttribute } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getSegments = (objectId: string) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().settingsReducer.attributesReducer.segments[objectId]?.length) {
    dispatch(attributesActions.setIsLoading({ key: objectId, status: true }));
  }
  attributesApi
    .getList(objectId)
    .then(({ data }) => {
      dispatch(attributesActions.setSegments({ key: objectId, data: data.results }));
    })
    .finally(() => {
      dispatch(attributesActions.setIsLoading({ key: objectId, status: false }));
    });
};

export const postSegment =
  (objectId: string, name: string, subSegments: { val: string }[]) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(attributesActions.setIsLoading({ key: "create", status: true }));
    try {
      const { data } = await attributesApi.post(objectId, { name });
      await attributesApi.bulkCreate(
        objectId,
        subSegments.filter((el) => el.val).map((el) => ({ name: el.val, parent: data.id }))
      );
      dispatch(attributesActions.invalidateKey());
      message.success("Сохранено");
    } catch (e) {
      errorCatcher(e as any);
    } finally {
      dispatch(attributesActions.setIsLoading({ key: "create", status: false }));
    }
  };

export const deleteAttribute =
  (objectId: string, id: number, successCb?: () => void) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(attributesActions.setIsLoading({ key: "delete", status: true }));
    attributesApi
      .delete(objectId, id)
      .then(() => {
        dispatch(attributesActions.invalidateKey());
        successCb?.();
        message.success("Удалено");
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(attributesActions.setIsLoading({ key: "delete", status: false }));
      });
  };

export const editAttribute =
  (
    objectId: string,
    id: number,
    data: IAttribute,
    shouldBeDeleted: Record<string, boolean>,
    newAttrs: IAttribute["subsegments"],
    successCb?: () => void
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(attributesActions.setIsLoading({ key: "edit", status: true }));
    try {
      await attributesApi.patch(objectId, id, { name: data.name });
      const patchReqs = data.subsegments
        .filter((el) => !shouldBeDeleted[el.id])
        .map((el) => attributesApi.patch(objectId, el.id, { name: el.name, parent: id }));
      const deleteReqs = Object.entries(shouldBeDeleted)
        .filter(([_, shouldBeDeleted]) => shouldBeDeleted)
        .map(([id]) => attributesApi.delete(objectId, +id));
      const createReqs = newAttrs.map((el) => attributesApi.post(objectId, { ...el, parent: id }));
      await Promise.all([...patchReqs, ...deleteReqs, ...createReqs]);
      dispatch(attributesActions.invalidateKey());
      message.success("Сохранено");
      successCb?.();
    } catch (e) {
      errorCatcher(e as any);
    } finally {
      dispatch(attributesActions.setIsLoading({ key: "edit", status: false }));
    }
  };
