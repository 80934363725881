import { message } from "antd";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { COUNT_MAX_DECIMALS_PLANS_FACTS_EDITING } from 'constants/forms';
import { apiAddFactInterval } from "redux/modules/common/building/processApi";

import AddMimsInModal from "components/UI/_TODO/AddingListInModal/AddMimsInModal";
import { useExpendituresInGroups } from "components/UI/_TODO/AddingListInModal/hooks/useExpendituresInGroups";
import InputNumber from "components/UI/atoms/InputNumber";

import ExpandableFilesInput from "../../../../../../shared/ui/inputs/ExpandableFileInput/ExpandableFileInput";
import AddMaterialsInModal from "../../../AddingListInModal/AddMaterialsInModal";
import AddWorkersInModal from "../../../AddingListInModal/AddWorkersInModal";
import Button from "shared/ui/controls/ButtonBase";
import CalendarRange from "shared/ui/inputs/CalendarRange/CalendarRange";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";
import { CreateAxesAndMarks, useCreateAxes } from "widgets/AxesAndMarks";

import { useSelectingMaterials } from "../../../AddingListInModal/hooks/useSelectingMaterials";
import { useSelectingMims } from "../../../AddingListInModal/hooks/useSelectingMims";
import { useSelectingWorkers } from "../../../AddingListInModal/hooks/useSelectingWorkers";
import { useSimpleResources } from "../../../AddingListInModal/hooks/useSimpleResources";

import styles from "./CreateFactModal.module.scss";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  product: any; // TODO: delete any
  objectId: number | string;
  selectedWorkGroupId?: number | null;
}

const CreateFactModal: FC<IProps> = ({ isOpen, handleClose, product, objectId, selectedWorkGroupId }) => {
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [count, setCount] = useState("0");
  const [files, setFiles] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const handleCloseModal = () => {
    handleClose();
    clearMaterials();
    clearMims();
    clearWorkers();
  };

  useEffect(() => {
    if (!isOpen) setIsPending(false);
  }, [isOpen]);

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers,
  } = useSelectingWorkers();

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    clearMaterials,
  } = useSelectingMaterials();

  const {
    confirmMimsHandler,
    cancelMimsHandler,
    changeCountMimsHandler,
    validateSubmittingMims,
    deleteAddedHandler: deleteAddedMims,
    selectMimsHandler,
    addedMims,
    selectedMims,
    selectedMimsIds,
    addedMimsIds,
    clearMims,
  } = useSelectingMims();

  const {
    materials: simpleMaterials,
    mims: simpleMims,
    deleteResource: deleteSimpleResource,
    updateResource: updateSimpleResource,
    createNewResource: createNewSimpleResource,
    isMaterialAdding: isSimpleMaterialAdding,
    isMimAdding: isSimpleMimAdding,
    toggleIsEditing: toggleIsSimpleResourceEditing,
    onSubmit: onSimpleResourcesSubmit,
    isValidForSubmit: validateSimpleResources,
  } = useSimpleResources();

  const handleSubmit = async () => {
    if (isAxesNotSubmitted) {
      message.info("Подтвердите оси и отметки");
      return;
    }
    const hasValidWorkers = validateSubmitting();
    const hasValidMaterials = validateSubmittingMaterials();
    const hasValidMims = validateSubmittingMims();
    const hasValidSimpleResources = validateSimpleResources();
    if (hasValidWorkers && hasValidMaterials && hasValidMims && hasValidSimpleResources) {
      setIsPending(true);
      const data = {
        start_at: moment(dateStart).format("YYYY-MM-DD"),
        end_at: moment(dateEnd).format("YYYY-MM-DD"),
        count: count || 0,
        workers: addedUsers,
        expenditure_id: product.id,
        stock_using_materials: addedMaterials.map((el) => ({
          /* @ts-ignore */
          stock_using_material_id: el.using_ids[0],
          count: el.local_count,
        })) /* @ts-ignore */,
        ticket_services: addedMims.map((el) => ({ service_id: el.service_id, count: el.local_count })),
      };

      if (addedUsers) {
        /* @ts-ignore */
        data.count_workers = addedUsers.map((item) => {
          /* @ts-ignore */
          return { worker_id: item?.id, count: item?.count };
        });
      }
      /* @ts-ignore */
      await apiAddFactInterval(objectId, product.id, data, false, {
        successCall: (response: any) => {
          /* @ts-ignore */
          if (response?.data?.id) {
            /* @ts-ignore */
            onSimpleResourcesSubmit({ fact_work: response?.data?.id });
          }
          onSaveAxes(response?.data?.id!);
          handleClose();
        },
        files,
        finallyCall: () => {
          setIsPending(false);
        },
      });
    }
  };

  const isSaveButtonDisabled = useMemo(
    () => !count || (+count === 0 && addedMaterials?.length <= 0 && addedMims?.length <= 0),
    [count, addedMaterials, addedMims]
  );

  const {
    addedExpenditures,
    changeExpendituresCountHandler,
    validateSubmittingExpenditures,
    createGroupExpendituresPlan,
    workGroupMaterials,
    workGroupMims,
    clearExpendituresInGroups,
  } = useExpendituresInGroups({
    isOpen /* @ts-ignore */,
    selectedWorkGroupId,
    count /* @ts-ignore */,
    setCount,
    isPlan: false,
  });

  const expendituresIds = useMemo(() => {
    if (selectedWorkGroupId) {
      return addedExpenditures?.map((el) => el.id) ?? [];
    }
    return [product.id];
  }, [selectedWorkGroupId, addedExpenditures, product.id]);

  const { onSaveAxes, onSubmit, savingAxes, isAxesNotSubmitted, setAxesDirty } = useCreateAxes({
    planOrFact: "fact",
    groupOrWork: selectedWorkGroupId ? "group" : "work",
  });

  return (
    <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
      <div className={styles.container}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>Добавить в факт</div>
          <div className={styles.subTitle} title={product.name}>
            {product.name}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.hiddenWrapper}>
            <div className={styles.fieldTitle}>Количество</div>
            <div className={styles.fieldValue}>
              {/* @ts-ignore */}
              <InputNumber value={count} setValue={setCount} decimalPlace={COUNT_MAX_DECIMALS_PLANS_FACTS_EDITING} />
            </div>
            <div className={styles.fieldTitle}>Период выполнения</div>
            <div className={styles.fieldRow}>
              <div className={styles.fieldCalendar}>
                <CalendarRange
                  defaultDateStart={dateStart} /* @ts-ignore */
                  setDefaultDateStart={setDateStart}
                  defaultDateEnd={dateEnd} /* @ts-ignore */
                  setDefaultDateEnd={setDateEnd}
                />
              </div>
            </div>
            {isOpen && (
              <AddWorkersInModal
                addedUsers={addedUsers}
                selectedUsers={selectedUsers}
                onAccept={confirmHandler}
                onDecline={cancelHandler}
                onChangeCount={changeCountHandler}
                onSelect={selectHandler}
                selectedIds={selectedUsersIds}
                onDelete={deleteAddedHandler}
                addedIds={addedUsersIds}
              />
            )}

            {isOpen && (
              <AddMaterialsInModal
                addedMaterials={addedMaterials} /* @ts-ignore */
                selectedUsers={selectedMaterials}
                onAccept={confirmMaterialsHandler}
                onDecline={cancelMaterialsHandler}
                onChangeCount={changeCountMaterialsHandler}
                onSelect={selectMaterialsHandler}
                selectedIds={selectedMaterialsIds}
                onDelete={deleteAddedMaterial}
                addedIds={addedMaterialsIds}
                activeSection={product.section_id}
                activeWork={product.id}
                isWorkGroup={!!selectedWorkGroupId}
                simpleResources={simpleMaterials} /* @ts-ignore */
                createNewSimpleResource={createNewSimpleResource}
                deleteSimpleResource={deleteSimpleResource}
                isSimpleResourceAdding={isSimpleMaterialAdding}
                toggleIsSimpleResourceEditing={toggleIsSimpleResourceEditing}
                updateSimpleResource={updateSimpleResource}
              />
            )}

            {isOpen && (
              <AddMimsInModal
                addedMims={addedMims} /* @ts-ignore */
                selectedMims={selectedMims}
                onAccept={confirmMimsHandler}
                onDecline={cancelMimsHandler}
                onChangeCount={changeCountMimsHandler}
                onSelect={selectMimsHandler}
                selectedIds={selectedMimsIds}
                onDelete={deleteAddedMims}
                addedIds={addedMimsIds}
                activeSection={product.section_id}
                activeWork={product.id}
                isWorkGroup={!!selectedWorkGroupId}
                simpleResources={simpleMims} /* @ts-ignore */
                createNewSimpleResource={createNewSimpleResource}
                deleteSimpleResource={deleteSimpleResource}
                isSimpleResourceAdding={isSimpleMimAdding}
                toggleIsSimpleResourceEditing={toggleIsSimpleResourceEditing}
                updateSimpleResource={updateSimpleResource}
              />
            )}
            <CreateAxesAndMarks
              isOpen={isOpen}
              expenditureIds={expendituresIds}
              onSubmit={onSubmit}
              savingAxes={savingAxes}
              setIsDirty={setAxesDirty}
            />
            <ExpandableFilesInput files={files} setFiles={setFiles} canExtractFiles={false} />
          </div>
        </div>

        <div className={styles.actionBlock}>
          <Button type="button" medium secondary disabled={false} onClick={handleCloseModal}>
            Отменить
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button medium primary onClick={handleSubmit} disabled={isSaveButtonDisabled} isLoading={isPending}>
            Сохранить
          </Button>
        </div>
      </div>
    </SliderModal>
  );
};

export default CreateFactModal;
