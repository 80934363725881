import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { deleteSection } from "redux/modules/common/building/materials/specification";

import BottomSliderMaterials from "../../../../ui/BottomSliderMaterials";
import Chapter from "../../../../ui/Chapter";
import ModalDelete from "../../../../ui/ModalDelete";

import CrossRoundedIcon from "images/icons/CrossRoundedIcon";

import styles from "./index.module.scss";

const Section = ({ data, objectId, blockOpenSubSections, canEdit, count }) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const openModalDeleteChapter = (e) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const closeModalAddChapter = (e) => {
    e?.stopPropagation();
    setIsAddModalOpen(false);
  };

  const removeSection = (id) => {
    dispatch(deleteSection(objectId, id));
    setIsDeleteModalOpen(false);
  };

  return (
    <div onClick={() => blockOpenSubSections(data)}>
      <BottomSliderMaterials isOpen={isAddModalOpen} closeFunc={closeModalAddChapter}>
        <Chapter
          name={data.name}
          id={data.id}
          objId={objectId}
          label="подраздел"
          close={(e) => closeModalAddChapter(e)}
        />
      </BottomSliderMaterials>
      {isDeleteModalOpen && (
        <ModalDelete
          id={data.id}
          title={data.name}
          deleteSection={removeSection}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      )}
      <div className={styles.container}>
        <div className={styles.name}>{data?.name}</div>
        <div className={styles.count}>
          <span>Материалы: </span>
          {count}
        </div>
        {canEdit && (
          <div className={styles.actions} onClick={openModalDeleteChapter}>
            <div>
              <CrossRoundedIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section;
