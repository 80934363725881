import cn from "classnames";
import React from "react";

import styles from "./MultipleButton.module.scss";

export interface MultipleButtonProps {
  /** Несколько кнопок button (ButtonBase) */
  children: React.ReactNode;
  border?: boolean;
}

const MultipleButton: React.FC<MultipleButtonProps> = ({ children, border }) => {
  return <div className={cn(styles.multipleButtonWrapper, { [styles.separateWithBorder]: border })}>{children}</div>;
};

export default MultipleButton;
