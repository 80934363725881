import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getSpecificationsSections,
  invalidationKeySpecificationSelector,
  isLoadingSpecificationSelector,
  setSpecificationSections,
  specificationSectionsSelector,
} from "redux/modules/common/building/materials/specification";

import Section from "../../components/Section";
import { useObjectId } from "components/pages/Documents/hooks/useObjectId";
import { useMaterialsInvalidationKeyContext } from "components/pages/Materials/common/_MaterialsInvalidationKeyContext/_MaterialsInvalidationKeyContext";
import { SPECIFICATION } from "components/pages/Materials/constants";
import { MaterialsCreateSectionRow } from "components/pages/Materials/widgets/MaterialsCreateSection/MaterialsCreateSection";

import Spinner from "shared/ui/atoms/Spinner/Spinner";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import materialsIcon from "images/icons/navigation/materials.svg";

interface IProps {
  canEdit?: boolean;
}

const SpecificationSections = ({ canEdit }: IProps) => {
  const rootPath = useUrlModuleWithObjectId();
  const history = useHistory();
  const dispatch = useDispatch();
  const objectId = useObjectId();
  //TODO
  const specificationSections = useSelector(specificationSectionsSelector);
  const isLoading = useSelector(isLoadingSpecificationSelector);
  //TODO убрать дублирование
  const { invalidationKey } = useMaterialsInvalidationKeyContext();
  const invalidationKey1 = useSelector(invalidationKeySpecificationSelector);

  useEffect(() => {
    dispatch(getSpecificationsSections(objectId, SPECIFICATION));
    return () => {
      dispatch(setSpecificationSections([]));
    }
  }, [objectId, invalidationKey, invalidationKey1]);

  return (
    <div>
      {" "}
      <MaterialsCreateSectionRow type="section" />
      {isLoading && <Spinner isFixed />}
      {!isLoading && specificationSections?.results && specificationSections?.results?.length === 0 && (
        <EmptyPlaceholder img={materialsIcon} />
      )}
      {!isLoading &&
        specificationSections?.results?.map(
          //TODO
          (item: any) =>
            item.subsections_count > 0 && (
              <Section
                key={item.id}
                objectId={objectId}
                data={item}
                //TODO
                blockOpenSubSections={(item: any) => {
                  //TODO
                  history.push(`/${rootPath}/materials/specification/${item.id}`);
                }}
                canEdit={canEdit}
                count={item.products_count}
              />
            )
        )}
    </div>
  );
};

export default SpecificationSections;
