import axios from "axios";

import {
  IApproveGroupSectionPlanProps,
  IEditGroupPlanIntervalProps,
  IGetGroupIntervalsListProps,
  IGetIntervalsListProps,
  IGroupIntervalResponse,
  IIntervalListItemResponse,
  IPlanIntervalResponse,
  ISectionPlan,
  IdeleteIntervalFileProps,
  IeditPlanIntervalProps,
  IgetIntervalProps,
  IpostIntervalFileProps,
} from "./processTypes";

import { IRemarksList } from "./journal/journalExecution/types";
import { IListResponseData } from "types/ListResponse";
import { IMaterialInAddingInPlan } from "types/interfaces/Materials";
import { IMimsInAddingInPlan } from "types/interfaces/Mims";

import { errorCatcher } from "../../../../utils/helpers/errorCatcher";

export function apiLoadProductsSection(id: number, sectionId: number, editable: boolean, isMaterials = false) {
  return axios
    .get(
      `/building/${id}/${isMaterials ? "materials/" : ""}estimate/${!editable ? "draft/" : ""}items/${sectionId}/` // /, {params: {parent: sectionId}}sections
    )
    .then(({ data }) => data)
    .catch(errorCatcher);
}

interface IaddIntervalConfig {
  successCall: (response: unknown) => void;
  files: unknown[];
  finallyCall: () => void;
}

export function apiAddInterval(
  objectId: number,
  itemId: number,
  data: Record<string, unknown>,
  isMaterials = false,
  { successCall, files, finallyCall }: IaddIntervalConfig
) {
  return axios
    .post<unknown>(`/building/${objectId}/${isMaterials ? "materials/" : ""}estimate/items/${itemId}/intervals/`, {
      ...data,
    })
    .then((response) => {
      if (successCall) {
        successCall(response);
      }
      if (files?.length) {
        const formData = new FormData(); // @ts-ignore
        files?.forEach((file) => formData.append("file", file.file));
        axios.post(
          // @ts-ignore
          `/building/${objectId}/estimate/items/${response.data.expenditure_id}/intervals/${response.data.id}/files/`,
          formData
        );
      }
    })
    .catch(errorCatcher)
    .finally(finallyCall);
}

export function apiAddFactInterval(
  objectId: number,
  itemId: number,
  data: Record<string, unknown>,
  isMaterials = false,
  { successCall, files, finallyCall }: IaddIntervalConfig
) {
  return axios
    .post(`/building/${objectId}/${isMaterials ? "materials/" : ""}estimate/items/${itemId}/fact-intervals/`, {
      ...data,
    })
    .then((response) => {
      if (successCall) {
        successCall(response);
      }
      if (files?.length) {
        const formData = new FormData(); // @ts-ignore
        files?.forEach((file) => formData.append("file", file.file)); // @ts-ignore
        axios.post(
          `/building/${objectId}/estimate/items/${response.data.expenditure_id}/fact-intervals/${response.data.id}/files/`,
          formData
        );
      }
    })
    .catch(errorCatcher)
    .finally(finallyCall);
}

export function apiGetFactInterval(
  objectId: number,
  expenditureId: number,
  factIntervalId: number,
  isMaterials = false
) {
  return axios
    .get(
      `/building/${objectId}/${
        isMaterials ? "materials/" : ""
      }estimate/items/${expenditureId}/fact-intervals/${factIntervalId}/`
    )
    .then((response) => {
      return response;
    })
    .catch(errorCatcher);
}

export function apiGetPlanInterval(
  objectId: number,
  expenditureId: number,
  factIntervalId: number,
  isMaterials = false
) {
  return axios
    .get(
      `/building/${objectId}/${
        isMaterials ? "materials/" : ""
      }estimate/items/${expenditureId}/intervals/${factIntervalId}/`
    )
    .then((response) => response)
    .catch(errorCatcher);
}

export function apiSummaryIndicators(objectId: number) {
  return axios
    .get(`/building/${objectId}/estimate/count/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export const loadPlanIntervals = (
  buildingId: string,
  sectionId: string,
  start_at: string,
  end_at: string,
  all: unknown,
  structure: "sections" | "segments" = "sections"
) => {
  return axios
    .get(`/building/${buildingId}/estimate/plan/${structure}/${sectionId}/week/`, {
      params: { start_at, end_at, all },
    })
    .then(({ data }) => data)
    .catch(errorCatcher);
};

export const loadProgressIntervals = (
  buildingId: number,
  sectionId: number,
  start_at: string,
  end_at: string,
  all: unknown,
  structure: "sections" | "segments" = "sections"
) => {
  return axios
    .get(`/building/${buildingId}/estimate/progress/${structure}/${sectionId}/week/`, {
      params: { start_at, end_at, all },
    })
    .then(({ data }) => data)
    .catch(errorCatcher);
};

export const editPlanInterval = (props: IeditPlanIntervalProps) => {
  return axios.patch(
    `/building/${props.buildingId}/estimate/items/${props.expenditureId}/intervals/${props.intervalId}/`,
    props.data
  );
};

export const getIntervalsList = ({
  buildingId,
  expenditureId,
  activeModule,
  dateStart,
  dateEnd,
}: IGetIntervalsListProps) => {
  return axios.get<IIntervalListItemResponse[]>(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/`,
    {
      params: { start_at__gte: dateStart, start_at__lte: dateEnd },
    }
  );
};

export const getIntervalFiles = ({ buildingId, expenditureId, activeModule, intervalId }: IgetIntervalProps) => {
  return axios.get(`/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/`);
};

export const getIntervalData = ({ buildingId, expenditureId, activeModule, intervalId }: IgetIntervalProps) => {
  return axios.get<IPlanIntervalResponse>(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/`
  );
};

export const postIntervalFile = ({
  buildingId,
  expenditureId,
  activeModule,
  intervalId,
  file,
}: IpostIntervalFileProps) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/`,
    formData
  );
};

export const deleteIntervalFile = ({
  buildingId,
  expenditureId,
  activeModule,
  intervalId,
  fileId,
}: IdeleteIntervalFileProps) => {
  return axios.delete(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/${fileId}/`
  );
};

export const getPlanMaterials = (objectId: number, expenditureId: number, filters: Record<string, string> | null) => {
  return axios.get<IListResponseData<IMaterialInAddingInPlan>>(
    `/building/${objectId}/materials/relates/${expenditureId}/`,
    {
      params: { ...filters, limit: 10 },
    }
  );
};

export const getPlanMims = (objectId: number, expId: number, filters: Record<string, string> | null) => {
  return axios.get<IListResponseData<IMimsInAddingInPlan>>(`/building/${objectId}/materials/relatesmim/${expId}/`, {
    params: { ...filters, limit: 10 },
  });
};

export const createSectionPlan = (objectId: string, data: Record<string, string>) => {
  return axios.post<ISectionPlan>(`/building/${objectId}/estimate/sections/plan-intervals/`, data);
};

export const getSectionPlan = (objectId: string, section_id: number) => {
  return axios.get<ISectionPlan>(`/building/${objectId}/estimate/sections/plan-intervals/${section_id}/`);
};

export const editSectionPlan = (objectId: string, section_id: number, data: { start_at?: string; end_at?: string }) => {
  return axios.patch<ISectionPlan>(`/building/${objectId}/estimate/sections/plan-intervals/${section_id}/`, data);
};

export const approveSectionPlan = (objectId: string | number, sectionPlanId: number) => {
  return axios.post(`/building/${objectId}/estimate/plan/section/approve/${sectionPlanId}/`);
};

export const approveExpenditurePlan = (objectId: string | number, workIntervalId: number) => {
  return axios.post(`/building/${objectId}/estimate/plan/expenditure/approve/${workIntervalId}/`);
};

export const getGroupRemarks = (projectId: number, groupId: number, params: Record<string, unknown> = {}) => {
  return axios.get<IListResponseData<IRemarksList>>(`/building/${projectId}/remarks/`, {
    params: {
      estimate_group_id: groupId,
      ...params,
    },
  });
};

export const getGroupTicketRemarks = (projectId: number, groupFactId: number, params: Record<string, unknown> = {}) => {
  return axios.get<IListResponseData<IRemarksList>>(`/building/${projectId}/ticket_remarks/`, {
    params: {
      group_interval_id: groupFactId,
      ...params,
    },
  });
};

export const getGroupIntervalsList = async ({
  buildingId,
  intervalId,
  activeModule,
}: // dateStart,
// dateEnd,
IGetGroupIntervalsListProps) => {
  const result = await axios.get<IGroupIntervalResponse>(
    `/building/${buildingId}/groups/${activeModule}/${intervalId}/`
  );
  return result;
  // return axios.get<IGroupIntervalResponse>(`/building/${buildingId}/groups/${activeModule}/${expenditureId}/`, {
  //   params: { end_at__after: dateStart, end_at__before: dateEnd },
  // });
};

export const approveGroupPlan = ({ buildingId, planGroupId }: IApproveGroupSectionPlanProps) => {
  return axios.post(`/building/${buildingId}/estimate/plan/group/approve/${planGroupId}/`);
};

export const editGroupPlanInterval = (props: IEditGroupPlanIntervalProps) => {
  return axios.patch(`/building/${props.buildingId}/groups/plans/${props.intervalId}/`, props.data);
};

export const createSectionClosureDates = ({
  planId,
  payment_date,
  objectId,
  percent,
  amount,
}: {
  planId: number;
  objectId: number | string;
  payment_date: string;
  percent?: string;
  amount?: string;
}) =>
  axios.post(`/building/${objectId}/estimate/sections/plan-intervals/close_dates/`, {
    plan: planId,
    payment_date: payment_date,
    user_percent: percent,
    amount,
  });

interface IPatchSectionClosureDateArgs {
  objectId: number | string;
  planId: number;
  dateId: number;
  paymentDate: string;
  userPercent: string;
}
export const patchSectionClosureDate = ({
  objectId,
  planId,
  paymentDate,
  userPercent,
  dateId,
}: IPatchSectionClosureDateArgs) =>
  axios.patch(`/building/${objectId}/estimate/sections/plan-intervals/close_dates/${dateId}/`, {
    plan: planId,
    payment_date: paymentDate,
    user_percent: userPercent,
  });

export const getSectionClosureDates = ({ sectionId, objectId }: { sectionId: number; objectId: number | string }) =>
  axios.get(`/building/${objectId}/estimate/sections/plan-intervals/close_dates/`, {
    params: { section_id: sectionId },
  });

export const deleteClosureDate = ({ objectId, dateId }: { objectId: number | string; dateId: number }) =>
  axios.delete(`/building/${objectId}/estimate/sections/plan-intervals/close_dates/${dateId}/`);
