export enum PricingModes {
  view = "view",
  budget = "budget",
  //estimatedPrice = "estimatedPrice",
}

export const PRICING_MODES_LOCALES = {
  [PricingModes.view]: "По смете",
  [PricingModes.budget]: "По бюджету",
  //[PricingModes.estimatedPrice]: "Расчетная цена",
};

export const PRICING_MODES_OPTIONS = Object.entries(PRICING_MODES_LOCALES).map(([mode, locale]) => ({
  id: mode,
  name: locale,
}));
