import axios from "axios";

import { IFinanceCommonFilter } from "pages/Finance/common/contexts/FinanceFiltersContext/FinanceFiltersContext";

import { IV2SummaryObjectResponse, IV2SummaryProjectsListResponse } from "./types";

import downloadFile from "utils/helpers/download";

export const summaryApi = {
  getListFull: () => {
    return axios.get<IV2SummaryProjectsListResponse>(`/tree/projects/finance_pivot/`);
  },
  getListByProject: (projectId: number, year: number) => {
    return axios.get<IV2SummaryProjectsListResponse>(`/tree/projects/finance_pivot/`, {
      params: { project_id: projectId, year },
    });
  },
  getDetailedObject: (objectId: number, year: number, filter?: IFinanceCommonFilter) => {
    return axios.get<IV2SummaryObjectResponse>(`/tree/finance_pivot/${objectId}/`, { params: { year, ...filter } });
  },
  downloadReport: (buildingId: number, reportName: string) => {
    downloadFile(`${axios.defaults.baseURL}/buildings/${buildingId}/finances/pivot/report/`, reportName, "POST");
  },
};
